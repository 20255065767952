import { useState } from "react";
import { Select, SelectCreatable } from "../../components/styled/select";
import { GlobalStore } from "../../stores/global";
import { PriceString } from "./price";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_TEMPLATES } from "../../graphql/queries/template";
import { GET_CREDITS } from "../../graphql/queries/credit";
import { GET_INVENTORY_LOCATIONS } from "../../graphql/queries/item";
import { GET_SUPPLIERS } from "../../graphql/queries/suppliers";
import { useTranslation } from "react-i18next";
import { GET_CONFIG_METADATA_CATEGORIES } from "../../graphql/queries/config";

export function SelectTemplate(props: any) {
  const theme = GlobalStore.useState(c => c.theme);
  const query: any = { sortBy: "title", sortOrder: 1 };
  if (props.type) query.types = [props.type];
  else if (props.types) query.types = props.types;
  const [, { data, refetch }] = useLazyQuery(GET_TEMPLATES, { variables: query });

  const templates = data?.templatesConnection?.templates || [];

  const handleLoadTemplates = () => refetch();

  const handleChange = (e: any) => {
    props.onChange(e.value);
  };

  return (
    <Select
      {...props}
      theme={theme}
      onMenuOpen={handleLoadTemplates}
      options={templates.map(template => ({ label: template.title, value: template })) || []}
      className={`select ${props.className || ""}`}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={handleChange}
      placeholder={props.placeholder}
      classNamePrefix="select"
      isDisabled={props.readonly}
      required={props.required}
    />
  );
}

export function SelectCategories(props: any) {
  const { theme } = GlobalStore.useState(c => c);
  const { t } = useTranslation();
  const [getData, { data }] = useLazyQuery(GET_CONFIG_METADATA_CATEGORIES, { fetchPolicy: "no-cache" });

  const categories = data?.configMetadata?.categories;
  const Component = props.selectOnly ? Select : SelectCreatable;

  return (
    <Component
      {...props}
      theme={theme}
      onMenuOpen={getData}
      options={categories?.map(t => ({ label: t, value: t })) || [{ label: t("Loading") + "...", isDisabled: true }]}
      className={`select ${props.className || ""}`}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      classNamePrefix="select"
      isDisabled={props.readonly}
      required={props.required}
    />
  );
}

export function SelectLocation(props: any) {
  const { theme } = GlobalStore.useState(c => c);
  const { t } = useTranslation();
  const [getData] = useLazyQuery(GET_INVENTORY_LOCATIONS, { fetchPolicy: "no-cache" });

  const Component = props.selectOnly ? Select : SelectCreatable;

  async function loadOptions(search: string, loadedOptions: any) {
    const { data } = await getData({ variables: { offset: loadedOptions.length, term: search } });
    if (!data?.inventoryLocations) throw new Error("Locations not found");
    const { entries, pagination } = data?.inventoryLocations;
    return {
      options: entries?.map(t => ({ label: t, value: t })) || [{ label: t("Loading") + "...", isDisabled: true }],
      hasMore: pagination.hasMore
    };
  }

  return (
    <Component
      {...props}
      theme={theme}
      debounceTimeout={300}
      loadOptions={loadOptions}
      withPagination={true}
      className={`select ${props.className || ""}`}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      classNamePrefix="select"
      isDisabled={props.readonly}
      required={props.required}
    />
  );
}

export function SelectSupplier(props: any) {
  const { theme } = GlobalStore.useState(c => c);
  const { t } = useTranslation();
  const [getSuppliers] = useLazyQuery(GET_SUPPLIERS, { fetchPolicy: "cache-and-network" });
  const [page, setPage] = useState(1);

  const Component = props.selectOnly ? Select : SelectCreatable;

  async function loadOptions(search: string) {
    const { data } = await getSuppliers({ variables: { page: search ? 1 : page, limit: 50, term: search } });
    if (!data?.inventorySuppliers) throw new Error("Suppliers not found");
    const { suppliers, pagination } = data?.inventorySuppliers;
    setPage(pagination.page + 1);
    return {
      options: suppliers?.map(t => ({ label: t.name, value: t.name })) || [{ label: t("Loading") + "...", isDisabled: true }],
      hasMore: pagination.pages > pagination.page
    };
  }

  return (
    <Component
      {...props}
      theme={theme}
      debounceTimeout={300}
      loadOptions={loadOptions}
      withPagination={true}
      className={`select ${props.className || ""}`}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      classNamePrefix="select"
      isDisabled={props.readonly}
      required={props.required}
    />
  );
}

export function SelectCredit(props: any) {
  const { theme, config } = GlobalStore.useState(c => c);
  const { data, refetch } = useQuery(GET_CREDITS, { variables: { userRef: props.userRef } });

  if (!config) return;

  const credits =
    data?.credits?.credits?.map(c => ({
      ...c,
      label: `#${c.id} - ${c.title} - ${PriceString({ config, value: c.totals.balance || 0 })}`,
      value: c._id
    })) || [];
  const handleLoadCredits = async () => refetch();

  const handleChange = (e: any) => {
    props.onChange(e.value);
  };

  return (
    <Select
      {...props}
      theme={theme}
      onMenuOpen={handleLoadCredits}
      options={credits}
      className={`select ${props.className || ""}`}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={handleChange}
      placeholder={props.placeholder}
      classNamePrefix="select"
      isDisabled={props.readonly}
      required={props.required}
    />
  );
}
