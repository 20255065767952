import { useState, useEffect } from "react";
import Button from "../styled/button";
import Input from "../styled/input";
import TextArea from "../styled/textArea";
import { Link, useHistory, useLocation } from "react-router-dom";
import Loader from "../common/loader";
import Liner from "../common/liner";
import Four0Four from "../global/404";
import Reorder from "react-reorder";
import { SearchItems } from "../../components/global/search";
import { GlobalStore } from "../../stores/global";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PLAYLIST, POST_PLAYLIST_DELETE, POST_PLAYLIST_UPDATE } from "../../graphql/queries/playlist";
import clone from "clone";
import { Config, Item, Playlist } from "../../__generated__/graphql";
import { getPlaceholderImage } from "../../utils";
import { Match } from "../../types/globals";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { t } from "i18next";

export default function EditPlaylist({ match }: { match: Match }) {
  const { config, addNotification } = GlobalStore.useState(c => c);
  const location = useLocation();
  const isNew = location.pathname.includes("/new");
  const history = useHistory();
  const id = parseInt(match.params.id);
  const [playlist, setPlaylist] = useState<Playlist | undefined>(isNew ? ({ entries: [], title: "" } as any) : undefined);
  const { data: playlistData } = useQuery(GET_PLAYLIST, { fetchPolicy: "cache-and-network", variables: { id } });
  const [updatePlaylist] = useMutation(POST_PLAYLIST_UPDATE);
  const [deletePlaylist] = useMutation(POST_PLAYLIST_DELETE);

  useEffect(() => {
    if (playlistData?.playlist) setPlaylist(clone(playlistData.playlist));
  }, [playlistData]);

  const handleInputChange = (e: any) => {
    if (!playlist) return;
    // @ts-ignore
    playlist[e.target.name] = e.target.value;
    setPlaylist({ ...playlist });
  };

  const handlDeleteEntry = (idx: number) => {
    if (!playlist) return;
    playlist.entries?.splice(idx, 1);
    setPlaylist({ ...playlist });
  };

  const handlSavePlaylist = async (e: any) => {
    if (!playlist || !playlist.entries) return;
    e.preventDefault();
    if (playlist.entries?.length < 4) return addNotification({ ok: 0, message: "Playlist must contain at least four entries" });
    try {
      const { data } = await updatePlaylist({
        variables: {
          playlistRef: playlist._id,
          playlistUpdateInput: {
            title: playlist.title,
            link: playlist.link,
            entries: playlist.entries.map(i => ({ ref: i?.item?._id, comments: i?.comments }))
          }
        }
      });
      if (data?.playlistUpdate) {
        setPlaylist(clone(data.playlistUpdate));
        addNotification({ ok: 1, message: "Playlist updated" });
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleAddEntry = (item: Item, e: any) => {
    if (!playlist || !playlist.entries) return;
    if (e) e.preventDefault();
    playlist.entries.unshift({ item });
    setPlaylist({ ...playlist });
  };

  const handleDeletePlaylist = async () => {
    if (!playlist || !playlist.entries) return;
    deletePlaylist({ variables: { playlistRef: playlist._id } })
      .catch(e => addNotification({ ok: 0, message: e.message }))
      .then(() => history.push("/playlists"));
  };

  const handleReorder = async (e: any, start: number, end: number) => {
    if (!playlist || !playlist.entries) return;

    const newEntries = [...playlist.entries];
    const entryMoving = newEntries.splice(start, 1)[0];
    newEntries.splice(end, 0, entryMoving);
    playlist.entries = newEntries;
    setPlaylist({ ...playlist });
  };

  const handleCommentsChange = (comments: string, i: number) => {
    if (!playlist || !playlist.entries) return;
    const entry = playlist.entries[i];
    if (entry) {
      entry.comments = comments;
      setPlaylist({ ...playlist });
    }
  };

  if (playlist === undefined) return <Loader />;
  else if (playlist === null) return <Four0Four />;
  const submittable = playlist?.entries?.length;

  return (
    <div id="playlistEdit">
      <section id="header">
        <h1>{isNew ? t("New playlist") : t("Edit playlist")}</h1>
        <div id="submit">
          <ButtonV2 variant="primary" disabled={!submittable} form="playlistForm" type="submit">
            {isNew ? t("Create") : t("Save")}
          </ButtonV2>
        </div>
      </section>

      <section id="playlistContainer">
        <div className="fields">
          <form onSubmit={handlSavePlaylist} id="playlistForm">
            <Input
              label={t("Playlist title")}
              type="text"
              name="title"
              required
              value={playlist.title || ""}
              onChange={handleInputChange}
            />
            <Input
              label={t("Link to page")}
              placeholder={t("Path or link to a page") + "..."}
              type="text"
              name="link"
              value={playlist.link || ""}
              onChange={handleInputChange}
            />
          </form>
          <div className="listings">
            <SearchItems onSelect={handleAddEntry} />
          </div>
          <p>
            <small>{t("Note: For optimal display on your Eshop, playlists should contain between 4 and 8 entries")}</small>
          </p>
          {isNew ? null : (
            <Button
              type="button"
              variant="danger"
              onClick={() => window.confirm(t("Are you sure you wish to delete this playlist?")) && handleDeletePlaylist()}>
              {t("Delete playlist")}
            </Button>
          )}
        </div>
        {playlist.entries && playlist.entries.length ? (
          <Reorder
            className="entries"
            reorderId="draggable"
            reorderGroup="reorder-group"
            lock="horizontal"
            holdTime={100}
            onReorder={handleReorder}
            autoScroll={true}
            disabled={false}
            disableContextMenus={true}>
            {playlist.entries.map((e, i) => (
              <Liner className="entry" index={i} key={e.item?._id}>
                <p>#{i + 1}</p>
                <Link to={e.item.path || ""}>
                  <img className="img" src={e.item.data.images[0]?.uri || getPlaceholderImage(config as Config)} />
                </Link>
                <div className="description">
                  <Link to={e.item.path || ""}>{e.item.descriptions.main}</Link>
                </div>
                <div className="comments">
                  <TextArea
                    variant={!(i % 2) && "overZone"}
                    name="comments"
                    rows="3"
                    placeholder={t("Comments about this entry")}
                    value={e.comments}
                    onChange={(v: any) => handleCommentsChange(v.target.value, i)}
                  />
                </div>
                <div className="action">
                  <Button variant="danger" type="button" onClick={() => handlDeleteEntry(i)}>
                    {t("Delete")}
                  </Button>
                </div>
              </Liner>
            ))}
          </Reorder>
        ) : (
          <p>{t("Add entries from the search listings function")}</p>
        )}
      </section>
    </div>
  );
}
