import React, { useState, useRef, useImperativeHandle, forwardRef, ReactNode } from "react";
import Checkbox from "../styled/checkbox";
import Button from "../styled/button";
import Loader from "../common/loader";
import FileSize from "../common/fileSize";
import Modal from "../modal";
import CopyToClipboard from "react-copy-to-clipboard";
import Details from "./details";
import { FaRegFileAlt } from "react-icons/fa";
import { useMutation, useQuery } from "@apollo/client";
import { GET_MEDIA, POST_MEDIA_DELETE, POST_MEDIA_UPDATE } from "../../graphql/queries/media";
import { findUrlForMedia } from "../../utils";
import { Media } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { useTranslation } from "react-i18next";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";

interface Props {
  children?: ReactNode;
  multiple: boolean;
  clickToSelect?: boolean;
  extensions?: string[];
  addNotification: AddNotification;
  handleSelectSingleMedia?: (media: Media) => void;
}

export interface PropsToForward {
  getLibrary: any;
  selectedMedia: any;
}

export default forwardRef<PropsToForward, Props>((props: Props, ref: any) => {
  const { multiple, clickToSelect, addNotification, handleSelectSingleMedia } = props;
  const [pagination, setPagination] = useState({ page: 1, limit: 30 });
  const { data, loading, refetch } = useQuery(GET_MEDIA, {
    variables: { ...pagination, extensions: props.extensions || null },
    fetchPolicy: "cache-and-network"
  });
  const [deleteMedia] = useMutation(POST_MEDIA_DELETE);
  const [updateMedia] = useMutation(POST_MEDIA_UPDATE);

  const { t } = useTranslation();

  const page = data?.media;

  const [isBulkActionRunning, setIsBulkActionRunning] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<Media[]>([]);

  useImperativeHandle(ref, () => ({ getLibrary: refetch, selectedMedia }));

  const handleDeleteMedia = async (ref: string) => {
    await deleteMedia({ variables: { mediaRefs: [ref] } })
      .then(() => addNotification({ ok: 1, message: "Media deleted" }))
      .then(() => refetch())
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleUpdateMedia = async (media: Media) => {
    updateMedia({
      variables: {
        mediaRef: media._id,
        mediaUpdateInput: {
          title: media.title,
          alt: media.alt,
          caption: media.caption
        }
      }
    })
      .then(() => addNotification({ ok: 1, message: "Media updated" }))
      .then(() => refetch())
      .catch(e => addNotification({ ok: 0, message: e.data }));
  };

  const handlePageChange = (pageNumber: number) => {
    if (loading) return;
    pagination.page = pageNumber;
    setPagination({ ...pagination });
  };

  const handleSelectMedia = (media: Media) => {
    const index = selectedMedia.findIndex(m => m.id === media.id);
    const exist = index !== -1;
    let elements: Media[] = [...selectedMedia];
    if (exist) {
      elements.splice(index, 1);
      setSelectedMedia([...elements]);
    } else {
      if (!multiple) elements = [];
      elements.push(media);
      setSelectedMedia([...elements]);
    }
    if (handleSelectSingleMedia) handleSelectSingleMedia(media);
  };

  const getSelectorState = () => {
    const pageElements = page?.media.map(m => m.id);
    const selectedElements = selectedMedia.map(m => m.id);
    const filtered = pageElements?.filter(e => selectedElements.indexOf(e) > -1);

    const none = !filtered?.length;
    const all = filtered?.length === pageElements?.length;
    const some = !!filtered?.length && !all;
    return { none, all, some };
  };

  const handleSelectAll = (none: boolean, some: boolean, all: boolean) => {
    if (!page) return;
    if (none || some) {
      setSelectedMedia([...selectedMedia, ...page.media]);
    } else if (all) {
      const pageElements = page.media.map(m => m.id);
      const filtered = selectedMedia.filter(m => pageElements.indexOf(m.id) === -1);
      setSelectedMedia([...filtered]);
    }
  };

  const handleBulkDeleteMedia = async () => {
    setIsBulkActionRunning(true);
    try {
      const { data } = await deleteMedia({ variables: { mediaRefs: selectedMedia.map(m => m._id) } });
      if (!data?.mediaDelete) addNotification({ ok: 1, message: "Media deleted" });
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    } finally {
      setSelectedMedia([]);
      setIsBulkActionRunning(false);
    }
  };

  if (!page) return <Loader withMargins />;

  const { none, all, some } = getSelectorState();

  return (
    <div id="mediaBrowser" ref={ref}>
      {!page.media.length ? (
        <h2 className="noMedia">{t("No media were found")}</h2>
      ) : (
        <>
          {process.env.hideDeleteAndBulk ? (
            <div className="header">
              <div className="left">
                <Checkbox name="selectAll" indeterminate={some} checked={some || all} onChange={() => handleSelectAll(none, some, all)} />
                <p>
                  {t("Selected")} ({selectedMedia.length})
                </p>
                <Button
                  type="button"
                  variant="danger"
                  onClick={handleBulkDeleteMedia}
                  disabled={isBulkActionRunning || !selectedMedia.length}>
                  {isBulkActionRunning ? t("Processing") + "..." : t("Delete selection")}
                </Button>
              </div>
              <div className="right"></div>
            </div>
          ) : null}
          <div className="files">
            {page.media.map(f => (
              <SingleMedia
                clickToSelect={clickToSelect}
                checked={!!selectedMedia.find(m => m.id === f.id)}
                handleSelectMedia={handleSelectMedia}
                handleDeleteMedia={handleDeleteMedia}
                handleUpdateMedia={handleUpdateMedia}
                addNotification={addNotification}
                key={f._id}
                media={f}
              />
            ))}
          </div>
        </>
      )}
      {page.media && page.media.length ? (
        <div className="pagination">
          <p>
            {page.pagination.count} {t("Items").toLowerCase()} - {page.pagination.pages} {t("pages")}
          </p>
          <ul className="right">
            {page.pagination?.page > 1 ? (
              <>
                <li>
                  <Button
                    variant="noStyle"
                    onClick={() => handlePageChange(page.pagination.page - 1)}
                    disabled={page.pagination.page === 1}
                    type="button">
                    {"< Prev"}
                  </Button>
                </li>
                <li>
                  <Button variant="noStyle" type="button" onClick={() => handlePageChange(page.pagination.page - 1)}>
                    {page.pagination.page - 1}
                  </Button>
                </li>
              </>
            ) : null}
            <li>
              <span className="active">{page.pagination.page}</span>
            </li>
            {page.pagination.page < page.pagination.pages ? (
              <>
                <li>
                  <Button variant="noStyle" type="button" onClick={() => handlePageChange(page.pagination.page + 1)}>
                    {page.pagination.page + 1}
                  </Button>
                </li>
                <li>
                  <Button
                    variant="noStyle"
                    disabled={page.pagination.page >= page.pagination.pages}
                    type="button"
                    onClick={() => handlePageChange(page.pagination.page + 1)}>
                    {t("Next")} {">"}
                  </Button>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      ) : null}
    </div>
  );
});

const SingleMedia = ({
  clickToSelect,
  media,
  addNotification,
  handleDeleteMedia,
  handleUpdateMedia,
  handleSelectMedia,
  checked
}: {
  clickToSelect: any;
  media: Media;
  addNotification: AddNotification;
  handleDeleteMedia: any;
  handleUpdateMedia: any;
  handleSelectMedia: any;
  checked: boolean;
}) => {
  const handleSaveMedia = () => handleUpdateMedia(detailsRef.current.getState());
  const detailsRef = React.createRef<any>();
  const modalRef = useRef<any>();
  const { t } = useTranslation();
  const handleEntryClick = (e: any) => {
    if (e.target.type === "checkbox" || clickToSelect) {
      handleSelectMedia(media);
    } else modalRef.current.open();
  };

  return (
    <div className="entry">
      <Modal className="mediaModal" ref={modalRef} style={{ maxWidth: "50%" }}>
        <div className="mediaDetailsModal">
          <ModalHeaderContainer style={{ padding: "var(--gutter)", paddingBottom: "0px" }}>
            <div style={{ display: "flex" }}>
              <Typography variant="pageTitle" tag="h2">
                {t("Details")}
              </Typography>
              <CopyToClipboard text={media.url || ""} onCopy={() => addNotification({ ok: 1, message: "Copied to clipboard" })}>
                <ButtonV2 variant="secondary" type="button" styleProps={{ marginLeft: "var(--gutter)" }}>
                  {t("Copy to clipboard")}
                </ButtonV2>
              </CopyToClipboard>
            </div>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <div className="content">
            <Details media={media} ref={detailsRef} />
          </div>
          <div className="footer">
            <div className="left">
              <ButtonV2
                variant="warning"
                type="button"
                onClick={async () => {
                  if (window.confirm(t("Are you sure you wish to delete this file?"))) {
                    await handleDeleteMedia(media._id);
                    if (modalRef.current) modalRef.current.close();
                  }
                }}>
                {t("Delete")}
              </ButtonV2>
            </div>
            <div className="right">
              <ButtonV2
                variant="primary"
                type="button"
                onClick={() => {
                  modalRef.current.close();
                  handleSaveMedia();
                }}>
                {t("Save")}
              </ButtonV2>
            </div>
          </div>
        </div>
      </Modal>
      <div className="image" onClick={handleEntryClick}>
        <div className="imageContainer">
          {media.mime?.includes("image/") ? <img src={findUrlForMedia(media, "medium")} /> : <FaRegFileAlt />}
          {process.env.hide ? (
            <div className="checkboxSelector">
              <Checkbox name="select" checked={checked} onChange={handleEntryClick} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="details" onClick={handleEntryClick}>
        <div className="titleAndType">
          <p>{media.title?.substring(0, 30)}</p>
          <div>
            <Button disabled variant="info" className="tag">
              {media.mime?.includes("image/") ? t("Image") : t("File")}
            </Button>
          </div>
        </div>
        <p className="tech">
          <span>
            {media.ext?.toUpperCase()} {media.width ? ` - ${media.width}x${media.height}` : null}
          </span>
          <span>
            <FileSize size={media.size as number} />
          </span>
        </p>
      </div>
    </div>
  );
};
