import styled from "styled-components";
import { colorsAsRgbArray, colorsAsRgbString, applyOpacity } from "@common-ground-io/colors";
import { getThemeProps } from "../../utils/styles";
import { LayoutType } from "../../types/styles.types";

type ThemeProps = {
  fontColor: string;
  borderColor: string;
  borderColorActive: string;
  fontColorHover: string;
  borderColorHover: string;
  tagBgColor: string;
  tagBgColorHover: string;
  popupBgColor: string;
  iconColor: string;
  iconColorActive: string;
};

type ThemeVariants = {
  primary: ThemeProps;
};

type ContainerProps = {
  active: boolean;
  hasSelectedOptions: boolean;
  isSquared: boolean;
};

const primaryLightVariant: ThemeProps = {
  fontColor: colorsAsRgbString.greyOvercast,
  borderColor: colorsAsRgbString.greyLight,
  borderColorActive: colorsAsRgbString.greyDark,
  fontColorHover: colorsAsRgbString.black,
  borderColorHover: colorsAsRgbString.grey,
  tagBgColor: colorsAsRgbString.greyLighter,
  tagBgColorHover: colorsAsRgbString.white,
  popupBgColor: colorsAsRgbString.greyLightest,
  iconColor: colorsAsRgbString.greyOvercast,
  iconColorActive: colorsAsRgbString.black
};

const primaryDarkVariant: ThemeProps = {
  fontColor: colorsAsRgbString.grey,
  borderColor: colorsAsRgbString.greyDark,
  borderColorActive: colorsAsRgbString.greyLight,
  borderColorHover: colorsAsRgbString.white,
  fontColorHover: colorsAsRgbString.white,
  tagBgColor: applyOpacity(colorsAsRgbArray.greyDark, 0.1),
  tagBgColorHover: applyOpacity(colorsAsRgbArray.greyDark, 0.4),
  popupBgColor: colorsAsRgbString.greyDarkest,
  iconColor: colorsAsRgbString.grey,
  iconColorActive: colorsAsRgbString.white
};

const layout: LayoutType<ThemeProps, ThemeVariants> = {
  light: {
    primary: primaryLightVariant
  },
  dark: {
    primary: primaryDarkVariant
  }
};

export const StyledContainer = styled.div`
  position: relative;
  width: max-content;
`;

export const StyledTitleContainer = styled.div<ContainerProps>`
  border-radius: ${({ isSquared }) => (isSquared ? "5px" : "2.2rem")};
  border: 1px solid
    ${props => (props.hasSelectedOptions ? getThemeProps(layout, props).borderColorActive : getThemeProps(layout, props).borderColor)};
  color: ${({ active, ...props }) => (active ? getThemeProps(layout, props).fontColorHover : getThemeProps(layout, props).fontColor)};
  background-color: ${({ active, ...props }) =>
    active ? getThemeProps(layout, props).tagBgColorHover : getThemeProps(layout, props).tagBgColor};
  padding: 7px;
  display: flex;
  width: max-content;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease-in;
  svg {
    path {
      stroke: ${({ active, hasSelectedOptions, ...props }) => {
        const defaultColor = hasSelectedOptions ? getThemeProps(layout, props).iconColorActive : getThemeProps(layout, props).iconColor;
        return active ? getThemeProps(layout, props).iconColorActive : defaultColor;
      }};
    }
    circle {
      stroke: ${({ active, hasSelectedOptions, ...props }) => {
        const defaultColor = hasSelectedOptions ? getThemeProps(layout, props).iconColorActive : getThemeProps(layout, props).iconColor;
        return active ? getThemeProps(layout, props).iconColorActive : defaultColor;
      }};
    }
  }
  &:hover {
    background-color: ${props => getThemeProps(layout, props).tagBgColorHover};
    color: ${props => getThemeProps(layout, props).fontColorHover};
    border: 1px solid ${props => getThemeProps(layout, props).borderColorHover};
    svg {
      path {
        stroke: ${props => getThemeProps(layout, props).iconColorActive};
      }
      circle {
        stroke: ${props => getThemeProps(layout, props).iconColorActive};
      }
    }
  }
`;

export const StyledTitle = styled.span`
  margin-left: 5px;
  font-weight: 700;
`;

export const StyledPopUpContainer = styled.div<{ width: number }>`
  position: absolute;
  top: calc(100% + 5px);
  background-color: ${props => getThemeProps(layout, props).popupBgColor};
  border: 1px solid ${colorsAsRgbString.greyDark};
  min-width: ${props => props.width}px;
  box-sizing: border-box;
  width: auto;
  max-width: 248px;
  border-radius: 15px;
  padding: 10px;
  z-index: 500;
`;

export const StyledPopUpListContainer = styled.div`
  padding: 5px 0;
  max-height: 150px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSeparator = styled.span`
  color: ${colorsAsRgbString.grey};
  margin: 0 5px;
`;

export const StyledSelectedLabel = styled.span`
  color: ${props => getThemeProps(layout, props).fontColorHover};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  gap: 5px;
  width: 100%;
`;
