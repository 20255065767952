import { useEffect, useState, useRef } from "react";
import Button from "./styled/button";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "./common/loader";
import { GlobalStore } from "../stores/global";
import Liner from "./common/liner";
import useOutsideClick from "../hooks/useOutsideClick";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_NOTIFICATIONS, POST_NOTIFICATIONS_MARK_AS_READ } from "../graphql/queries/notification";
import styled from "styled-components";
import { colorsAsRgbString } from "@common-ground-io/colors";
import useIdleDetector from "../hooks/useIdleDetector";
import { useTranslation } from "react-i18next";

const NotificationCenter = () => {
  const ref = useRef<any>();
  const { t } = useTranslation();
  const { theme, addNotification } = GlobalStore.useState(c => c);
  const [pageNumber, setPageNumber] = useState(1);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [getNotifications, { data, loading, refetch }] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: "cache-and-network",
    variables: { page: pageNumber }
  });
  const { isIdled } = useIdleDetector();
  const [markAsRead] = useMutation(POST_NOTIFICATIONS_MARK_AS_READ);

  const page = data && data.notificationsConnection;

  useEffect(() => {
    const id = setInterval(async () => {
      if (!isIdled && process.env.VITE_BUILD_ENV === "production") getNotifications();
    }, 60000);
    return () => clearInterval(id);
  }, [getNotifications, isIdled]);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (page && page.unreadCount) setUnreadCount(page.unreadCount);
  }, [page]);

  useOutsideClick(ref, () => setIsOpen(false));

  const handleMarkAsRead = async (refs: string[] | null) => {
    try {
      const { data } = await markAsRead({ variables: { refs } });
      if (typeof data?.notificationMarkAsRead?.unreadCount === "number") setUnreadCount(data.notificationMarkAsRead.unreadCount);
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const handleClick = async (ref: string) => {
    setIsOpen(!isOpen);
    try {
      handleMarkAsRead([ref]);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const handePageChange = async (inc: number) => {
    getNotifications({ variables: { page: pageNumber + inc } });
    setPageNumber(pageNumber + inc);
  };

  if (!page?.notifications) return null;
  return (
    <div id="notificationCenter" ref={ref}>
      <div className="icons" onClick={() => setIsOpen(!isOpen)}>
        <i className="cg-icon-notifications" />
        {unreadCount > 0 ? <span className={`badge ${unreadCount > 0 ? "active" : ""}`}>{unreadCount}</span> : null}
      </div>
      <ContainerSection theme={theme} className={`content theme-${theme} ${isOpen ? "open" : "closed"}`}>
        <div className="header">
          <h3>
            {t("Notifications")} ({unreadCount})
          </h3>
          <button disabled={page.notifications.length === 0} onClick={() => handleMarkAsRead(null)} type="button">
            {t("Mark all as read")}
          </button>
        </div>
        <div className="entries">
          {page.notifications.length ? (
            page.notifications.map((n, i) => (
              <Liner className="notification" index={i} key={`${n._id}-${i}`}>
                <div className={`entry ${n.read ? "" : "active"}`}>
                  <span className={`mark ${!n.read ? "unread" : ""}`}>
                    {!n.read ? (
                      <button onClick={() => handleMarkAsRead([n._id])} type="button">
                        <span />
                      </button>
                    ) : null}
                  </span>
                  <div className="entryColumn">
                    {n.link?.type === "path" ? (
                      <Link
                        onClick={() => handleClick(n._id)}
                        to={n.link.target || ""}
                        dangerouslySetInnerHTML={{ __html: n.message || "" }}
                      />
                    ) : null}
                    {n.link?.type === "external" ? (
                      <a href={n.link?.target || ""} target="_tab" dangerouslySetInnerHTML={{ __html: n.message || "" }} />
                    ) : null}
                    {!n.link ? <div dangerouslySetInnerHTML={{ __html: n.message || "" }} /> : null}
                    <small>{moment(n.created).fromNow()}</small>
                  </div>
                </div>
              </Liner>
            ))
          ) : (
            <p style={{ textAlign: "left", padding: "10px" }}>{t("You have no new notifications")}</p>
          )}
        </div>
        {page.pagination.pages > 1 ? (
          <div className="loadMore">
            {page.pagination.page > 1 ? (
              <Button type="button" variant="secondary" onClick={() => handePageChange(-1)}>
                {loading ? <Loader /> : t("Previous")}
              </Button>
            ) : null}
            {page.pagination.page < page.pagination.pages ? (
              <Button
                variant="secondary"
                disabled={page.pagination.page >= page.pagination.pages}
                type="button"
                onClick={() => handePageChange(1)}>
                {loading ? <Loader /> : t("Next")}
              </Button>
            ) : null}
          </div>
        ) : null}
      </ContainerSection>
    </div>
  );
};

const ContainerSection = styled.div`
  padding: 0;
  background-color: ${props => (props.theme === "dark" ? colorsAsRgbString.greyDarker : colorsAsRgbString.greyLighter)};
  box-shadow: 0 0 6px 0 ${props => (props.theme === "dark" ? colorsAsRgbString.greyDark : colorsAsRgbString.greyLighter)};
`;

export default NotificationCenter;
