import App from "./App";
import * as Sentry from "@sentry/react";
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GlobalStore } from "./stores/global";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { Router } from "react-router-dom";
import SessionContext from "./contexts/SessionContext";
import { createBrowserHistory, createHashHistory } from "history";
import { getSessionJWTForDomain, isElectron, removeSessionJWTForDomain } from "./utils";
import { createRoot } from "react-dom/client";
import * as amplitude from "@amplitude/analytics-browser";
import "./i18n/i18n";

export const history = isElectron() ? createHashHistory() : createBrowserHistory();

amplitude.init("d66279c4856579faedae450d1a3bf49a", { defaultTracking: true, offline: process.env.VITE_BUILD_ENV !== "production" });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        removeSessionJWTForDomain();
        GlobalStore.update(s => {
          s.session = null;
        });
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

Sentry.init({
  environment: process.env.VITE_BUILD_ENV,
  dsn: process.env.VITE_SENTRY_ID,
  release: `${process.env.VITE_NAME}@${process.env.VITE_VERSION}`,
  enabled: process.env.NODE_ENV === "production",
  integrations: []
});

const mainLink = createUploadLink({ uri: process.env.VITE_API_ENDPOINT + "/graphql" });
const creatorsLink = new HttpLink({ uri: process.env.VITE_CREATORS_API_ENDPOINT });
const authLink = setContext((_, { headers = {} }) => {
  const jwt = getSessionJWTForDomain();
  const origin = localStorage.getItem("origin");
  if (origin) headers["commonground-origin"] = origin;
  if (jwt) headers["commonground-jwt"] = jwt;
  return { headers };
});

const cache = new InMemoryCache({
  typePolicies: {
    ReleaseLabel: { keyFields: ["catno", "id"] },
    ReleaseArtist: { keyFields: ["id", "name"] },
    Voucher: { keyFields: ["_id"] },
    Track: { keyFields: ["title", "position", "uri"] },
    PaymentMethod: { keyFields: ["origin", "amount"] },
    CheckoutOrOrderBuyer: { keyFields: ["_id", "firstName", "lastName", "email"] },
    ConfigTaxDefinition: { keyFields: ["name", "rate", "default"] },
    CollectionFilter: { keyFields: ["_id"] },
    ThemeColorPreset: { keyFields: ["name", "value"] },
    Theme: {
      fields: {
        colours: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        publication: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Listing: {
      fields: {
        prices: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Interval: {
      fields: {
        posts: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    SupportConversation: {
      fields: {
        source: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Config: {
      fields: {
        admins: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Playlist: {
      fields: {
        entries: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Order: {
      fields: {
        shipping: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        billing: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        totals: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Item: {
      fields: {
        data: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        listings: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        descriptions: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    }
  }
});

const apolloClient = new ApolloClient({
  link: from([
    errorLink,
    authLink,
    ApolloLink.split(
      operation => operation.getContext().clientName === "creators",
      creatorsLink, // if above
      mainLink
    )
  ]),
  cache
});

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <ApolloProvider client={apolloClient}>
    <Router history={history}>
      <SessionContext />
      <App apolloClient={apolloClient} />
    </Router>
  </ApolloProvider>
);
