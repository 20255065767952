import { gql } from "../../__generated__/gql";

gql(`
  fragment ConfigTaxDefinitionFields on ConfigTaxDefinition {
    id
    name
    title
    rate
    default
  }
`);

gql(`
  fragment ConfigTaxZoneFields on ConfigTaxZone {
    name
    code
    overrideNationalTaxRate
    tax {
      ...ConfigTaxDefinitionFields
    }
  }
`);

gql(`
  fragment ConfigStripeUserFields on ConfigStripeUser {
    id
    defaultPaymentId
    paymentMethods {
      id
      type
      last4
      brand
      name
      expMonth
      expYear
    }
  }
`);

gql(`
  fragment ConfigTaxFields on ConfigTaxes {
    vat {
      id
      validated
    }
    country {
      name
      code
      area {
        name
        code
      }
      tax {
        ...ConfigTaxDefinitionFields
      }
      taxZoneRequired
      zone {
        ...ConfigTaxZoneFields
      }
      zones {
        ...ConfigTaxZoneFields
      }
    }
    rules {
      collectTaxes
      editPricesBeforeTaxes
      showPricesBeforeTaxes
      collectTaxesInZone
      collectTaxesNationally
      collectTaxesInArea
      collectTaxesOutsideCountryOrArea
      collectTaxesOnShipping
      europeanDistanceSalesOverride
      fallbackToNationalRate
    }
    definitions {
      ...ConfigTaxDefinitionFields
    }
  }
`);

gql(`
  fragment ConfigFields on Config {
    _id
    id
    created
    currency
    currencySymbol
    currencySymbolPosition
    uri
    shopName
    domain
    admins {
      role
      added {
        by {
          name
        }
        date
      }
      admin {
        _id
        type
        name
        firstName
        lastName
        email
      }
    }
    plan {
      id
      expires
      currency
      trial {
        available
        active
      }
      title
      recurrence
      description
      price {
        value
      }
      nextPaymentDate
      seats
    }
    sites {
      _id
      isPrimary
      isDefault
      domain
      created
      target {
        cname
        validated
      }
      certificate {
        provider
        region
        arn
        validated
        addedToLoadBalancer
        loadBalancer
        records {
          name
          value
        }
      }
    }
    google {
      analytics {
        view {
          name
        }
        property {
          id
          name
        }
      }
      merchant {
        enabled
      }
    }
    recordfy {
      enabled
    }
    meta {
      catalog {
        enabled
      }
    }
    status {
      active
    }
    invoicing {
      generator {
        format
        active
        counter
      }
    }
    taxes {
      ...ConfigTaxFields
    }
    eshop {
      uri
      seo {
        title
        description
        favicon
        image
        robots
        index
        indexCatalogue
      }
      checkout {
        paymentMethods {
          cashInAdvance {
            status
            notes
          }
          iyzipay {
            enabled
            apiKey
            secretKey
          }
          payze {
            enabled
            apiKey
            secretKey
          }
          paypal {
            enabled
            meUrl
            clientSecret
            clientId
          }
          stripe {
            enabled
            accountId
            locations {
              id
              name
              address {
                ...AddressFields
              }
            }
          }
        }
      }
      head {
        tags
      }
      preferences {
        access {
          private {
            enabled
            message
          }
        }
        languages {
          whitelist
        }
        homepage {
          handle
          type
        }
        wantlist {
          enabled
        }
        player {
          enabled
        }
        suggestions {
          enabled
        }
      }
      menus {
        _id
        id
        title
        location
        className
        entries
      }
      theme {
        id
        ref
        version
        generation
        revision
      }
    }
    socials {
      title
      id
      value
    }
    apiKey {
      value
    }
    communication {
      language
      emails {
        endpoints
        from
      }
    }
    stripe {
      customerId
    }
    discogs {
      enabled
      username
      token
      listingExtraRate
      syncInventory
      excludeTaxesFromPrice
      requestData {
        method
        level
        consumerKey
        consumerSecret
        token
        tokenSecret
        authorizeUrl
      }
      accessData {
        method
        level
        consumerKey
        consumerSecret
        token
        tokenSecret
        authorizeUrl
      }
    }
    bandcamp {
      clientId
      clientSecret
      bands {
        name
        id
        active
      }
    }
    community {
      feed {
        enabled
      }
    }
    tags
    statistics {
      inventory { count }
      orders { count }
      customers { count }
      campaigns { count }
      inTouch { count }
      invoices { total }
    }
    modules
  }
`);

export const GET_CONFIG = gql(`
  query config($origin: String) {
    config(origin: $origin) {
      ...ConfigFields
    }
  }
`);

gql(`
  fragment ReferralFields on Referral {
    _id
    id
    created
    email
    invitation {
      date
    }
    redeem {
      status
      date
      confirmed
    }
  }
`);

export const GET_CONFIG_REFERRALS = gql(`
  query configReferrals {
    configReferrals{
      referrals {
        ...ReferralFields
      }
    }
  }
`);

export const GET_CONFIG_METADATA = gql(`
  query configMetadata {
    configMetadata {
      id 
      categories 
      styles 
      years 
      genres 
      types 
      countries 
      mediums 
      formats 
      manufacturers
    }
  }
`);

export const GET_CONFIG_METADATA_INVENTORY = gql(`
  query configMetadataInventory {
    configMetadata {
      id 
      locations
      categories
    }
  }
`);

export const GET_CONFIG_METADATA_RELEASES = gql(`
  query configMetadataReleases {
    configMetadata {
      id 
      genres
      styles
    }
  }
`);

export const GET_CONFIG_METADATA_ITEM = gql(`
  query configMetadataItem {
    configMetadata {
      id 
      categories
      supplierCodes
      locations
    }
  }
`);

export const GET_CONFIG_METADATA_OPTIONS = gql(`
  query configMetadataOptions {
    configMetadata { 
      id 
      options { 
        names 
        values
      }
    }
  }
`);

export const GET_CONFIG_METADATA_MENUS = gql(`
  query configMetadataMenus {
    configMetadata { 
      id 
      collections { 
        handle
        title
      }
      articles { 
        handle
        title
      }
    }
  }
`);

export const GET_CONFIG_METADATA_BOOK = gql(`
  query configMetadataBook {
    configMetadata { 
      id 
      bookPublishers
      bookCategories
      bookAuthors
    }
  }
`);

export const GET_CONFIG_METADATA_PRODUCTS = gql(`
  query configMetadataProduct {
    configMetadata { 
      id 
      manufacturers
      types
    }
  }
`);

export const GET_CONFIG_METADATA_CATEGORIES = gql(`
  query configMetadataCategories {
    configMetadata { 
      id 
      categories
    }
  }
`);

export const GET_CONFIG_STATISTICS = gql(`
  query configStatistics {
    configStatistics {
      total
      itemCount
      stockCount
      listingsCount
    }
  }
`);

export const GET_CONFIG_DISCOGS_IDENTITY = gql(`
  query configDiscogsIdentity {
    configDiscogsIdentity {
      id
      username
      resource_url
      consumer_name
    }
  }
`);

export const GET_CONFIG_ONBOARDING = gql(`
  query configOnboarding {
    configOnboarding {
      steps {
        title
        completed
        path
      }
    }
  }
`);

export const GET_CONFIG_DOMAIN = gql(`
  query configDomain($domainRef: ID!) {
    configDomain(domainRef: $domainRef) {
      _id
      isPrimary
      isDefault
      domain
      created
      target {
        cname
        validated
      }
      certificate {
        provider
        region
        arn
        validated
        status
        addedToLoadBalancer
        loadBalancer
        records {
          name
          value
        }
      }
      redirect {
        host
        target
        validated
        records {
          type
          name
          value
        }
      }
    }
  }
`);

export const GET_CUSTOMER = gql(`
  query configStripeUser {
    configStripeUser {
      ...ConfigStripeUserFields
    }
  }
`);

export const GET_STRIPE_INTENT = gql(`
  query configStripeIntent {
    configStripeIntent
  }
`);

export const POST_DOMAIN_ADD_REDIRECT = gql(`
  mutation configDomainRedirectAdd($domainRef: ID!) {
    configDomainRedirectAdd(domainRef: $domainRef) {
      ...ConfigFields
    }
  }
`);

export const POST_DOMAIN_REMOVE_REDIRECT = gql(`
  mutation configDomainRedirectRemove($domainRef: ID!) {
    configDomainRedirectRemove(domainRef: $domainRef) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_TAXES_UPDATE = gql(`
  mutation configTaxesUpdate($vatNumber: String, $countryCode: String!, $countryZone: String, $rules: ConfigTaxesRulesUpdateInput!, $definitions: [ConfigTaxesDefinitionsUpdateInput]!) {
    configTaxesUpdate(vatNumber: $vatNumber, countryCode: $countryCode, countryZone: $countryZone, rules: $rules, definitions: $definitions) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_REFERRAL = gql(`
  mutation configReferralCreate($email: String!) {
    configReferralCreate(email: $email) {
      referrals {
        ...ReferralFields
      }
    }
  }
`);

export const POST_CONFIG_CREATE = gql(`
  mutation configCreate($configData: ConfigCreateInput!) {
    configCreate(configData: $configData) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_DISCOGS_UPDATE = gql(`
  mutation configDiscogsUpdate($settings: ConfigDiscogsUpdateData, $disconnect: Boolean, $requestToken: Boolean, $verifier: String) {
    configDiscogsUpdate(settings: $settings, disconnect: $disconnect, requestToken: $requestToken, verifier: $verifier) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_BANDCAMP_SETTINGS = gql(`
  mutation configBandcampUpdateSettings($clientId: String!, $clientSecret: String!, $bands: [Float], $disconnect: Boolean) {
    configBandcampUpdateSettings(clientId: $clientId, clientSecret: $clientSecret, bands: $bands, disconnect: $disconnect) {
      bandcamp {
        clientId
        clientSecret
        bands {
          id
          name
          active
        }
      }
    }
  }
`);

export const POST_CONFIG_MENU_UPDATE = gql(`
  mutation configMenuUpdate($menu: ConfigMenuEntry!) {
    configMenuUpdate(menu: $menu) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_MODULE_UPDATE = gql(`
  mutation configModuleUpdate($id: String!, $data: JSON!) {
    configModuleUpdate(id: $id, data: $data) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_UPDATE = gql(`
  mutation configUpdate($socials: [ConfigSocialInput!], $language: String $emailFrom: String, $google: ConfigGoogleInput, $meta: ConfigMetaInput) {
    configUpdate(socials: $socials, language: $language, emailFrom: $emailFrom, google: $google, meta: $meta) {
      ...ConfigFields
    }
  }
`);

export const POST_PAYMENT_METHOD_UPDATE = gql(`
  mutation configPaymentMethodUpdate($paymentMethodId: String!, $paymentMethodData: JSON!) {
    configPaymentMethodUpdate(paymentMethodId: $paymentMethodId, paymentMethodData: $paymentMethodData) {
      ...ConfigFields
    }
  }
`);

export const POST_STRIPE_CONNECT_METHOD = gql(`
  mutation configStripeConnect($code: String, $state: String, $disconnect: Boolean) {
    configStripeConnect(code: $code, state: $state, disconnect: $disconnect) {
      ...ConfigFields
    }
  }
`);

export const POST_STRIPE_LOCATION = gql(`
  mutation configStripeLocation($createLocation: ConfigStripeLocationInput, $deleteLocationId: String) {
    configStripeLocation(createLocation: $createLocation, deleteLocationId: $deleteLocationId) {
      ...ConfigFields
    }
  }
`);

export const POST_STRIPE_PAYMENT_METHOD = gql(`
  mutation configStripePaymentMethod($attachPaymentMethodId: String, $detachPaymentMethodId: String, $defaultPaymentMethodId: String) {
    configStripePaymentMethod(attachPaymentMethodId: $attachPaymentMethodId, detachPaymentMethodId: $detachPaymentMethodId, defaultPaymentMethodId: $defaultPaymentMethodId) {
      ...ConfigStripeUserFields
    }
  }
`);

export const POST_INVOICING_UPDATE = gql(`
  mutation configInvoicingUpdate($settings: InvoicingEntry!) {
    configInvoicingUpdate(settings: $settings) {
      ...ConfigFields
    }
  }
`);

export const POST_PLAN_SUBSCRIBE = gql(`
  mutation configPlanSubscribe($planRef: ID!) {
    configPlanSubscribe(planRef: $planRef) {
      config {
        ...ConfigFields
      }
      intent
      needsAuthentication
    }
  }
`);

export const POST_CONFIG_ESHOP_SEO_UPDATE = gql(`
  mutation configEshopSeoUpdate($title: String, $description: String, $index: Boolean, $favicon: String, $image: String, $robots: String) {
    configEshopSeoUpdate(title: $title, description: $description, index: $index, favicon: $favicon, image: $image, robots: $robots) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_ESHOP_SETTINGS_UPDATE = gql(`
  mutation configEshopSettingsUpdate(
    $languages: [String]
    $homepage: ConfigEshopSettingsUpdateHomepageInput
    $head: ConfigEshopSettingsUpdateHeadInput
    $player: ConfigEshopSettingsUpdatePlayerInput
    $wantlist: ConfigEshopSettingsUpdateWantlistInput
    $access: ConfigEshopSettingsUpdateAccessInput
    $suggestions: ConfigEshopSettingsUpdateSuggestionsInput) {
      configEshopSettingsUpdate(languages: $languages, homepage: $homepage, access: $access, head: $head, player: $player, wantlist: $wantlist, suggestions: $suggestions) {
        ...ConfigFields
    }
  }
`);

export const POST_CONFIG_DELETE_ACCOUNT = gql(`
  mutation configDeleteAccount {
    configDeleteAccount
  }
`);

export const POST_CONFIG_RESET = gql(`
  mutation configReset($releases: Boolean, $products: Boolean, $books: Boolean, $customers: Boolean, $orders: Boolean, $credits: Boolean) {
    configReset(releases: $releases, products: $products, books: $books, customers: $customers, orders: $orders, credits: $credits){
      releases
      products
      books
      customers
      orders
      credits
    }
  }
`);

export const POST_CONFIG_SITE = gql(`
  mutation configDomainAdd($domainData: ConfigDomainUpdateNewSiteData) {
    configDomainUpdate(domainData: $domainData) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_SITE_DELETE = gql(`
  mutation configDomainDelete($domainRef: ID!) {
    configDomainDelete(domainRef: $domainRef) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_DOMAIN_UPDATE = gql(`
  mutation configDomainUpdate($activationData: ConfigDomainUpdateActivationData) {
    configDomainUpdate(activationData: $activationData) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_ADMIN_REMOVE = gql(`
  mutation configAdminRemove($userRef: ID!) {
    configAdminRemove(userRef: $userRef) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_ADMIN_ADD = gql(`
  mutation configAdminAdd($email: String!, $firstName: String!, $lastName: String!) {
    configAdminAdd(email: $email, firstName: $firstName, lastName: $lastName) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_POLICY_UPDATE = gql(`
  mutation configShippingPolicyUpdate($id: String, $active: Boolean!, $methods: JSON!, $countries: [String!]!, $freeShippingThreshold: Float) {
    configShippingPolicyUpdate(id: $id, active: $active, methods: $methods, countries: $countries, freeShippingThreshold: $freeShippingThreshold) {
      ...ConfigFields
    }
  }
`);

export const POST_CONFIG_POLICY_DELETE = gql(`
  mutation configShippingPolicyDelete($id: String!) {
    configShippingPolicyDelete(id: $id) {
      ...ConfigFields
    }
  }
`);
