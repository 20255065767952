import { colorsAsRgbString } from "@common-ground-io/colors";
import styled from "styled-components";

const layout = {
  light: {
    fontColorLabel: colorsAsRgbString.greyOvercast,
    bcgColorInput: colorsAsRgbString.greyLighter,
    iconColor: "transparent",
    iconOutline: colorsAsRgbString.grey,
    iconColorActive: colorsAsRgbString.greyDarker
  },
  dark: {
    fontColorLabel: colorsAsRgbString.grey,
    bcgColorInput: colorsAsRgbString.greyDark,
    iconColor: "transparent",
    iconOutline: colorsAsRgbString.grey,
    iconColorActive: colorsAsRgbString.greyLighter
  }
};

const Styledlabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;
  margin-bottom: 10px;
  color: ${props => path(layout, props).fontColorLabel};
`;

const StyledInputlabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: ${props => path(layout, props).fontColorLabel};
`;

const HiddenRadioItem = styled.input.attrs({ type: "radio" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadio = styled.div<{ checked?: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => path(layout, props).bcgColorInput};
  border-radius: 10px;
  transition: all 150ms;
  margin-right: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

const Icon = styled.svg<{ checked?: boolean }>`
  fill: ${props => (props.checked ? path(layout, props).iconColorActive : path(layout, props).iconColor)};
  stroke-width: 2px;
  border-radius: 50%;
  border: 1px solid ${props => path(layout, props).iconOutline};
`;

const StyledCircle = styled.circle.attrs({ cx: 12, cy: 12, r: 6 })<{ checked?: boolean }>`
  fill: ${props => (props.checked ? path(layout, props).iconColorActive : path(layout, props).iconColor)};
`;

const path = (layout: any, props: any) => layout[props.theme.name || "light"];

const Radio = (props: any) => {
  const { entries = [] } = props;

  return (
    <Container className={"cg-common cg-radio "}>
      {props.label ? <Styledlabel>{props.label}</Styledlabel> : null}
      {entries.map((e: any, i: number) => (
        <StyledInputlabel key={i}>
          <HiddenRadioItem {...props} name={props.name} checked={props.value === e.value} entry={e} value={e.value} />
          <StyledRadio checked={props.value === e.value}>
            <Icon viewBox="0 0 24 24" name={undefined}>
              <StyledCircle checked={props.value === e.value} />
            </Icon>
          </StyledRadio>
          {e.label}
        </StyledInputlabel>
      ))}
    </Container>
  );
};

export default Radio;
