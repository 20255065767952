import { useEffect, useRef, useState } from "react";
import Button from "../styled/button";
import Modal, { PropsToForward } from "../modal";
import Loader from "../common/loader";
import { useQuery } from "@apollo/client";
import { GET_ITEM_PRICE_SUGGESTIONS } from "../../graphql/queries/item";
import Liner from "./liner";
import { Config, Listing } from "../../__generated__/graphql";
import { getPriceSuggestions } from "../../@services/discogsService";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function MarketSuggestions({
  config,
  discogsId,
  handlePriceSuggestionSelect,
  listing
}: {
  config: Config;
  discogsId: number;
  handlePriceSuggestionSelect: any;
  listing: Listing;
}) {
  const token = config.discogs.token;
  const accessData = config.discogs.accessData;
  const modalRef: any = useRef<PropsToForward>();
  const { t } = useTranslation();

  const handleSelectPrice = (priceAndCondition: { price: number }) => {
    if (handlePriceSuggestionSelect) handlePriceSuggestionSelect(priceAndCondition);
    modalRef.current?.close();
  };

  if (!token && !accessData) return null;

  return (
    <div className="marketSuggestions">
      <Modal style={{}} ref={modalRef}>
        <div className="marketSuggestionsModal">
          <div className="header">
            <div className="left">
              <h2>{t("Discogs price information")}</h2>
            </div>
            <Button variant="noStyle" onClick={() => modalRef.current.close()} type="button">
              <i className="cg-icon-burger-close" />
            </Button>
          </div>
          <div className="content">
            <DiscogsScrappedPrices config={config} handleSelectPrice={handleSelectPrice} listing={listing} discogsId={discogsId} t={t} />
          </div>
        </div>
      </Modal>
      <button type="button" className="reset marketRates" onClick={() => modalRef.current.open()}>
        <i className="cg-icon-market-rates" />
      </button>
    </div>
  );
}

interface DiscogsPriceSuggestions {
  [key: string]: {
    currency: string;
    value: number;
  };
}

const DiscogsScrappedPrices = ({
  config,
  listing,
  discogsId,
  handleSelectPrice,
  t
}: {
  config: Config;
  listing: Listing;
  discogsId: number;
  handleSelectPrice: ({ price }: { price: number }) => void;
  t: TFunction;
}) => {
  const { data } = useQuery(GET_ITEM_PRICE_SUGGESTIONS, { variables: { discogsId }, fetchPolicy: "cache-and-network" });
  const [suggestions, setSuggestions] = useState<DiscogsPriceSuggestions | null>(null);

  useEffect(() => {
    getPriceSuggestions(config, discogsId)
      .then(({ data }) => {
        if (data) setSuggestions(data);
      })
      .catch((e: any) => console.error(e.toString()));
  }, []);

  const mediaCondition = listing.options.find(o => o.name === "Media Condition")?.value;
  const sleeveCondition = listing.options.find(o => o.name === "Sleeve Condition")?.value;

  const isAMatch = (media: string, sleeve: string) => mediaCondition === media && sleeveCondition === sleeve;

  const statistics = data?.itemPriceSuggestions?.discogsStatistics;
  const prices = data?.itemPriceSuggestions?.discogsScrappedPrices;

  return (
    <div className="dicsogsScrappedPrices">
      {suggestions && mediaCondition ? (
        <div className="apiSuggestions" style={{ margin: "var(--gutter) 0" }}>
          <h3>{t("Price suggestions")}</h3>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "" }}>
            {Object.keys(suggestions).map(key => (
              <div key={key}>
                <button
                  type="button"
                  onClick={() => handleSelectPrice({ price: Math.round(suggestions[key].value * 100) / 100 })}
                  className="reset"
                  style={{ fontWeight: mediaCondition === key ? "bold" : "normal", margin: 0 }}>
                  {key}: {suggestions[key].value.toFixed(2)} {suggestions[key].currency}
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {data?.itemPriceSuggestions && statistics && prices ? (
        <div className="discogsSuggestions">
          <div className="header">
            <h3>{t("Statistics & live rates")}</h3>
            <a target="_blank" href={data.itemPriceSuggestions.discogsUrl} rel="noreferrer">
              {t("View more")}
            </a>
          </div>

          <div className="statistics">
            <div className="left">
              <p>
                {t("Have")}: {statistics.have}
              </p>
              <p>
                {t("Want")}: {statistics.want}
              </p>
              <p>
                {t("Last sold")}: {statistics.lastSold}
              </p>
            </div>
            <div className="right">
              <p>
                {t("Lowest")}: {statistics.lowest}
              </p>
              <p>
                {t("Median")}: {statistics.median}
              </p>
              <p>
                {t("Highest")}: {statistics.highest}
              </p>
            </div>
          </div>

          <div className="priceTable">
            {prices.length ? (
              prices.map((e, i) => (
                <Liner
                  onClick={() => handleSelectPrice({ price: e.price })}
                  className={`entry ${isAMatch(e.mediaCondition, e.sleeveCondition) ? "active" : ""}`}
                  index={i}
                  key={i}>
                  <div className="conditions">
                    <p>
                      {t("Media")}: {e.mediaCondition}
                    </p>
                    <p>
                      {t("Sleeve")}: {e.sleeveCondition}
                    </p>
                  </div>
                  <p>
                    <span className="seller">{e.seller}</span>
                    <br />
                    <span>{e.shipsFrom}</span>
                  </p>
                  <div className="price">
                    <p>{e.priceString}</p>
                  </div>
                </Liner>
              ))
            ) : (
              <p>{t("No rates available")}</p>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
