import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Loader from "./loader";
import { TFunction } from "i18next";

export default function ImageDropzone({
  formData,
  uploadingState,
  onDrop,
  t
}: {
  formData: any;
  uploadingState: boolean;
  onDrop: any;
  t: TFunction;
}) {
  const onDropzone = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        acceptedFiles.forEach(f => {
          formData.append("files", f);
        });
        onDrop(formData, acceptedFiles);
      }
    },
    [formData]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropzone,
    minSize: 0,
    maxSize: 8000000,
    accept: "image/*"
  });

  return (
    <div {...getRootProps({ id: "imageDropzone" })}>
      <input {...getInputProps()} />
      <div>{uploadingState ? <Loader /> : <p>{t("Drop image files here")}</p>}</div>
    </div>
  );
}
