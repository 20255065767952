import { useState, useRef } from "react";
import { Redirect, Prompt, Link } from "react-router-dom";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Input from "../styled/input";
import Zone from "../styled/zone";
import Languages from "@common-ground-io/common-assets/assets/languages.json";
import Loader from "../common/loader";
import Listing from "../listings/single";
import Image from "../image";
import FileUploader from "../fileUploader";
import { Select, SelectCreatable } from "../styled/select";
import DatePicker from "../styled/datePicker";
import { DescriptionEditor, IdentifiersEditor } from "./inputs";
import Reorder from "react-reorder";
import ImageDropzone from "../common/imageDropzone";
import Four0Four from "../global/404";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { POST_ITEM_CREATE, POST_ITEM_IMAGE_REGISTER, POST_ITEM_IMAGE_URL, POST_ITEM_UPDATE } from "../../graphql/queries/item";
import isNumber from "is-number";
import { sanitizeListingData, removeTypenameAndIds, uploadToS3 } from "../../utils";
import { AddNotification } from "../../types/globals";
import { Config, Item } from "../../__generated__/graphql";
import { GET_CONFIG_METADATA_BOOK } from "../../graphql/queries/config";
import { TFunction } from "i18next/typescript/t";
import { Typography } from "../../componentsV2/Typography";

export default function NewProduct({
  item,
  config,
  configReload,
  isNew,
  setItem,
  t,
  addNotification
}: {
  item: Item;
  config: Config;
  configReload: any;
  isNew: boolean;
  setItem: any;
  t: TFunction;
  addNotification: AddNotification;
}) {
  const formId = "itemForm";
  const [redirect, setRedirect] = useState<string | null>(null);
  const [formData] = useState(new FormData());
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const newListingRef = useRef<any>();
  const [missingFields, setMissingFields] = useState<any>({});
  const [updateItemWithGraphql] = useMutation(POST_ITEM_UPDATE);
  const [getImageSignedUrl] = useMutation(POST_ITEM_IMAGE_URL);
  const [registerImage] = useMutation(POST_ITEM_IMAGE_REGISTER);
  const [createItem] = useMutation(POST_ITEM_CREATE);
  const { data: metadata } = useQuery(GET_CONFIG_METADATA_BOOK, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only"
  });

  const handleGenericInputUpdate = (name: string, value: any) => {
    setIsDirty(true);
    // @ts-ignore
    item.data[name] = value;
    setItem({ ...item });
    if (missingFields[name] && value) setMissingFields({ ...missingFields, [name]: false });
  };

  const handleShopDescriptionUpdate = (content: string) => {
    setIsDirty(true);
    item.descriptions.shop.text = content;
    setItem({ ...item });
  };

  const handleShortDescriptionUpdate = (content: string) => {
    setIsDirty(true);
    if (content.length > 160) return addNotification({ ok: 0, message: "Max number of characters is 160" });
    item.descriptions.shop.short = content;
    setItem({ ...item });
  };

  const handleIdentifierUpdate = (object: any) => {
    setIsDirty(true);
    item.data.identifiers = object;
    setItem({ ...item });
  };

  const handleSubmitItemData = async (e: any, noRedirect?: boolean) => {
    console.log("submit");

    if (e) e.preventDefault();
    try {
      setIsSubmitting(true);
      isSubmittable();

      await updateItemWithGraphql({
        variables: {
          itemRef: item._id,
          descriptions: {
            short: item.descriptions.shop.short,
            long: item.descriptions.shop.text
          },
          itemUpdateBookInput: {
            title: item.data.title || "",
            subtitle: item.data.subtitle || "",
            authors: item.data.authors || [],
            publisher: item.data.publisher || "",
            categories: item.data.categories || [],
            pageCount: typeof item.data.pageCount === "string" ? parseInt(item.data.pageCount) : item.data.pageCount,
            language: item.data.language,
            publishedDate: item.data.publishedDate,
            format: item.data.format || "",
            weight: typeof item.data.weight === "string" ? parseInt(item.data.weight) : item.data.weight || 0,
            images: removeTypenameAndIds(item.data.images || []),
            identifiers: removeTypenameAndIds(item.data.identifiers || [])
          }
        }
      });
      setIsDirty(false);
      if (!noRedirect) {
        addNotification({ ok: 1, message: "Item updated" });
        setRedirect(item.path);
      }
    } catch (e: any) {
      addNotification({ message: e.message, ok: 0 });
    } finally {
      setIsSubmitting(false);
    }
  };

  const readUploadedFileAsText = (inputFile: any) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  const handleAlternativeImageUpload = async (file: File, index: number) => {
    if (!file) return;
    // item.data.images[index].alternativeUploading = true;
    setItem({ ...item });

    const fileToSend = file;
    const { data } = await getImageSignedUrl({ variables: { itemRef: item._id as string, filename: fileToSend.name } });
    if (!data?.itemImageSignedUrl) return;
    const options = { headers: { "Content-Type": fileToSend.type, "Cache-Control": "max-age=31536000" } };
    await uploadToS3(data.itemImageSignedUrl.signedUrl, fileToSend, options);
    const registerData = {
      key: data.itemImageSignedUrl.key,
      filename: fileToSend.name,
      index,
      itemRef: item._id as string,
      alternative: true
    };
    const { data: imageRegisterResults } = await registerImage({ variables: registerData });
    if (imageRegisterResults?.itemImageRegister) {
      setItem({ ...imageRegisterResults.itemImageRegister });
      addNotification({ ok: 1, message: t("Image added") });
    }
  };

  const handleImageDrop = async (_: any, files: File[]) => {
    setIsDirty(true);
    if (isNew) {
      await files.forEach(async f => {
        try {
          const fileContents = await readUploadedFileAsText(f);
          item.data.images.push({ uri: fileContents as string });
          setItem({ ...item });
        } catch (e: any) {
          addNotification({ ok: 0, message: e.toString() });
        }
      });
    } else {
      if (isDirty) await handleSubmitItemData(null, true);
      setIsUploadingImages(true);
      try {
        for (const fileToSend of formData.getAll("files")) {
          const { data } = await getImageSignedUrl({ variables: { itemRef: item._id, filename: (fileToSend as File).name } });
          if (!data?.itemImageSignedUrl) continue;
          const options = { headers: { "Content-Type": (fileToSend as File).type, "Cache-Control": "max-age=31536000" } };
          await uploadToS3(data.itemImageSignedUrl.signedUrl, fileToSend as File, options);
          const registerData = {
            key: data.itemImageSignedUrl.key,
            filename: (fileToSend as File).name,
            itemRef: item._id,
            alternative: false
          };
          const { data: registeredData } = await registerImage({ variables: registerData });
          if (registeredData?.itemImageRegister) {
            setItem({ ...registeredData?.itemImageRegister });
            addNotification({ ok: 1, message: t("Image added") });
          }
        }
        formData.delete("files");
      } catch (e: any) {
        addNotification({ ok: 0, message: e.message || e.toString() });
      } finally {
        setIsUploadingImages(false);
      }
    }
  };

  const handleAddItem = async (e: any) => {
    console.log("hello");

    e.preventDefault();
    try {
      setIsSubmitting(true);
      isSubmittable();
      if (!newListingRef.current) return addNotification({ ok: 0, message: "Listing error" });

      const listingToAdd = newListingRef.current.getListing();
      const itemDataToSend = {
        ...item.data,
        weight: typeof item.data.weight === "string" ? parseInt(item.data.weight) : item.data.weight,
        pageCount: typeof item.data.pageCount === "string" ? parseInt(item.data.pageCount) : item.data.pageCount || 0
      };
      const descriptions = { short: item.descriptions.shop.short, long: item.descriptions.shop.text };

      sanitizeListingData(listingToAdd);

      if ((!isNumber(listingToAdd.prices.sale) && !isNumber(listingToAdd.prices.beforeTaxes)) || !isNumber(listingToAdd.stock.quantity)) {
        addNotification({ ok: 0, message: "Price and stock values are required" });
        setIsSubmitting(false);
        return;
      }

      itemDataToSend.images = [];
      delete itemDataToSend.maturityRating;
      const { data } = await createItem({
        variables: { type: "BookItem", descriptions, listings: [listingToAdd], itemCreateBookInput: itemDataToSend }
      });

      const addedItem = data?.itemCreate;
      if (!addedItem) throw new Error("Error creating item");

      const images = formData.getAll("files");

      if (images.length) {
        try {
          for (const fileToSend of formData.getAll("files") as any) {
            const { data } = await getImageSignedUrl({ variables: { itemRef: addedItem._id, filename: fileToSend.name } });
            if (!data?.itemImageSignedUrl) continue;
            const options = { headers: { "Content-Type": fileToSend.type, "Cache-Control": "max-age=31536000" } };
            await uploadToS3(data?.itemImageSignedUrl.signedUrl, fileToSend, options);
            const registerData = {
              key: data?.itemImageSignedUrl.key,
              filename: fileToSend.name,
              itemRef: addedItem._id,
              alternative: false
            };
            await registerImage({ variables: registerData });
          }
          formData.delete("files");
        } catch (e: any) {
          addNotification({ ok: 0, message: e.toString() });
        } finally {
          setIsUploadingImages(false);
        }
      }
      configReload();
      setRedirect(addedItem.path);
      addNotification({ ok: 1, message: "Item created" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSubmittable = () => {
    if (isSubmitting) return false;

    if (!item.data.title) throw new Error(t("A title is required"));
    if (!isNumber(item.data.weight)) throw new Error(t("A weight is required"));
    if (!item.data.language) throw new Error(t("A language is required"));
    if (!item.data.format) throw new Error(t("A format is required"));
    if (!item.data.pageCount) throw new Error(t("A page count is required"));
  };

  const handleDeleteImage = async (index: number) => {
    setIsDirty(true);
    item.data.images.splice(index, 1);
    if (!isNew) {
      setItem({ ...item });
    } else {
      const files = formData.getAll("files");
      files.splice(index, 1);
      formData.delete("files");
      files.forEach(item => {
        formData.append("files", item);
      });
      setItem({ ...item });
    }
  };

  const handleDeleteAlternativeImage = async (index: number) => {
    setIsDirty(true);
    delete item.data.images[index].alternative;
    setItem({ ...item });
  };

  const handleReorder = async (e: any, start: number, end: number) => {
    setIsDirty(true);
    const entryMoving = item.data.images.splice(start, 1)[0];
    item.data.images.splice(end, 0, entryMoving);
    setItem({ ...item });
  };

  const handlePublishedDateChange = (e: any) => {
    setIsDirty(true);
    if (!e || !moment().isValid()) return;
    item.data.publishedDate = moment(e).format();
    setItem({ ...item });
  };

  const handleAuthorsChange = (entries: any) => {
    setIsDirty(true);
    item.data.authors = entries.map((e: any) => e.value);
    setItem({ ...item });
  };

  const handlePublisherChange = (value: { value: string }) => {
    setIsDirty(true);
    item.data.publisher = value?.value || null;
    setItem({ ...item });
  };

  const handleCategoriesChange = (entries: any) => {
    item.data.categories = entries.map((e: any) => e.value);
    setItem({ ...item });
  };

  const handleDropdownChange = async (value: { value: { code: string } }) => {
    item.data.language = value.value.code;
    setItem({ ...item });
  };

  const handleFormatDropdownChange = async (value: { value: string }) => {
    item.data.format = value.value;
    setItem({ ...item });
  };

  if (redirect) return <Redirect to={redirect} />;
  if (!isNew && item === null) return <Four0Four />;
  if (!isNew && item === undefined) return <Loader />;
  if (!item) return null;

  const shopDescription = item.descriptions.shop.text;

  return (
    <div id="itemEdit" className="product">
      <Prompt when={isDirty} message={() => t("Your changes will be lost, are you sure you want to leave this page ?")} />
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {isNew ? t("Add a book") : t("Edit book")}
          </Typography>
          {!isNew && item.path ? (
            <Link to={item.path}>
              <Button type="button" variant="noStyle">
                {t("Back")}
              </Button>
            </Link>
          ) : null}
        </div>
        <div className="right">
          <ButtonV2 variant="primary" form={formId} type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Loader /> : isNew ? t("Submit") : t("Save")}
          </ButtonV2>
        </div>
      </section>
      <div id="content">
        <form onSubmit={isNew ? handleAddItem : handleSubmitItemData} id={formId}>
          <section id="images">
            <h3>{t("Images")}</h3>
            <ImageDropzone formData={formData} uploadingState={isUploadingImages} onDrop={handleImageDrop} t={t} />
            {item.data.images.length ? (
              <div className="entries">
                <Reorder
                  className="entries"
                  reorderId="draggable"
                  reorderGroup="reorder-group"
                  lock="horizontal"
                  holdTime={20}
                  onReorder={handleReorder}
                  autoScroll={true}
                  disabled={false}
                  disableContextMenus={true}>
                  {item.data.images.map((i, index) => (
                    <div key={`${i.uri}-${index}`} className="image">
                      <Image className="primary" entry={i} />
                      <div className="imageOptions">
                        <Button variant="danger" type="button" onClick={() => handleDeleteImage(index)}>
                          {t("Delete")}
                        </Button>
                        {i.alternative ? (
                          <div className="alternative">
                            <Button type="button" variant="danger" onClick={() => handleDeleteAlternativeImage(index)}>
                              {t("Delete hover image")}
                            </Button>
                          </div>
                        ) : (
                          <FileUploader
                            // isUploading={!!i.alternativeUploading}
                            accept="image/png, image/jpeg"
                            label="Add hover image"
                            name="alternative"
                            handleFile={(file: File) => handleAlternativeImageUpload(file, index)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </Reorder>
              </div>
            ) : null}
          </section>
          <section id="information">
            <h3>{t("Item information")}</h3>
            <div id="fields">
              <Input
                label={t("Title") + "*"}
                name="title"
                required
                form={formId}
                value={item.data.title || ""}
                placeholder={t("Title") + "..."}
                className={missingFields.title ? "required" : ""}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <Input
                label={t("Subtitle")}
                name="subtitle"
                form={formId}
                value={item.data.subtitle || ""}
                placeholder={t("Subtitle") + "..."}
                className={missingFields.subtitle ? "required" : ""}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <SelectCreatable
                isMulti={false}
                label="Publisher"
                isClearable={false}
                value={item.data.publisher ? { label: item.data.publisher, value: item.data.publisher } : null}
                onChange={(option: any) => handlePublisherChange(option)}
                options={[...(metadata?.configMetadata?.bookPublishers || []), item.data.publisher].map(s => ({ value: s, label: s }))}
              />
              <SelectCreatable
                isMulti={true}
                label="Authors"
                value={(item.data.authors || []).map(s => ({ label: s, value: s }))}
                onChange={(option: any) => handleAuthorsChange(option)}
                options={(metadata?.configMetadata?.bookAuthors || []).concat(item.data.authors || []).map(s => ({ value: s, label: s }))}
              />
              <SelectCreatable
                isMulti={true}
                label="Categories"
                value={(item.data.categories || []).map(s => ({ label: s, value: s }))}
                onChange={(option: any) => handleCategoriesChange(option)}
                options={(metadata?.configMetadata?.bookCategories || [])
                  .concat(item.data.categories || [])
                  .map(s => ({ value: s, label: s }))}
              />
              <Input
                label={t("Weight in grams") + "*"}
                name="weight"
                type="number"
                required
                step="1"
                min="0"
                value={item.data.weight || ""}
                onWheel={(e: any) => e.target.blur()}
                placeholder={t("Weight in grams") + "..."}
                className={missingFields.weight ? "required" : ""}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <Input
                label={t("Page count")}
                name="pageCount"
                form={formId}
                required
                value={item.data.pageCount || ""}
                placeholder={t("Page count") + "..."}
                className={missingFields.pageCount ? "required" : ""}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <Select
                required
                label={t("Language") + "*"}
                placeholder={t("Select") + "..."}
                isMulti={false}
                options={Languages.map(c => ({ label: c.name, value: c }))}
                value={item.data.language ? { label: Languages.find(c => c.code === item.data.language)?.name } : null}
                onChange={(a: any) => handleDropdownChange(a)}
              />
              <DatePicker
                value={item.data.publishedDate ? moment(item.data.publishedDate) : ""}
                onChange={handlePublishedDateChange}
                label={t("Published date")}
                className="publishedDate"
                dateFormat="dd/MM/yyyy"
                closeOnSelect={true}
              />
              <SelectCreatable
                label={t("Format") + "*"}
                placeholder={t("Select") + "..."}
                isMulti={false}
                isClearable={false}
                // M.A. > Do we need to translate those options ? Are they related to other properties on the front ? Might be better to integrate in the Backend than on the front ?
                options={["Paperback", "Soft cover", "Hardcover"].map(c => ({ label: c, value: c }))}
                value={item.data.format ? { label: item.data.format } : null}
                onChange={(a: any) => handleFormatDropdownChange(a)}
              />
              <div>
                <DescriptionEditor label={t("Full description")} content={shopDescription || ""} onChange={handleShopDescriptionUpdate} />
                <DescriptionEditor
                  label={t("Tag line / SEO description - 160 characters max")}
                  content={item.descriptions.shop.short || ""}
                  onChange={handleShortDescriptionUpdate}
                />
              </div>
              <Input
                label={t("Public asset download link")}
                type="url"
                name="assetLink"
                value={item.data.assetLink || undefined}
                placeholder={t("An URL link to download extra assets") + "..."}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <IdentifiersEditor item={item} handleIdentifierUpdate={handleIdentifierUpdate} t={t} />
            </div>
          </section>
        </form>
      </div>
      {isNew ? (
        <Zone id="listings">
          <div id="listingsHeader" />
          <Listing
            config={config}
            overZone={true}
            listing={item.listings[0]}
            ref={newListingRef}
            item={item}
            edit={isNew || false}
            hideSubmit={true}
          />
        </Zone>
      ) : null}
    </div>
  );
}
