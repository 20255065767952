import { Select } from "../components/styled/select";

const Tests = () => {
  return (
    <div>
      <Select options={[{ label: "vaallvava" }, { label: "valueeere" }]} />
    </div>
  );
};

export default Tests;
