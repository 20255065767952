import { capitalizeString } from "../../utils/index";
import { MultiSelectValueType } from "./MultiSelect.types";

export const getOptionsLabel = (options: MultiSelectValueType[]): string[] => {
  if (options.length === 0) return [""];

  const optionsLabel = options.map(option => capitalizeString(option.label));

  if (optionsLabel.length <= 3) return optionsLabel;
  const moreOptions = `& ${optionsLabel.length - 3} more`;
  return [...optionsLabel.slice(0, 3), moreOptions];
};

export const truncateLabel = (label: string, length?: number): string => {
  const lengthToApply = length || 15;
  if (label.length <= lengthToApply) return label;
  return `${label.slice(0, lengthToApply)}...`;
};
