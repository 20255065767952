import { Config, ConfigDiscogsOauthData, DiscogsInventoryEntry } from "../__generated__/graphql";
import http from "../httpService";
import URI from "urijs";

function oauthHeaders(accessData: ConfigDiscogsOauthData) {
  return {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `OAuth oauth_consumer_key="${accessData.consumerKey}",oauth_nonce="${Date.now()}",oauth_token="${
      accessData.token
    }",oauth_signature="${accessData.consumerSecret}&${
      accessData.tokenSecret
    }",oauth_signature_method="PLAINTEXT",oauth_timestamp="${new Date().getTime()}"`
  };
}

export const getPriceSuggestions = async (config: Config, releaseId: number) => {
  const token = config.discogs.token;
  const accessData = config.discogs.accessData;

  const uri = new URI(`https://api.discogs.com//marketplace/price_suggestions/${releaseId}`);
  let options: any = {};
  if (token) {
    uri.addSearch({ token });
  } else if (accessData) {
    options = { headers: oauthHeaders(accessData) };
  }

  return http.get(uri.toString(), options);
};

export function discogsSearch({
  token,
  term,
  type = "release",
  accessData
}: {
  token?: string | null;
  term?: string;
  type?: string;
  accessData?: ConfigDiscogsOauthData | null;
}) {
  const uri = new URI("https://api.discogs.com/database/search");
  uri.addSearch({ q: term, type, page: 1, per_page: 40 });
  let options: any = null;
  if (token) {
    uri.addSearch({ token });
  } else if (accessData) {
    options = { headers: oauthHeaders(accessData) };
  }
  return http.get(uri.toString(), options);
}

export function discogsGetRelease({
  token,
  id,
  accessData
}: {
  token?: string | null;
  id: string | number;
  accessData?: ConfigDiscogsOauthData | null;
}) {
  const uri = new URI(`https://api.discogs.com/releases/${id}`);
  let options: any = null;
  if (token) {
    uri.addSearch({ token });
  } else if (accessData) {
    options = { headers: oauthHeaders(accessData) };
  }
  return http.get(uri.toString(), options);
}

export function unlist({ config, listing }: { config: Config; listing: DiscogsInventoryEntry }) {
  const token = config.discogs.token;
  const accessData = config.discogs.accessData;
  if (process.env.VITE_BUILD_ENV !== "production") throw new Error("Only allowed in production");

  const uri = new URI(`https://api.discogs.com/marketplace/listings/${listing.id}`);
  let options: any = null;
  if (token) {
    uri.addSearch({ token });
  } else if (accessData) {
    options = { headers: oauthHeaders(accessData) };
  }
  return http.delete(uri.toString(), options);
}
