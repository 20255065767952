import styled from "styled-components";

import { colorsAsRgbString } from "@common-ground-io/colors";

const inputLayout = {
  light: {
    fontColorLabel: colorsAsRgbString.greyOvercast,
    bcgColorInput: colorsAsRgbString.greyLighter,
    borderColor: colorsAsRgbString.greyLight,
    fontColorInput: colorsAsRgbString.greyDarker,
    fontColorInputReadonly: colorsAsRgbString.greyLight,
    fontColorPlaceholder: colorsAsRgbString.grey
  },
  dark: {
    fontColorLabel: colorsAsRgbString.grey,
    bcgColorInput: colorsAsRgbString.greyDark,
    borderColor: "transparent",
    fontColorInput: colorsAsRgbString.greyLighter,
    fontColorInputReadonly: colorsAsRgbString.grey,
    fontColorPlaceholder: colorsAsRgbString.greyLight
  }
};

const iconLayout = {
  light: {
    bcgColor: colorsAsRgbString.greyLighter,
    borderColor: colorsAsRgbString.greyLight,
    fontColor: colorsAsRgbString.greyDark,
    borderColorHover: colorsAsRgbString.grey,
    fontColorHover: colorsAsRgbString.grey,
    bcgColorActive: colorsAsRgbString.grey,
    borderColorActive: "transparent",
    fontColorActive: colorsAsRgbString.greyLightest,
    separatorColor: colorsAsRgbString.greyLighter
  },
  dark: {
    bcgColor: colorsAsRgbString.greyDark,
    borderColor: "transparent",
    fontColor: colorsAsRgbString.greyLight,
    borderColorHover: colorsAsRgbString.greyLight,
    fontColorHover: colorsAsRgbString.greyLight,
    bcgColorActive: colorsAsRgbString.greyLight,
    borderColorActive: "transparent",
    fontColorActive: colorsAsRgbString.greyDark,
    separatorColor: colorsAsRgbString.greyDark
  }
};

const Styledlabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(inputLayout, props).fontColorLabel};
`;

const StyledInput = styled.input<{ iconPosition?: string; fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid ${props => path(inputLayout, props).borderColor};
  ${props =>
    props.iconPosition === "left" &&
    `
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-left: 1px solid ${(props: any) => path(iconLayout, props).separatorColor};
  `};
  ${props =>
    props.iconPosition === "right" &&
    `
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  `};
  margin: 0;

  box-shadow: none;
  outline: none;

  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(inputLayout, props).bcgColorInput};
  color: ${props => path(inputLayout, props).fontColorInput};

  &:read-only {
    color: ${props => path(inputLayout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(inputLayout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  ::-webkit-calendar-picker-indicator {
    filter: ${({ theme }) =>
      theme.name === "light"
        ? "invert(23%) sepia(0%) saturate(1432%) hue-rotate(140deg) brightness(111%) contrast(87%);"
        : "invert(50%) sepia(0%) saturate(3%) hue-rotate(168deg) brightness(95%) contrast(84%);"};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

const StyledIconZone = styled.div<{ iconPosition?: string }>`
  display: flex;
  align-items: center;
  height: auto;
  border: 1px solid ${props => path(inputLayout, props).borderColor};
  ${props =>
    props.iconPosition === "left" &&
    `
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-right: 1px solid ${path(iconLayout, props).separatorColor};
  `};
  ${props =>
    props.iconPosition === "right" &&
    `
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-left: 1px solid ${path(iconLayout, props).separatorColor};
  `};
  padding: 8px 10px;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-style: normal;
  line-height: 1;
  text-align: center;
  margin: 0;
  background-color: ${props => path(iconLayout, props).bcgColor};
  color: ${props => path(iconLayout, props).fontColor};
`;

const StyledContainer = styled.div<{ label?: string; iconPosition?: string }>`
  display: flex;
  align-content: flex-start;
  margin-top: ${props => (props.label ? "10px" : "0px")};
`;

const Container = styled.div`
  display: block;
`;

const path = (layout: any, props: any) => layout[props.theme.name || "light"];

const InputWithIconComponent = (props: any) => {
  const iconPosition = props.iconPosition || "left";

  const Icon = (
    <StyledIconZone {...props} iconPosition={iconPosition}>
      {props.icon || "Icon"}
    </StyledIconZone>
  );

  return (
    <Container className="cg-common cg-inputWithIcon">
      {props.label && (
        <Styledlabel htmlFor={props.id || props.name || ""} className={`cg-common  ${props.className || ""} cg-label`}>
          {props.label}
        </Styledlabel>
      )}
      <StyledContainer iconPosition={iconPosition} label={props.label}>
        {iconPosition === "left" && Icon}
        <StyledInput
          {...props}
          onWheel={(e: any) => (e.target as HTMLElement).blur()}
          className={`cg-common cg-input  ${props.className || ""}`}
        />
        {iconPosition === "right" && Icon}
      </StyledContainer>
    </Container>
  );
};

export default InputWithIconComponent;
