import { applyOpacity, colorsAsRgbArray } from "@common-ground-io/colors";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: ${applyOpacity(colorsAsRgbArray.greyDark, 0.2)};
  border-radius: 10px;
  padding: 8px 14px;
`;

export const StyledInput = styled.input`
  background: none;
  border: none;
`;
