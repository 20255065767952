import { useState } from "react";
import { Checkbox } from "../../../componentsV2/Checkbox";
import Input from "../../../components/styled/input";
import TextArea from "../../../components/styled/textArea";
import { Select } from "../../../components/styled/select";
import { SelectTemplate, SelectCategories, SelectLocation, SelectSupplier } from "../../../components/common/select";
import { Options } from "../../../components/common/options";
import moment from "moment";
import { StatusSelect } from "../../../components/common/listingSelect";
import Price, { PriceInput } from "../../../components/common/price";
import DatePicker from "../../../components/styled/datePicker";
import MarketSuggestions from "../../../components/common/marketSuggestions";
import TemplateEditor from "../../../components/templates/editor";
import { Config, Item, Listing as IListing, Template, ItemListingInput, Listing } from "../../../components/../__generated__/graphql";
import { ItemEditable } from "../../../components/items/edit.release";
import { useTranslation } from "react-i18next";
import { AddItemStore } from "../../../stores/AddItem";
import clone from "clone";

export interface ListingProps {
  overZone: boolean;
  listing: IListing;
  listingDefault?: IListing;
  config: Config;
  item: Item | ItemEditable;
  index?: number;
  edit: boolean;
  onSubmit?: any;
  onDelete?: any;
  hideSubmit?: any;
  noBulk?: boolean;
  ref?: any;
  new?: boolean;
  onCancel?: () => void;
}

export interface ListingPropsToForward {
  getListing: () => ItemListingInput;
}

const ListingComponent = ({ config, item, listing, index }: { config: Config; item: ItemEditable; listing: Listing; index: number }) => {
  const [dirty, setDirty] = useState(false);
  const { t } = useTranslation();

  const handleAvailableChange = (e: any) => {
    setDirty(true);
    if (e === null) listing.available = null;
    else if (!moment(e).isValid()) return;
    AddItemStore.update(s => {
      s.listings[index].available = moment(e).format();
    });
  };

  const handlePublishedChange = (e: any) => {
    setDirty(true);
    if (!moment(e).isValid()) return;
    listing.posted = moment(e).format();
  };

  const handleCreatableSelect = (option: any, event: any) => {
    setDirty(true);
    let value = null;
    if (event.action === "clear") {
      value = null;
      localStorage.removeItem(`${config.id}-add-listing-${event.name}`);
    } else if (event.action === "select-option") {
      localStorage.setItem(`${config.id}-add-listing-${event.name}`, option.value);
      value = option.value;
    }
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index][event.name] = value;
    });
  };

  const handleCategoriesSelect = (options: any, event: any) => {
    setDirty(true);
    let value = [];
    if (event.action === "clear") {
      value = [];
      localStorage.removeItem(`${config.id}-add-listing-categories`);
    } else if (event.action === "remove-value") {
      localStorage.removeItem(`${config.id}-add-listing-categories`);
      value = listing.categories.filter(c => c !== event.removedValue.value);
    } else if (event.action === "select-option") {
      const mappedOptions = options.map((i: any) => i.value);
      localStorage.setItem(`${config.id}-add-listing-categories`, JSON.stringify(mappedOptions));
      value = mappedOptions;
    }
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index].categories = value;
    });
  };

  const handleSupplierCreate = (value: string) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].supplierCode = value;
    });
  };

  const handleDeleteOption = (optionIndex: number) => {
    setDirty(true);
    const cloned = clone(listing.options);
    cloned.splice(optionIndex, 1);
    AddItemStore.update(s => {
      s.listings[index].options = cloned;
    });
  };

  const handleAutolistChange = () => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].preventDiscogsListing = !listing.preventDiscogsListing;
    });
  };

  const handleOnePerCustomerChange = () => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].onePerCustomer = !listing.onePerCustomer;
    });
  };

  const handlePreOrderChange = () => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].preOrder = !listing.preOrder;
    });
  };

  const handleLocationCreate = (value: string) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].location = value;
    });
  };

  const handleCategoryCreate = (value: string) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].categories.push(value);
    });
  };

  const handlePriceChange = (event: any) => {
    setDirty(true);
    localStorage.setItem(`${config.id}-add-listing-${event.target.name}`, event.target.value);
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index].prices[event.target.name] = event.target.valueAsNumber;
    });
  };

  const handlePriceSuggestionSelect = ({ price, condition }: { price: number; condition: string }) => {
    setDirty(true);
    console.log(price, condition, dirty);

    // if (listing.prices) listing.prices.sale = price;
    // const option = listing.options.find(o => o.name === "Media Condition");
    // if (option && condition) option.value = condition;
    // localStorage.setItem(`${config.id}-add-listing-sale`, String(price));
  };

  const handleStockChange = (event: any) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].stock.quantity = event.target?.valueAsNumber;
    });
  };

  const handleStatusChange = (option: { value: string }) => {
    setDirty(true);
    localStorage.setItem(`${config.id}-add-listing-status`, option.value);
    AddItemStore.update(s => {
      s.listings[index].status = option.value;
    });
  };

  const handleCommentsUpdate = (name: string, value: any) => {
    setDirty(true);
    localStorage.setItem(`${config.id}-add-listing-${name}`, value);
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index][name] = value;
    });
  };

  const handleTemplateSelect = (template: Template) => {
    setDirty(true);
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index].comments = template.data.content;
    });
    localStorage.setItem(`${config.id}-add-listing-comments`, template.data.content || "");
  };

  const handleOptionOnChange = (option: any, event: any, optionIndex: number) => {
    setDirty(true);
    const storageStr = `${config.id}-add-listing-options-${item.type}-${index}-${event.name}`;
    if (event.action === "clear") {
      localStorage.removeItem(storageStr);
    } else if (event.action === "select-option") {
      AddItemStore.update(s => {
        // @ts-ignore
        s.listings[index].options[optionIndex][event.name] = option.value;
      });
      localStorage.setItem(storageStr, option.value);
    }
  };

  const handleOptionCreate = (value: any, name: string, index: number) => {
    setDirty(true);
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index][name] = value;
    });
  };

  const handleAddAttribute = () => {
    setDirty(true);
    AddItemStore.update(s => {
      s.listings[index].options.push({ name: "", value: "" });
    });
  };

  const handleSkuOrBarcodeChange = (e: any) => {
    setDirty(true);
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index][e.target.name] = e.target.value;
    });
  };

  const handleSecondHandChange = () => {
    setDirty(true);
    localStorage.setItem(`${config.id}-add-listing-secondHand`, JSON.stringify(!listing.secondHand));
    AddItemStore.update(s => {
      // @ts-ignore
      s.listings[index].secondHand = !listing.secondHand;
    });
  };

  const couldBeListedToDiscogs = item.type === "ReleaseItem" && item?.data?.discogsId && item.data.discogsId > 0;
  const preTaxes = config?.taxes?.rules?.editPricesBeforeTaxes;
  const overZone = true;
  const priceToDisplay = preTaxes ? listing?.prices?.beforeTaxes : listing?.prices?.sale;

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gridGap: "var(--gutter)" }}>
        <div className="conditionsAndDate">
          <StatusSelect overZone={overZone} onChange={handleStatusChange} value={listing.status} />
          <Options
            overZone={overZone}
            onChange={handleOptionOnChange}
            onCreate={handleOptionCreate}
            onAdd={handleAddAttribute}
            options={listing.options}
            item={item}
            deleteOption={handleDeleteOption}
          />
          <Checkbox checked={listing.secondHand || false} onClick={handleSecondHandChange} label={t("Second hand listing")} />
        </div>
        <div className="priceAndTax">
          <div className="price">
            {item?.data?.discogsId && !preTaxes ? (
              <div className="priceHeader">
                <MarketSuggestions
                  listing={listing}
                  discogsId={item.data.discogsId}
                  handlePriceSuggestionSelect={handlePriceSuggestionSelect}
                  config={config}
                />
              </div>
            ) : null}
            {preTaxes ? (
              <PriceInput
                label={t("Price before sales tax")}
                variant={overZone ? "overZone" : ""}
                value={listing.prices?.beforeTaxes as number}
                name="beforeTaxes"
                type="number"
                step="0.01"
                fullWidth
                min="0.00"
                placeholder={t("Enter a value") + "..."}
                required={true}
                onChange={handlePriceChange}
              />
            ) : (
              <>
                <PriceInput
                  label={t("Price incl. sales tax")}
                  variant={overZone ? "overZone" : ""}
                  value={listing.prices?.sale as number}
                  name="sale"
                  type="number"
                  step="0.01"
                  fullWidth
                  min="0.00"
                  placeholder={t("Enter a value") + "..."}
                  required={true}
                  onChange={handlePriceChange}
                />
                {config.taxes?.rules?.collectTaxes && listing.prices?.beforeTaxes ? (
                  <small>
                    {t("Price before sales tax")}: <Price value={listing.prices?.beforeTaxes} />
                  </small>
                ) : null}
              </>
            )}
          </div>
          <div className="price">
            <PriceInput
              label={t("Compare at price")}
              variant={overZone ? "overZone" : ""}
              value={listing.prices?.compare as number}
              name="compare"
              type="number"
              step="0.01"
              fullWidth
              min="0.00"
              onChange={handlePriceChange}
            />
          </div>
          <PriceInput
            label={t("Item cost / Supplier price")}
            variant={overZone ? "overZone" : ""}
            value={listing.prices?.cost as number}
            name="cost"
            step="0.01"
            fullWidth
            min="0.00"
            type="number"
            onChange={handlePriceChange}
          />
          <Select
            label={t("National tax rate override")}
            variant={overZone && "overZone"}
            onChange={handleCreatableSelect}
            value={listing.taxDefinition ? { label: listing.taxDefinition, value: listing.taxDefinition } : null}
            options={[
              { label: t("Default"), value: "" },
              ...(config?.taxes?.definitions || []).filter(t => !t?.default).map(t => ({ label: t?.name, value: t?.name }))
            ]}
            name="taxDefinition"
          />
          <div>
            <small>
              {listing?.prices?.cost && priceToDisplay && !isNaN(listing.prices.cost) && !isNaN(priceToDisplay) ? (
                <>
                  {t("Price difference")}: <Price value={priceToDisplay - listing.prices.cost} /> |{" "}
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  {Math.abs(((listing.prices.cost - priceToDisplay) / listing.prices.cost) * 100).toFixed(2)}% | x
                  {(priceToDisplay / listing.prices.cost).toFixed(2)}
                </>
              ) : (
                t("Enter cost and sale prices in order to compute the margin")
              )}
            </small>
          </div>
        </div>
        <div className="availableAndCategory">
          <div>
            <DatePicker
              label={t("Available from")}
              dateFormat="dd/MM/yyyy"
              variant={overZone && "overZone"}
              value={listing.available}
              onChange={handleAvailableChange}
              className="available datePicker"
            />
            <Checkbox label={t("Set as pre order")} onClick={handlePreOrderChange} checked={listing.preOrder || false} />
          </div>
          <DatePicker
            label={t("Published date")}
            variant={overZone && "overZone"}
            value={listing.posted || moment()}
            dateFormat="dd/MM/yyyy"
            onChange={handlePublishedChange}
            className="available datePicker"
          />
          <SelectCategories
            label={t("Categories")}
            variant={overZone && "overZone"}
            isMulti
            creatable={true}
            onCreateOption={handleCategoryCreate}
            onChange={handleCategoriesSelect}
            value={listing.categories?.map(c => ({ label: c, value: c }))}
            name="categories"
          />
          {couldBeListedToDiscogs ? (
            <Checkbox label={t("Disable Discogs auto-list")} onClick={handleAutolistChange} checked={!!listing.preventDiscogsListing} />
          ) : null}
          <Checkbox label={t("One per customer")} onClick={handleOnePerCustomerChange} checked={!!listing.onePerCustomer} />
        </div>
        <div className="stockLocationSupplier">
          <Input
            label={t("Stock quantity")}
            variant={overZone && "overZone"}
            required={true}
            value={listing?.stock?.quantity}
            type="number"
            onWheel={(e: any) => e.target.blur()}
            placeholder="Available quantity"
            onChange={handleStockChange}
          />
          <SelectSupplier
            variant={overZone && "overZone"}
            label={t("Supplier")}
            onChange={handleCreatableSelect}
            onCreateOption={handleSupplierCreate}
            value={listing.supplierCode ? { label: listing.supplierCode, value: listing.supplierCode } : null}
            name="supplierCode"
          />
          <SelectLocation
            label={t("Location")}
            variant={overZone && "overZone"}
            onCreateOption={handleLocationCreate}
            onChange={handleCreatableSelect}
            value={listing.location ? { label: listing.location || "", value: listing.location || "" } : null}
            name="location"
          />
          <Input
            label={t("Barcode identifier")}
            variant={overZone && "overZone"}
            required={false}
            value={listing.barcode || ""}
            type="text"
            name="barcode"
            autoComplete="off"
            placeholder={t("Barcode identifier") + "..."}
            onChange={handleSkuOrBarcodeChange}
          />
          <Input
            label={t("SKU")}
            variant={overZone && "overZone"}
            required={false}
            value={listing.sku || ""}
            type="text"
            autoComplete="off"
            name="sku"
            placeholder={t("SKU") + "..."}
            onChange={handleSkuOrBarcodeChange}
          />
        </div>
        <div className="comments">
          <div className="public templateControls">
            <SelectTemplate
              label={t("Public comments")}
              variant={overZone && "overZone"}
              placeholder={t("Select from template")}
              type="comment"
              onChange={handleTemplateSelect}
            />
            <TextArea
              variant={overZone && "overZone"}
              rows="5"
              onChange={(e: any) => handleCommentsUpdate("comments", e.target.value)}
              value={listing.comments || ""}
            />
            <TemplateEditor type="comment" />
          </div>
          <div className="private">
            <TextArea
              variant={overZone && "overZone"}
              label={t("Private comments")}
              rows="4"
              onChange={(e: any) => handleCommentsUpdate("privateComments", e.target.value)}
              value={listing.privateComments || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingComponent;
