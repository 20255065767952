import { useEffect, useState } from "react";
import URI from "urijs";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { POST_STRIPE_CONNECT_METHOD } from "../graphql/queries/config";
import { useTranslation } from "react-i18next";

export default function StripeConnect() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(null);
  const currentPath = location.pathname + location.search;
  const [stripeConnectMethodUpdate, { loading }] = useMutation(POST_STRIPE_CONNECT_METHOD);
  useEffect(() => {
    const currentUri = new URI(currentPath);
    const searchQuery = currentUri.search(true);
    stripeConnectMethodUpdate({ variables: { code: searchQuery.code, state: searchQuery.state } })
      .then(() => history.replace("/editor/checkout"))
      .catch(error => setError(error.message));
  }, [currentPath]);

  return (
    <div id="stripeConnect" style={{ textAlign: "center", marginTop: "var(--gutter)" }}>
      {loading ? (
        <>
          <h2>{t("Retrieving your tokens, please wait")}... </h2>
        </>
      ) : (
        <p>{error}</p>
      )}
    </div>
  );
}
