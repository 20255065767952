import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
const isProduction = process.env.VITE_BUILD_ENV === "production";

const useAnalytics = () => {
  const location = useLocation();
  const url = location.pathname + location.search;

  useEffect(() => {
    if (isProduction) {
      ReactGA.initialize(process.env.VITE_GA as string);
      console.log("GA initialized with ", process.env.VITE_GA);
    } else console.log("No analytics");
  }, []);

  /** * Page view ***/
  useEffect(() => {
    if (isProduction) return;
    ReactGA.send("pageview");
  }, [url]);

  return null;
};

export default useAnalytics;
