import styled from "styled-components";

import { applyOpacity, colorsAsRgbArray, colorsAsRgbString } from "@common-ground-io/colors";

const layout = {
  light: {
    fontColorLabel: colorsAsRgbString.grey,
    bcgColorInput: applyOpacity(colorsAsRgbArray.zone, 0.4),
    iconColor: "transparent",
    iconColorActive: colorsAsRgbString.greyDarker
  },
  dark: {
    fontColorLabel: colorsAsRgbString.grey,
    bcgColorInput: colorsAsRgbString.greyDark,
    iconColor: "transparent",
    iconColorActive: colorsAsRgbString.greyLightest
  }
};

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(layout, props).fontColorLabel};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => path(layout, props).bcgColorInput};
  border-radius: 3px;
  transition: all 150ms;
`;

const Container = styled.div``;

const CheckboxContainer = styled.div<{ noMargin: boolean }>`
  display: inline-block;
  vertical-align: middle;
  margin-right: ${({ noMargin }) => (noMargin ? "0px" : "5px")};
`;

const Icon = styled.svg<{ checked?: boolean; variant?: string }>`
  fill: none;
  stroke: ${props => (props.checked ? path(layout, props).iconColorActive : path(layout, props).iconColor)};
  stroke-width: 2px;
`;

const path = (layout: any, props: any) => layout[props.theme.name || "light"];

const Checkbox = (props: any) => {
  const { checked, className, disabled } = props;

  return (
    <Container className={"cg-common cg-checkbox "}>
      <Styledlabel>
        <CheckboxContainer noMargin={!props.label} className={className}>
          <HiddenCheckbox disabled={disabled || false} checked={checked} {...props} label={undefined} />
          <StyledCheckbox checked={checked} {...props} name={undefined}>
            <Icon viewBox="0 0 24 24" checked={checked} name={undefined}>
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
        {props.label}
      </Styledlabel>
    </Container>
  );
};

export default Checkbox;
