import { useEffect } from "react";
import Loader from "../../components/common/loader";
import { GlobalStore } from "../../stores/global";
import { GET_ARTICLES } from "../../graphql/queries/other";
import { useQuery } from "@apollo/client";
import moment from "moment";
import Zone from "../../components/styled/zone";
import { useLocation } from "react-router-dom";
import FourOFour from "../../components/global/404";
import { useTranslation } from "react-i18next";

export default function NewsPanel() {
  const location = useLocation();
  const { t } = useTranslation();
  const hash = location.hash.replace("#", "");
  const { apolloClientArticles: client } = GlobalStore.useState(c => c);

  const { data, loading } = useQuery(GET_ARTICLES, { client, variables: {} });

  const news = data && data.supportNews;

  useEffect(() => {
    if (hash && !loading) {
      const element = document.getElementById(hash);
      if (element) element.scrollIntoView();
    } else window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [hash, loading]);

  if (loading || news === undefined) return <Loader />;
  else if (news === null) return <FourOFour />;

  return (
    <div id="newsArticles">
      <div className="content">
        {news.campaigns.map(p => (
          <Zone key={p.id} className="article" id={p.id}>
            <div className="header">
              <h2 className="title">{p.title}</h2>
              <p className="date">{moment(p.publication?.date).format("ll")}</p>
            </div>
            {/* {p.bannerImage ? <img className="banner" src={p.bannerImage} /> : null} */}
            <div className="campaignHtml" dangerouslySetInnerHTML={{ __html: p.html || "" }} />
            <div className="footer">
              <p className="createdBy">
                {t("Published")} {moment(p.publication?.date).format("ll")}
              </p>
            </div>
          </Zone>
        ))}
      </div>
    </div>
  );
}
