import { Link } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

interface ComponentProps {
  [key: string]: any;
}

export type MenuEntryType = {
  name?: string;
  path?: string;
  icon?: string;
  external?: boolean;
  condition?: boolean;
  children?: MenuEntryType[];
  subPaths?: string[];
  type: "li" | "component" | "hr" | string;
  className?: string;
  component?: React.ComponentType;
  componentProps?: ComponentProps;
};

const pathWithoutQuery = (path: string) => {
  return path.split("?")[0];
};

const hasChildren = (entry: MenuEntryType) => {
  return entry.children && entry.children.length > 1;
};

const isActiveEntry = (entry: MenuEntryType) => {
  const location = useLocation();
  const hasRootPath = entry.path === "/" || entry.subPaths?.some(path => path === "/");
  const currentPath = pathWithoutQuery(location.pathname);

  if (hasRootPath) {
    return currentPath === pathWithoutQuery(entry.path || "") || entry.subPaths?.some(path => currentPath === pathWithoutQuery(path));
  }

  return (
    currentPath.includes(pathWithoutQuery(entry.path || "")) || entry.subPaths?.some(path => currentPath.includes(pathWithoutQuery(path)))
  );
};

const showOnCondition = (entry: MenuEntryType) => typeof entry.condition !== "boolean" || entry.condition;

interface MenuLinksProps {
  entry: MenuEntryType;
  children: React.ReactNode;
}
const MenuLink = ({ entry, children }: MenuLinksProps) => {
  const isExternalLink = entry.path?.startsWith("http");

  if (isExternalLink) {
    return (
      <Link to={{ pathname: entry.path }} target="_blank" rel="noopener noreferrer">
        {children}
      </Link>
    );
  } else {
    return <Link to={entry.path || ""}>{children}</Link>;
  }
};

export const renderEntries = (entries: MenuEntryType[]) => {
  return entries.map((entry, i) => {
    if (showOnCondition(entry)) {
      switch (entry.type) {
        case "li":
          return RenderLiEntry(entry, i);
        case "component":
          return RenderComponentEntry(entry, i);
        case "hr":
          return RenderHrEntry(entry, i);
        default:
          return null;
      }
    } else {
      return null;
    }
  });
};

const RenderLiEntry = (entry: MenuEntryType, i: number) => {
  const { isMobile: isIpad } = useWindowSize(1200);
  const isActive = isActiveEntry(entry);
  const hasSubMenu = hasChildren(entry);

  return (
    <li key={i} className={`${isActive ? "active" : ""} ${hasSubMenu ? "hasSubmenu" : ""}`} style={{ padding: isIpad ? 2 : 2 }}>
      <MenuLink entry={entry}>
        {entry.icon ? (
          <div className="icon-wrapper">
            <i className={entry.icon} />
          </div>
        ) : null}{" "}
        <span>{entry.name}</span>
        {entry.external ? <i className="cg-icon-open-tab external" /> : null}
      </MenuLink>
      {hasSubMenu ? <ol className={`submenu ${isActive ? "active" : ""}`}>{renderEntries(entry.children || [])}</ol> : null}
    </li>
  );
};

const RenderComponentEntry = (entry: MenuEntryType, i: number) => {
  const Component = entry.component;
  const componentProps = entry.componentProps;

  return (
    <li key={i}>
      {/* @ts-ignore */}
      <Component {...componentProps} />
    </li>
  );
};

const RenderHrEntry = (entry: MenuEntryType, i: number) => {
  return <hr key={i} className={entry.className} />;
};
