import { useContext } from "react";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";
import styled, { ThemeContext } from "styled-components";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import { colorsAsRgbString } from "@common-ground-io/colors";

import Async from "react-select/async";
import { useTranslation } from "react-i18next";

const layout: any = {
  light: {
    fontColorLabel: colorsAsRgbString.greyOvercast,
    borderColor: colorsAsRgbString.greyLight,
    bcgColorInput: colorsAsRgbString.greyLighter,
    bcgColorInputActive: colorsAsRgbString.greyDark,
    bcgColorInputHover: colorsAsRgbString.greyLight,
    bcgColorMultiValue: colorsAsRgbString.greyLighter,
    bcgColorDropdown: colorsAsRgbString.greyLighter,
    fontColorInput: colorsAsRgbString.greyOvercast,
    fontColorMenuSelected: colorsAsRgbString.greyDark,
    fontColorMenuInactive: colorsAsRgbString.greyOvercast,
    fontColorPlaceholder: colorsAsRgbString.grey,
    indicatorColor: colorsAsRgbString.greyOvercast
  },
  dark: {
    fontColorLabel: colorsAsRgbString.grey,
    bcgColorInput: colorsAsRgbString.greyDark,
    borderColor: "transparent",
    bcgColorInputActive: colorsAsRgbString.greyDarkest,
    bcgColorInputHover: colorsAsRgbString.greyDarkest,
    bcgColorMultiValue: colorsAsRgbString.greyDark,
    bcgColorDropdown: colorsAsRgbString.greyDark,
    fontColorInput: colorsAsRgbString.greyLight,
    fontColorMenuSelected: colorsAsRgbString.greyLighter,
    fontColorMenuInactive: colorsAsRgbString.grey,
    fontColorPlaceholder: colorsAsRgbString.greyLight,
    indicatorColor: colorsAsRgbString.grey
  }
};

const computeStyles = ({ theme = "light", label, isMulti }: { theme?: string; label: string; isMulti?: boolean }) => {
  return {
    control: (provided: any) => {
      return {
        ...provided,
        height: "auto",
        minHeight: "35px",
        boxShadow: "none !important",
        borderColor: layout[theme].borderColor,
        borderRadius: isMulti ? 10 : 40,
        paddingLeft: "5px",
        // @ts-ignore
        backgroundColor: layout[theme].bcgColorInput, // main background color
        borderStyle: "solid",
        // @ts-ignore
        color: layout[theme].fontColorInput,
        marginTop: label ? "10px" : "0px",
        "&:hover": {
          // borderColor: "transparent",
          // @ts-ignore
          color: layout[theme].fontColorInput
        }
      };
    },
    clearIndicator: (base: any) => {
      // The cross to delete values
      return {
        ...base,
        // @ts-ignore
        color: layout[theme].indicatorColor,
        "&:hover": {
          // @ts-ignore
          color: layout[theme].indicatorColor
        }
      };
    },
    indicator: (base: any) => {
      // The vertical bar
      return { ...base };
    },
    indicatorContainer: (base: any) => {
      // The vertical bar
      return { ...base, height: "30px" };
    },
    indicatorSeparator: () => {
      // The vertical bar
      return { display: "none" };
    },
    dropdownIndicator: (base: any) => {
      // The arrow to open the dropdown
      return {
        ...base,
        // @ts-ignore
        color: layout[theme].indicatorColor,
        "&:hover": {
          // @ts-ignore
          color: layout[theme].indicatorColor
        }
      };
    },
    menu: (base: any) => ({
      ...base,
      zIndex: 2,
      // @ts-ignore
      backgroundColor: layout[theme].bcgColorDropdown,
      borderRadius: 10
    }),
    menuList: (base: any) => ({
      ...base,
      zIndex: 2,
      borderRadius: 10
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      // @ts-ignore
      // color: layout[theme].indicatorColor,
      color: layout[theme].indicatorColor,
      borderRadius: 10,
      height: "auto"
    }),
    singleValue: (provided: any) => ({
      // Value shows at rest
      ...provided,
      // @ts-ignore
      // backgroundColor: layout[theme].bcgColorInput,
      // @ts-ignore
      color: layout[theme].fontColorInput,
      fontWeight: "700",
      "&:hover": {
        cursor: "pointer"
      }
    }),
    multiValue: (provided: any) => ({
      // Multi Value shows at rest
      ...provided,
      // @ts-ignore
      backgroundColor: layout[theme].bcgColorMultiValue
    }),
    multiValueLabel: (provided: any) => ({
      // Multi Value shows at rest
      ...provided,
      // @ts-ignore
      color: layout[theme].fontColorInput
    }),
    multiValueRemove: (provided: any) => ({
      // Multi Value shows at rest
      ...provided,
      // @ts-ignore
      color: layout[theme].fontColorInput,
      "&:hover": {
        cursor: "pointer",
        // @ts-ignore
        backgroundColor: layout[theme].indicatorColor,
        // @ts-ignore
        color: layout[theme].fontColorInput
      }
    }),
    input: (provided: any) => ({
      // input value as you type in
      ...provided,
      margin: "0px",
      backgroundColor: "transparent",
      // @ts-ignore
      color: layout[theme].fontColorInput
    }),
    option: (provided: any, state: { isFocused: boolean; isSelected: boolean; isDisabled: boolean; isActive: boolean }) => {
      // dropdown input value
      return {
        ...provided,
        borderColor: "transparent",
        // @ts-ignore
        color: state.isSelected ? layout[theme].fontColorMenuSelected : layout[theme].fontColorMenuInactive,
        // @ts-ignore
        backgroundColor: state.isFocused
          ? layout[theme].bcgColorInputHover
          : state.isActive && state.isDisabled
          ? layout[theme].bcgColorInputActive
          : layout[theme].bcgColorInput,
        "&:hover": {
          cursor: "pointer"
        }
      };
    }
  };
};

const path = (layout: any, props: any) => layout[props.theme.name || "light"];

const Styledlabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(layout, props).fontColorLabel};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Select = (props: any) => {
  const { t } = useTranslation();
  const { name: theme } = useContext(ThemeContext);

  const Component: any = props.withPagination ? AsyncPaginate : ReactSelect;

  return (
    <Container className="cg-common cg-select">
      {props.label && (
        <Styledlabel htmlFor={props.id || props.name || ""} className={`cg-common ${props.className || ""}-label`}>
          {props.label}
        </Styledlabel>
      )}
      <Component
        {...props}
        className={`select ${props.className || ""}`}
        styles={computeStyles({ theme, isMulti: props.isMulti, label: props.label })}
        isMulti={props.isMulti || false}
        classNamePrefix="select"
        isDisabled={props.readOnly || props.readonly || props.disabled}
        placeholder={props.placeholder ? props.placeholder : t("Select") + "..."}
      />
    </Container>
  );
};

const AsyncCreatable = withAsyncPaginate(Creatable);
const SelectCreatable = (props: any) => {
  const { t } = useTranslation();
  const { name: theme } = useContext(ThemeContext);

  const Component: any = props.withPagination ? AsyncCreatable : Creatable;

  return (
    <Container className="cg-common cg-select cg-selectCreatable">
      {props.label && (
        <Styledlabel htmlFor={props.id || props.name || ""} className={`cg-common ${props.className || ""}-label`}>
          {props.label}
        </Styledlabel>
      )}
      <Component
        {...props}
        className={props.className || "select"}
        classNamePrefix="select"
        styles={computeStyles({ theme, label: props.label, isMulti: props.isMulti })}
        isMulti={props.isMulti || false}
        isClearable={props.isClearable === undefined || false}
        placeholder={props.placeholder ? props.placeholder : t("Select") + "..."}
      />
    </Container>
  );
};

const SelectAsync = (props: any) => {
  const { t } = useTranslation();
  const { name: theme } = useContext(ThemeContext);

  return (
    <Container className="cg-common cg-select cg-selectAsync">
      {props.label && (
        <Styledlabel htmlFor={props.id || props.name || ""} className={`cg-common ${props.className || ""}-label`}>
          {props.label}
        </Styledlabel>
      )}
      <Async
        className={`select ${props.className || ""}`}
        styles={computeStyles({ theme, label: props.label, isMulti: props.isMulti })}
        classNamePrefix="select"
        isDisabled={props.readonly}
        placeholder={props.placeholder ? props.placeholder : t("Select") + "..."}
        {...props}
      />
    </Container>
  );
};

export { Select, SelectAsync, SelectCreatable };
