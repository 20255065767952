import styled from "styled-components";
import { colorsAsRgbString } from "@common-ground-io/colors";

const getBackgroundColor = ({ theme, inverted }: { theme?: { name: string }; inverted?: boolean }) => {
  if (!theme) return colorsAsRgbString.greyLighter;

  if (theme.name === "light") {
    if (inverted) return colorsAsRgbString.greyLightest;
    else return colorsAsRgbString.greyLighter;
  } else if (theme.name === "dark") {
    if (inverted) return colorsAsRgbString.greyDarkest;
    else return colorsAsRgbString.greyDarker; // applyOpacity(colorsAsRgbArray.greyDarker, 0.5);
  }
};

const StyledDiv = styled.div<{ inverted?: boolean }>`
  border-radius: 10px;
  padding: 15px;
  background-color: ${props => getBackgroundColor({ theme: props.theme, inverted: props.inverted })};
`;

const ZoneComponent = (props: any) => (
  <StyledDiv {...props} className={`cg-common cg-zone ${props.className || ""}`}>
    {props.children}
  </StyledDiv>
);

export default ZoneComponent;
