import { useMemo, useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { colorsAsRgbString } from "@common-ground-io/colors";
import debounce from "lodash.debounce";

const inputLayout = {
  light: {
    fontColorLabel: colorsAsRgbString.greyOvercast,
    bcgColorResult: colorsAsRgbString.greyLight,
    bcgColorResultSecondary: colorsAsRgbString.greyLightest,
    bcgColorInput: colorsAsRgbString.greyLighter,
    borderColor: colorsAsRgbString.greyLight,
    fontColorInput: colorsAsRgbString.greyDarker,
    fontColorResult: colorsAsRgbString.greyDarker,
    fontColorInputReadonly: colorsAsRgbString.greyLight,
    fontColorPlaceholder: colorsAsRgbString.greyOvercast
  },
  dark: {
    fontColorLabel: colorsAsRgbString.grey,
    bcgColorResult: colorsAsRgbString.greyDarker,
    bcgColorResultSecondary: colorsAsRgbString.greyDark,
    bcgColorInput: colorsAsRgbString.greyDark,
    borderColor: "transparent",
    fontColorInput: colorsAsRgbString.greyLighter,
    fontColorResult: colorsAsRgbString.greyLighter,
    fontColorResultHover: colorsAsRgbString.greyLight,
    fontColorInputReadonly: colorsAsRgbString.grey,
    fontColorPlaceholder: colorsAsRgbString.grey
  }
};

const iconLayout = {
  light: {
    bcgColor: colorsAsRgbString.greyLighter,
    fontColor: colorsAsRgbString.grey,
    borderColorHover: colorsAsRgbString.grey,
    borderColorActive: "transparent",
    fontColorHover: colorsAsRgbString.grey,
    bcgColorActive: colorsAsRgbString.grey,
    fontColorActive: colorsAsRgbString.greyLightest,
    separatorColor: colorsAsRgbString.greyLighter
  },
  dark: {
    bcgColor: colorsAsRgbString.greyDark,
    fontColor: colorsAsRgbString.greyLight,
    borderColorHover: colorsAsRgbString.greyLight,
    fontColorHover: colorsAsRgbString.greyLight,
    bcgColorActive: colorsAsRgbString.greyLight,
    borderColorActive: "transparent",
    fontColorActive: colorsAsRgbString.greyDark,
    separatorColor: colorsAsRgbString.greyDark
  }
};

const Styledlabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(inputLayout, props).fontColorLabel};
`;

const StyledInput = styled.input.attrs({ autoComplete: "off", autoFill: "off" })`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid ${props => path(inputLayout, props).borderColor};

  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: 0;

  box-shadow: none;
  outline: none;

  font-style: normal;
  font-family: inherit;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  width: 100%;
  background-color: ${props => path(inputLayout, props).bcgColorInput};
  color: ${props => path(inputLayout, props).fontColorInput};

  &:read-only {
    color: ${props => path(inputLayout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(inputLayout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

const StyledIconZone = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  height: auto;
  border: 1px solid ${props => path(inputLayout, props).borderColor};

  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding: 4px;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-style: normal;
  line-height: 1;
  text-align: center;
  margin: 0;
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(iconLayout, props).bcgColor};
  color: ${props => path(iconLayout, props).fontColor};
  margin-left: -1px;
`;

const StyledResults = styled.div`
  background-color: ${props => path(inputLayout, props).bcgColorResult};
  position: absolute;
  border-radius: 10px;
  top: 40px;
  height: auto;
  max-height: 30vh;
  overflow-y: scroll;
  width: calc(100% - 0px);
  z-index: 2;
`;

const StyledResult = styled.div`
  text-decoration: none;
  padding: 10px;
  background-color: ${props => path(inputLayout, props).bcgColorResult};
  &:nth-child(even) {
    background-color: ${props => path(inputLayout, props).bcgColorResultSecondary};
  }
`;

const StyledContainer = styled.div<{ label?: string; fullWidth?: boolean }>`
  display: flex;
  align-content: flex-start;
  margin-top: ${props => (props.label ? "10px" : "0px")};
  position: relative;
`;

const Container = styled.div`
  display: block;
`;

const path = (layout: any, props: any) => layout[props.theme.name || "light"];

const SearchComponent = (props: any, ref: any) => {
  const [term, setTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<any>("");
  const inputRef = useRef<any>();

  const localRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({ close: resetAndClose, results }));

  const eventHandler = (term: string) => {
    if (props.search) {
      props
        .search(term)
        .then((results: any) => {
          setResults(results);
          setIsSearching(false);
        })
        .catch((e: any) => console.error(e));
    }
  };

  const changeHandler = (e: any) => {
    const value = e.target.value;
    setIsSearching(true);
    setTerm(value);
    if (value) debouncedChangeHandler(value);
    else resetAndClose();
  };

  const resetAndClose = () => {
    setResults(null);
    setTerm("");
    setIsSearching(false);
  };

  const handleClickOutside = (event: any) => {
    if (localRef.current && !localRef.current.contains(event.target)) {
      resetAndClose();
    }
  };

  const handleResultClick = (options: any) => {
    resetAndClose();
    if (options?.focus && inputRef.current) inputRef.current.focus();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  });

  const debouncedChangeHandler = useMemo(() => debounce(eventHandler, props.debounceTime || 300), []);

  return (
    <Container
      ref={localRef}
      id={props.id || undefined}
      style={props.style || {}}
      className={`cg-common cg-search ${props.className || ""}`}>
      {props.label && (
        <Styledlabel htmlFor={props.id || props.name || ""} className={`cg-common ${props.className || ""} cg-label`}>
          {props.label}
        </Styledlabel>
      )}
      <StyledContainer label={props.label}>
        <StyledInput
          ref={inputRef}
          type="text"
          name="search"
          value={term}
          onChange={changeHandler}
          placeholder={props.placeholder || "Search..."}
          className={`cg-common cg-input  ${props.className || ""}`}
        />
        <StyledIconZone>
          <i className="cg-icon-search" />
        </StyledIconZone>
        <StyledResults className="results">
          {isSearching ? (
            <StyledResult className="result">
              <p>Searching...</p>
            </StyledResult>
          ) : null}
          {!isSearching && results && Array.isArray(results) && results.length === 0 ? (
            <StyledResult className="result">
              <p>No results...</p>
            </StyledResult>
          ) : null}
          {!isSearching && results && Array.isArray(results) && results.length
            ? results.map((entry, i) => (
                <StyledResult key={i} className="result">
                  <props.suggestionComponent entry={entry} results={results} term={term} handleResultClick={handleResultClick} />
                </StyledResult>
              ))
            : null}
        </StyledResults>
      </StyledContainer>
    </Container>
  );
};

export default forwardRef(SearchComponent);
