import React from "react";
import { StyledContainer, StyledInput } from "./SearchInput.styles";
import { CSSProperties } from "styled-components";
import { SearchIcon } from "../SVGIcons";

type Props = {
  handleChange: () => void;
  styleProps?: CSSProperties;
};

export const SearchInput: React.FC<Props> = ({ styleProps }) => {
  return (
    <StyledContainer style={styleProps}>
      <StyledInput type="search" placeholder="Search..." />
      <SearchIcon />
    </StyledContainer>
  );
};
