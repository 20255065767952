import { useEffect, useRef, useState } from "react";
import Button from "../styled/button";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalStore } from "../../stores/global";
import { renderEntries } from "../lib/menuUtils";
import useOutsideClick from "../../hooks/useOutsideClick";
import { colorsAsRgbString } from "@common-ground-io/colors";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { QuickstartButton } from "./tutorial";

const StyledMainMenu = styled.div`
  background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDarker)};
`;

export const mainMenuEntries = (t: TFunction<"ns1", undefined>) => [
  { type: "li", name: t("Dashboard"), icon: "cg-icon-dashboard", path: "/", subPaths: ["/dashboard"] },
  { type: "hr", className: "invisible" },
  {
    type: "li",
    name: t("Inventory"),
    icon: "cg-icon-inventory",
    path: "/inventory",
    subPaths: ["/item", "/inventory", "/release"]
  },
  { type: "hr", className: "invisible" },
  { type: "li", name: t("Orders"), icon: "cg-icon-orders", path: "/orders" },
  { type: "li", name: t("Checkouts"), icon: "cg-icon-checkout", path: "/order/new" },
  { type: "li", name: t("Credit notes"), icon: "cg-icon-credit-notes", path: "/creditnotes" },
  { type: "li", name: t("Customers"), icon: "cg-icon-customers", path: "/users" },
  { type: "li", name: t("Vouchers"), icon: "cg-icon-vouchers", path: "/vouchers" },
  { type: "li", name: t("Messages"), icon: "cg-icon-messages", path: "/messages" },
  { type: "hr", className: "invisible" },
  { type: "li", name: t("Eshop editor"), icon: "cg-icon-eshop-editor", path: "/editor/themes" },
  { type: "li", name: t("Media"), icon: "cg-icon-media", path: "/media" },
  { type: "hr", className: "invisible" },
  { type: "li", name: t("Stickers"), icon: "cg-icon-stickers", path: "/stickers" },
  { type: "li", name: t("Jobs"), icon: "cg-icon-exports", path: "/jobs" },
  { type: "li", name: t("Newsletter"), icon: "cg-icon-newsletters", path: "/newsletters" },
  { type: "hr", className: "invisible" },
  { type: "li", name: t("Preferences"), icon: "cg-icon-preferences", path: "/preferences" }
];

export const bottomEntries = (t: TFunction<"ns1", undefined>) => [
  { type: "li", name: t("Guide"), external: true, path: "https://guide.common-ground.io/" },
  { type: "li", name: t("FAQ"), external: true, path: "https://common-ground.io/faq" },
  {
    type: "li",
    name: t("Tutorials"),
    external: true,
    path: "https://www.youtube.com/watch?v=OIOylL8IwbA&list=PLCP8BkUFAK-gxEj4vtC4A6jpbyAyBZd6Y"
  },
  { type: "hr", className: "invisible" },
  { type: "li", name: t("Bug report"), path: "/support?type=bug+report" },
  { type: "li", name: t("Feedback"), path: "/support?type=feedback" },
  { type: "li", name: t("Feature request"), path: "/support?type=feature+request" },
  { type: "li", name: t("Support"), path: "/support" }
];

export default function Menu() {
  const { isInEditorView, isBelowIpad, menuIsCollapsed, isMobile } = GlobalStore.useState(c => c);
  const [isBottomMenuOpen, setIsBottomMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const isInEditorView = location.pathname.includes("/editor");
    GlobalStore.update(s => {
      s.isInEditorView = isInEditorView;
    });
  }, [location.pathname]);

  const handleCollapse = (state: boolean) => {
    GlobalStore.update(s => {
      s.menuIsCollapsed = state;
    });
    const app = document.getElementById("app");
    app?.classList.remove(state ? "open" : "closed");
    app?.classList.add(state ? "closed" : "open");
  };

  useEffect(() => {
    handleCollapse(isInEditorView);
  }, [isInEditorView]);

  return (
    <StyledMainMenu
      id="mainMenu"
      className={`${isBelowIpad ? "mobile" : ""} ${isInEditorView ? "isInEditorView" : ""} ${menuIsCollapsed ? "collapsed" : "open"}`}
      onWheel={() => {
        setIsBottomMenuOpen(false);
        // @ts-expect-error element blur
        document.activeElement.blur();
      }}>
      <nav id="menu">
        <MenuEntries
          isMobile={isMobile}
          isCollapsed={menuIsCollapsed}
          isBottomMenuOpen={isBottomMenuOpen}
          setIsBottomMenuOpen={setIsBottomMenuOpen}
        />
      </nav>
    </StyledMainMenu>
  );
}

const StyledEntries = styled.div<{ isMobile?: boolean }>`
  ol li.active > a:not(.toggleBottomEntriesBtn) span {
    color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.black : colorsAsRgbString.white)};
  }
  ol li:hover > a:not(.toggleBottomEntriesBtn) span {
    color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyDarkest : colorsAsRgbString.greyLighter)};
  }
  ol li.active:has(.submenu li.active) > a span {
    color: currentColor;
  }
  ol li.active ol.submenu.active li.active a i {
    color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.black : colorsAsRgbString.white)};
  }
  > ol > li.active > a > .icon-wrapper,
  > ol > li:hover > a > .icon-wrapper {
    background-color: ${({ theme, isMobile }) =>
      isMobile ? "transparent" : theme.name === "light" ? colorsAsRgbString.greyLight : colorsAsRgbString.greyDark};
  }

  &:hover > a:not(.toggleBottomEntriesBtn) {
    > span {
      color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyDarkest : colorsAsRgbString.greyLighter)};
    }
    .icon-wrapper {
      background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDark)};
    }
  }
  &:has(.submenu.active .active) > a span {
    color: unset;
  }
  &.isCollapsed ol li:not(.hasSubmenu).active {
    color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.black : colorsAsRgbString.white)};
  }
  &.isCollapsed .bottomEntries:hover .icon-wrapper {
    background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDark)};
  }
  &.mobile ol li {
    a:not(.toggleBottomEntriesBtn) .icon-wrapper {
      background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLightest : colorsAsRgbString.greyDarker)};
    }
    &.active a > .icon-wrapper {
      background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDark)};
    }
  }
`;

export const MenuEntries = ({
  isMobile,
  isCollapsed,
  isBottomMenuOpen,
  setIsBottomMenuOpen
}: {
  isMobile?: boolean;
  isCollapsed?: boolean;
  isBottomMenuOpen?: boolean;
  setIsBottomMenuOpen: (state: boolean) => void;
}) => {
  const entriesRef = useRef(null);
  const { t } = useTranslation();

  return (
    <>
      <StyledEntries
        isMobile={isMobile}
        className={`entries ${isMobile ? "mobile" : ""} ${isCollapsed ? "isCollapsed" : ""}`}
        ref={entriesRef}>
        <ol>{renderEntries(mainMenuEntries(t))}</ol>
        <BottomMenuEntries
          isBottomMenuOpen={isBottomMenuOpen || false}
          setIsBottomMenuOpen={setIsBottomMenuOpen}
          isMobile={isMobile || false}
          isCollapsed={isCollapsed || false}
        />
      </StyledEntries>
    </>
  );
};

const StyledBottomEntries = styled.ol`
  background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDarker)};
  ol {
    background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDark)};
  }
  &.open {
    color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyDark : colorsAsRgbString.greyLighter)};
    > li > a {
      color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.black : colorsAsRgbString.white)};
      &:hover {
        color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.black : colorsAsRgbString.white)};
      }
    }
  }
  &:not(.mobile):hover .toggleBottomEntriesBtn,
  &.open .toggleBottomEntriesBtn {
    background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDark)};
  }
  &.open .bottomEntriesDropdown li.active > a > span {
    color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyDark : colorsAsRgbString.greyLighter)};
  }
  &.open .bottomEntriesDropdown li > a:hover > span {
    color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyDarker : colorsAsRgbString.white)};
  }
  &.mobile {
    background-color: unset;
    .bottomEntriesDropdown {
      background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.white : colorsAsRgbString.greyDarkest)};
      ol {
        background-color: unset;
        li {
          background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLightest : colorsAsRgbString.greyDarker)};
          &.active {
            color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.black : colorsAsRgbString.white)};
          }
        }
      }
    }
  }
`;

const BottomMenuEntries = ({
  isBottomMenuOpen,
  setIsBottomMenuOpen,
  isMobile,
  isCollapsed
}: {
  isBottomMenuOpen: boolean;
  setIsBottomMenuOpen: any;
  isMobile: boolean;
  isCollapsed: boolean;
}) => {
  const bottomEntriesRef = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();

  function toggleBottomEntries(e: any) {
    e.preventDefault();
    setIsBottomMenuOpen(!isBottomMenuOpen);
  }

  useOutsideClick(bottomEntriesRef, () => {
    setIsBottomMenuOpen(false);
  });

  useEffect(() => {
    return history.listen(() => {
      setIsBottomMenuOpen(false);
    });
  }, [history]);

  return (
    <StyledBottomEntries
      className={`bottomEntries ${isBottomMenuOpen ? "open" : ""} ${isMobile ? "mobile" : ""} ${isCollapsed ? "isCollapsed" : ""}`}
      ref={bottomEntriesRef}>
      <li onClick={(e: any) => toggleBottomEntries(e)} className="helpMenuButton">
        <a href="#" className="toggleBottomEntriesBtn">
          <div className="icon-wrapper">
            <i className="cg-icon-support" />
          </div>
          <span>{t("Help?")}</span>
        </a>
      </li>
      <div className="bottomEntriesDropdown">
        {isMobile && (
          <Button type="button" variant="noStyle" className="close" onClick={toggleBottomEntries}>
            <i className="cg-icon-burger-close" />
          </Button>
        )}
        <ol>
          <li>
            <QuickstartButton />
          </li>
          {renderEntries(bottomEntries(t))}
        </ol>
      </div>
    </StyledBottomEntries>
  );
};
