import styled from "styled-components";
import { colorsAsRgbString } from "@common-ground-io/colors";
import { useTranslation } from "react-i18next";

const inputLayout = {
  light: {
    fontColorLabel: colorsAsRgbString.greyOvercast,
    bcgColorInput: colorsAsRgbString.greyLighter,
    borderColor: colorsAsRgbString.greyLight,
    fontColorInput: colorsAsRgbString.greyDarker,
    fontColorInputReadonly: colorsAsRgbString.greyLight,
    fontColorPlaceholder: colorsAsRgbString.grey
  },
  dark: {
    fontColorLabel: colorsAsRgbString.grey,
    bcgColorInput: colorsAsRgbString.greyDark,
    borderColor: "transparent",
    fontColorInput: colorsAsRgbString.greyLighter,
    fontColorInputReadonly: colorsAsRgbString.grey,
    fontColorPlaceholder: colorsAsRgbString.greyLight
  }
};

const buttonLayout = {
  light: {
    bcgColor: colorsAsRgbString.greyLighter,
    fontColor: colorsAsRgbString.greyOvercast,
    borderColor: colorsAsRgbString.greyLight,
    borderColorHover: colorsAsRgbString.greyLight,
    fontColorHover: colorsAsRgbString.grey,
    bcgColorActive: colorsAsRgbString.grey,
    borderColorActive: "transparent",
    fontColorActive: colorsAsRgbString.greyLightest,
    separatorColor: colorsAsRgbString.greyLighter
  },
  dark: {
    bcgColor: colorsAsRgbString.greyDark,
    fontColor: colorsAsRgbString.greyLight,
    borderColor: "transparent",
    borderColorHover: colorsAsRgbString.greyLight,
    fontColorHover: colorsAsRgbString.greyLight,
    bcgColorActive: colorsAsRgbString.greyLight,
    borderColorActive: "transparent",
    fontColorActive: colorsAsRgbString.greyDark,
    separatorColor: colorsAsRgbString.greyDark
  }
};

const Styledlabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(inputLayout, props).fontColorLabel};
`;

const StyledInput = styled.input<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border: 1px solid ${props => path(inputLayout, props).borderColor};
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: 0;

  box-shadow: none;
  outline: none;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(inputLayout, props).bcgColorInput};
  color: ${props => path(inputLayout, props).fontColorInput};

  &:read-only {
    color: ${props => path(inputLayout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(inputLayout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  ::-webkit-calendar-picker-indicator {
    filter: ${({ theme }) =>
      theme.name === "light"
        ? "invert(23%) sepia(0%) saturate(1432%) hue-rotate(140deg) brightness(111%) contrast(87%);"
        : "invert(50%) sepia(0%) saturate(3%) hue-rotate(168deg) brightness(95%) contrast(84%);"};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

const StyledButton = styled.button<{ fullWidth?: boolean }>`
  height: auto;
  padding: 5px 10px;
  border: 1px solid ${props => path(inputLayout, props).borderColor};
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-style: normal;
  line-height: 1;
  text-align: "center";
  margin: 0;
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(buttonLayout, props).bcgColor};
  color: ${props => path(buttonLayout, props).fontColor};
  border-left: 1px solid ${props => path(buttonLayout, props).separatorColor};

  &:hover {
    border: 1px solid;
    border-color: ${props => path(buttonLayout, props).borderColorHover};
    color: ${props => path(buttonLayout, props).fontColorHover};
  }

  &:active {
    border: 1px solid;
    border-color: ${props => path(buttonLayout, props).borderColorActive};
    background-color: ${props => path(buttonLayout, props).bcgColorActive};
    color: ${props => path(buttonLayout, props).fontColorActive};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const path = (layout: any, props: any) => layout[props.theme.name || "light"];

const InputWithSubmitComponent = (props: any) => {
  const { t } = useTranslation();

  return (
    <Container className="cg-common cg-inputWithSubmit">
      {props.label && (
        <Styledlabel htmlFor={props.id || props.name || ""} className={`cg-common ${props.className || ""}-label`}>
          {props.label}
        </Styledlabel>
      )}
      <StyledContainer>
        <StyledInput
          {...props}
          onWheel={(e: any) => (e.target as HTMLElement).blur()}
          className={`cg-common cg-input  ${props.className || ""}`}
        />
        <StyledButton
          onClick={props.onSubmit || props.onClick}
          className={`cg-common cg-button ${props.className || ""}`}
          type={props.buttonType || "submit"}>
          {props.submitText || t("Submit")}
        </StyledButton>
      </StyledContainer>
    </Container>
  );
};

export default InputWithSubmitComponent;
