import { Select } from "../styled/select";
import { Session } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";

const ShopSelector = ({
  domainList,
  onChange,
  session,
  selectedDomain
}: {
  selectedDomain: string;
  onChange: any;
  session: Session;
  domainList: { active: boolean; value: string; label: string }[];
}) => {
  const handleConfigSelect = ({ value }: { value: string }) => {
    onChange(value);
  };
  const { t } = useTranslation();
  const selected = domainList.find(d => d.value === selectedDomain);
  const sortF = (a: { label: string }, b: { label: string }) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());
  const payingConfigs = domainList.filter(d => d.active).sort(sortF);
  const nonPayingConfigs = domainList.filter(d => !d.active).sort(sortF);
  return (
    <div id="domainSelect">
      {domainList && (
        <Select
          options={
            session.user.type === "SuperAdmin"
              ? [
                  { label: `Active (${payingConfigs.length})`, options: payingConfigs },
                  { label: `Idled (${nonPayingConfigs.length})`, options: nonPayingConfigs }
                ]
              : domainList
          }
          value={selected}
          onChange={handleConfigSelect}
          placeholder={t("Select your shop")}
        />
      )}
    </div>
  );
};

export default ShopSelector;
