import { useState, useEffect } from "react";
import URI from "urijs";
import NewProduct from "./edit.product";
import NewRelease from "./edit.release";
import NewBook from "./edit.book";
import { getDefaultListingData } from "./inputs";
import { GlobalStore } from "../../stores/global";
import Four0Four from "../global/404";
import Loader from "../common/loader";
import { useLazyQuery } from "@apollo/client";
import { GET_ITEM } from "../../graphql/queries/item";
import { Config, Item } from "../../__generated__/graphql";
import { Match } from "../../types/globals";
import { useTranslation } from "react-i18next";
import clone from "clone";

const ItemEdit = ({ match, location }: { match: Match; location: Location }) => {
  const { config, configReload, addNotification } = GlobalStore.useState(c => c);
  const { t } = useTranslation();
  const currentUri = new URI(location.pathname + location.search);
  const search = currentUri.search(true);
  const type = search && search.type;
  const isNew = match.path.includes("new");
  const [item, setItem] = useState<Item | null | undefined>();
  const [getItem] = useLazyQuery(GET_ITEM, { fetchPolicy: "cache-and-network" });
  const id = match.params.id;

  useEffect(() => {
    const getData = async () => {
      const id = match.params.id;
      try {
        const { data } = await getItem({ variables: { id: parseInt(id) } });
        if (data) setItem(clone(data.item));
      } catch (e: any) {
        setItem(null);
      }
    };
    if (!isNew) getData();
    else {
      let initialData: any = { weight: "", title: "" };
      if (type === "ReleaseItem")
        initialData = {
          ...initialData,
          title: "",
          images: [],
          styles: [],
          genres: [],
          labels: [],
          country: "",
          artists: [],
          // eslint-disable-next-line quotes
          formats: [{ name: "Vinyl", qty: "1", descriptions: ['12"'] }],
          tracklist: [],
          weight: "",
          assetLink: ""
        };
      else if (type === "ProductItem")
        initialData = {
          ...initialData,
          images: [],
          cat: "",
          manufacturer: "",
          type: "",
          giftCard: {}
        };
      else if (type === "BookItem")
        initialData = {
          ...initialData,
          images: [],
          subtitle: "",
          authors: [],
          publisher: "",
          identifiers: [],
          pageCount: "",
          categories: [],
          maturityRating: "",
          language: "",
          publishedDate: new Date()
        };

      setItem({
        type,
        listings: [getDefaultListingData(config as Config, type)],
        descriptions: { shop: { text: "", html: "" } },
        data: initialData
      } as any);
    }
  }, [match.params.id, type, config, match.path]);

  if (!isNew && item === null) return <Four0Four />;
  else if (!isNew && item === undefined) return <Loader />;
  else if (!item) return null;

  const isProduct = item.type === "ProductItem";
  const isBook = item.type === "BookItem";
  const isRelease = item.type === "ReleaseItem";

  if (isProduct)
    return (
      <NewProduct
        item={item}
        config={config as Config}
        configReload={configReload}
        isNew={isNew}
        setItem={setItem}
        addNotification={addNotification}
        t={t}
      />
    );
  else if (isBook)
    return (
      <NewBook
        item={item}
        config={config as Config}
        configReload={configReload}
        isNew={isNew}
        setItem={setItem}
        addNotification={addNotification}
        t={t}
      />
    );
  else if (isRelease)
    return (
      <NewRelease config={config as Config} id={parseInt(id)} isNew={isNew} setItem={setItem} addNotification={addNotification} t={t} />
    );
};

export default ItemEdit;
