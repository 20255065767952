import React, { useEffect, useState } from "react";

export const usePopupPosition = (
  containerRef: React.RefObject<HTMLDivElement>,
  popupRef: React.RefObject<HTMLDivElement>,
  isOpen: boolean
) => {
  const [popupPosition, setPopupPosition] = useState({ left: "0px", top: "0px" });

  const adjustPopupPosition = () => {
    if (!containerRef.current || !popupRef.current) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const topPosition = `${containerRect.top + containerRect.height + 5}px`;
    const popup = popupRef.current;
    const viewportWidth = window.innerWidth;

    if (containerRect.left + popup.offsetWidth > viewportWidth) {
      setPopupPosition({ left: `${containerRect.right - (popupRef.current?.offsetWidth ?? 0)}px`, top: topPosition });
    } else {
      setPopupPosition({ left: `${containerRect.left}px`, top: topPosition });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        adjustPopupPosition();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isOpen]);

  useEffect(() => {
    adjustPopupPosition();
  }, [isOpen, popupRef?.current?.clientWidth]);

  return popupPosition;
};
