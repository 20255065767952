import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "../styled/button";
import { Typography } from "../../componentsV2/Typography";
import { Link, useLocation, useHistory } from "react-router-dom";
import { SearchItems } from "../global/search";
import Header from "../global/header";
import { Select } from "../styled/select";
import { ConfigContextData } from "../../contexts/ConfigContext";
import { MenuEntries } from "../../components/global/menu";
import { GlobalStore } from "../../stores/global";
import NotificationCenter from "../../components/notificationCenter";
import styled, { css } from "styled-components";
import { colorsAsRgbString } from "@common-ground-io/colors";
import useClickOutside from "../../hooks/useOutsideClick";

import LanguageSelector from "../../components/languageSelector";
import { AddReleaseLink } from "../items/add";
import { getConfigProperty } from "../../utils";
import { Config, Session } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { EThemes } from "../../types/globals";
import { AddItemStore } from "../../stores/AddItem";
import { QuickstartButton } from "./tutorial";

const CommunityButton = React.lazy(() => import("../../pages/community"));

const isProduction = process.env.VITE_BUILD_ENV === "production";

const LinkWithSubmenu = ({ config, session, configSelect }: { config: Config; session: Session; configSelect: any }) => {
  const history = useHistory();

  const handleConfigChange = async (value: any) => {
    if (value.value === "addAccount") return history.push("/register");
    else await configSelect(value.value);
  };

  const options: { label: string; value: string; active?: boolean; isDisabled?: boolean }[] | undefined =
    session?.adminDomains.map(d => ({
      label: d.siteName,
      value: d.domainName,
      active: d.active,
      isDisabled: config.domain === d.domainName
    })) || [];
  options.push({ label: "+ Add a new store", value: "addAccount" });
  const sorter = (a: any, b: any) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
  const activeConfigs = options.filter(c => c.active).sort(sorter);
  const idleConfigs = options.filter(c => !c.active).sort(sorter);

  let optionsToDisplay;
  if (session.user.type === "SuperAdmin") {
    optionsToDisplay = [
      { label: "Active", options: activeConfigs },
      { label: "Inactive", options: idleConfigs }
    ];
  } else optionsToDisplay = options;

  return (
    <Select
      fullWidth
      variant="overZone"
      className="selector"
      onChange={handleConfigChange}
      options={optionsToDisplay}
      value={{ label: getConfigProperty(config, "information", "shopName") }}
    />
  );
};

export default function Navbar() {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const { config, session, version, isBelowIpad, logout, theme, menuIsCollapsed } = GlobalStore.useState(c => c);
  const { configSelect } = useContext(ConfigContextData);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const shortcutsRef = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();
  const drafts = AddItemStore.useState(s => s.drafts);

  const handleThemeChange = (themeToApply: EThemes) => {
    if (themeToApply)
      GlobalStore.update(s => {
        s.theme = themeToApply;
      });
  };

  const handleCollapse = (state: boolean) => {
    GlobalStore.update(s => {
      s.menuIsCollapsed = state;
    });
    const app = document.getElementById("app");
    app?.classList.remove(state ? "open" : "closed");
    app?.classList.add(state ? "closed" : "open");
  };

  const handleSearchPage = (e: any) => {
    e.preventDefault();
    history.push(`/search?term=${e.target.search.value}`);
  };

  useClickOutside(shortcutsRef, () => {
    setIsSearching(false);
  });

  const handleSearchClick = () => {
    setIsProfileOpen(false);
    setIsSearching(!isSearching);
  };

  const getEshopUri = (): string => {
    const maintenancePassword =
      getConfigProperty(config as Config, "information", "maintenanceMode") &&
      getConfigProperty(config as Config, "information", "maintenancePassword");
    let uri = "";
    if (!isProduction)
      uri = `https://front-staging.common-ground.io?domain=${config?.domain}&generation=${config?.eshop?.theme?.generation || 1}`;
    else uri = config?.uri as string;

    if (maintenancePassword) uri += `?preview=${maintenancePassword}&from=backoffice`;

    return uri;
  };

  useEffect(() => {
    if (isProfileOpen) {
      setIsSearching(false);
    }
  }, [isProfileOpen]);

  if (isBelowIpad)
    return (
      <Mobile
        logout={logout}
        version={version}
        isProfileOpen={isProfileOpen}
        configSelect={configSelect}
        setIsProfileOpen={setIsProfileOpen}
        handleThemeChange={handleThemeChange}
        session={session as Session}
        theme={theme}
        config={config as Config}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
      />
    );
  else
    return (
      <NavbarContainer id="navbarContainer">
        <NavbarComponent id="navbar">
          <div className="left">
            <Button variant="noStyle" type="button" onClick={() => handleCollapse(!menuIsCollapsed)}>
              {menuIsCollapsed ? <i className="cg-icon-burger" /> : <i className="cg-icon-burger-close" />}
            </Button>
            <LinkWithSubmenu configSelect={configSelect} session={session as Session} config={config as Config} />
          </div>
          <ul className="right">
            {process.env.VITE_BUILD_ENV !== "production" ? (
              <li>
                <h2>{process.env.VITE_BUILD_ENV}</h2>
              </li>
            ) : null}
            <li className="shortcuts" ref={shortcutsRef}>
              {drafts.release ? <Link to={"/inventory/add/release"}>{t("Draft")}</Link> : null}
              <AddReleaseLink isBelowIpad={isBelowIpad} theme={theme as EThemes} />
            </li>
            <li>
              <form id="navbarSearch" onSubmit={handleSearchPage} onClick={handleSearchClick}>
                <SearchItems formId="navbarSearch" placeholder={t("Find releases, products or books") + "..."} />
              </form>
            </li>
            <li>
              <CommunityButton />
            </li>
            <li>
              <a target="tab" href={getEshopUri()}>
                <button className="reset" type="button">
                  {t("My Eshop")}
                </button>
              </a>
            </li>
            <li>
              <NotificationCenter />
            </li>
            <li>
              <Profile
                version={version as string}
                session={session as Session}
                logout={logout}
                theme={theme as EThemes}
                isOpen={isProfileOpen}
                setIsOpen={setIsProfileOpen}
                handleThemeChange={handleThemeChange}
              />
            </li>
          </ul>
        </NavbarComponent>
      </NavbarContainer>
    );
}

const Mobile = ({
  session,
  config,
  configSelect,
  logout,
  version,
  theme,
  isProfileOpen,
  setIsProfileOpen,
  handleThemeChange,
  isSearching,
  setIsSearching
}: {
  session: Session;
  config: Config;
  configSelect: any;
  logout: () => void;
  version?: string;
  theme?: EThemes;
  isProfileOpen: boolean;
  setIsProfileOpen: (state: boolean) => void;
  handleThemeChange: (theme: EThemes) => void;
  isSearching: boolean;
  setIsSearching: (state: boolean) => void;
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) document.body.classList.add("noScroll");
    else document.body.classList.remove("noScroll");
  }, [isOpen]);

  useClickOutside(ref, () => {
    setIsSearching(false);
  });

  useEffect(() => {
    setIsOpen(false);
    setIsSearching(false);
  }, [location]);

  const handleSearchClick = () => {
    setIsProfileOpen(false);
    setIsSearching(!isSearching);
  };

  const handleSearchPage = (e: any) => {
    e.preventDefault();
    history.push(`/search?term=${e.target.search.value}`);
  };

  return (
    <>
      <NavbarContainer id="mobileNavbarContainer" ref={ref}>
        <nav id="mobileNavbar">
          <ul id="mobileNavbarList">
            <li>
              <Profile
                version={version}
                session={session}
                logout={logout}
                theme={theme as EThemes}
                isOpen={isProfileOpen}
                setIsOpen={setIsProfileOpen}
                handleThemeChange={handleThemeChange}
              />
            </li>
            <li className="shortcuts">
              <AddReleaseLink isBelowIpad={true} theme={theme as EThemes} />
            </li>
            <li>
              <Link to="/order/new">
                <i className="cg-icon-checkout" />
              </Link>
            </li>
            <li>
              <button type="button" className="reset" onClick={handleSearchClick}>
                <i className="cg-icon-search-mobile" />
              </button>
            </li>
            <li>
              <NotificationCenter />
            </li>
            <li>
              <Button type="button" variant="noStyle" className="burger reset" onClick={() => setIsOpen(!isOpen)}>
                <i className="cg-icon-burger" />
              </Button>
            </li>
          </ul>
          {isSearching ? (
            <div className="searchAndSelect">
              <form id="navbarSearch" onSubmit={handleSearchPage}>
                <SearchItems formId="navbarSearch" placeholder={t("Find releases, products or books")} />
              </form>
            </div>
          ) : null}
        </nav>
      </NavbarContainer>
      <MenuFullWidth config={config} configSelect={configSelect} session={session} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const MenuFullWidth = ({
  session,
  config,
  isOpen,
  configSelect,
  setIsOpen,
  isMobile = true
}: {
  session: Session;
  config: Config;
  isOpen: boolean;
  configSelect: (domain: string) => void;
  setIsOpen: (state: boolean) => void;
  isMobile?: boolean;
}) => {
  const [isBottomMenuOpen, setIsBottomMenuOpen] = useState(false);
  return (
    <MobileMenuContainer className={`mobileMainMenu ${isOpen ? "open" : "closed"}`}>
      <div className="header">
        {session?.adminDomains?.length > 1 ? (
          <Header
            selectedDomain={config.sites?.find(s => s?.isPrimary)?.domain as string}
            onChange={configSelect}
            session={session}
            domainList={
              session?.adminDomains?.map(d => ({
                label: d.siteName,
                value: d.domainName,
                active: d.active
              })) || []
            }
          />
        ) : null}
        <Button type="button" variant="noStyle" className="close" onClick={() => setIsOpen(!isOpen)}>
          <i className="cg-icon-burger-close" />
        </Button>
      </div>
      <MenuEntries isMobile={isMobile} isBottomMenuOpen={isBottomMenuOpen} setIsBottomMenuOpen={setIsBottomMenuOpen} />
    </MobileMenuContainer>
  );
};

const Profile = ({
  session,
  version,
  isOpen,
  setIsOpen,
  logout,
  theme,
  handleThemeChange
}: {
  session: Session;
  version?: string;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  logout: () => void;
  theme: EThemes;
  handleThemeChange: (theme: EThemes) => void;
}) => {
  const ref = useRef<any>();
  const location = useLocation();
  const { t } = useTranslation();

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <div className="profile" ref={ref}>
      <ProfileButton onClick={() => setIsOpen(!isOpen)} theme={theme} className="circled">
        {session.user.firstName?.substring(0, 1)}
        {session.user.lastName?.substring(0, 1)}
      </ProfileButton>
      {isOpen ? (
        <ProfileContainer theme={theme} className="dropdown open">
          <ProfileContainerSection theme={theme} className="header">
            <ProfileButton theme={theme} className="circled">
              {session.user.firstName?.substring(0, 1)}
              {session.user.lastName?.substring(0, 1)}
            </ProfileButton>
            <p>{session.user.name}</p>
          </ProfileContainerSection>
          <ProfileContainerSection odd theme={theme} className="containerSection">
            <div className="appearance">
              <p>{t("Appearance")}</p>
              <div className="themeSelector">
                <button type="button" className={theme === EThemes.dark ? "active" : ""} onClick={() => handleThemeChange(EThemes.dark)}>
                  {t("Dark")}
                </button>
                <span>/</span>
                <button type="button" className={theme === EThemes.light ? "active" : ""} onClick={() => handleThemeChange(EThemes.light)}>
                  {t("Light")}
                </button>
              </div>
            </div>
            <p>
              <Link to="/preferences">{t("Store preferences")}</Link>
            </p>
          </ProfileContainerSection>
          <ProfileContainerSection theme={theme} className="containerSection">
            {session.user.type === "SuperAdmin" ? <LanguageSelector /> : null}
            <p>
              <Link to="/profile">{t("Edit my profile")}</Link>
            </p>
            <p>
              <Link to="/profile/notifications">{t("Notifications settings")}</Link>
            </p>
          </ProfileContainerSection>
          <ProfileContainerSection odd theme={theme} className="containerSection">
            <p>
              <QuickstartButton />
            </p>
            <p>
              <Link to="/support">{t("Support")}</Link>
            </p>
            <p>
              <a href="https://docs.common-ground.io" target="_blank" rel="noreferrer">
                {t("Docs")}
              </a>
            </p>
          </ProfileContainerSection>
          <ProfileContainerSection theme={theme} className="containerSection">
            <Typography variant="copy">
              <p className="version">
                <span>{t("Backoffice version")}</span>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <span>v{version}</span>
              </p>
            </Typography>
          </ProfileContainerSection>
          <ProfileContainerSection theme={theme} odd className="containerSection">
            <p>
              <button className="logout" type="button" onClick={logout}>
                {t("Logout")}
              </button>
            </p>
          </ProfileContainerSection>
        </ProfileContainer>
      ) : null}
    </div>
  );
};

const ProfileButton = styled.button`
  display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin: 0;
  background: transparent;
  color: ${props => (props.theme === "dark" ? colorsAsRgbString.greyLight : colorsAsRgbString.greyDark)};
  border: none;
`;

const ProfileContainer = styled.div`
  font-weight: bold;
  border-radius: 15px;
  box-shadow: 0 0 6px 0 ${props => (props.theme === "dark" ? "#000" : colorsAsRgbString.greyLighter)};
  background-color: ${props => (props.theme === "dark" ? colorsAsRgbString.greyDarker : colorsAsRgbString.grey)};
`;

const ProfileContainerSection = styled.div<{ odd?: boolean }>`
  padding: var(--gutter);
  ${props =>
    props.odd
      ? css`
          background-color: ${props => (props.theme === "dark" ? colorsAsRgbString.greyDark : colorsAsRgbString.white)};
        `
      : css`
          background-color: ${props => (props.theme === "dark" ? colorsAsRgbString.greyDarker : colorsAsRgbString.greyLightest)};
        `}
`;

const NavbarComponent = styled.nav`
  &#navbar button.reset.toggleAddItemBtn {
    :hover {
      background-color: white;
    }
  }
`;
const NavbarContainer = styled.div`
  background-color: ${props => (props.theme.name === "dark" ? colorsAsRgbString.greyDarker : colorsAsRgbString.greyLightest)};
`;
const MobileMenuContainer = styled.div`
  background-color: ${props => (props.theme.name === "dark" ? colorsAsRgbString.black : colorsAsRgbString.white)};
`;
