import Zone from "../../components/styled/zone";

const Liner = (props: any) => {
  const { index, children, className } = props;
  const overZone = !(index % 2);
  if (overZone)
    return (
      <Zone
        {...props}
        className={className}
        overZone={overZone}
        style={{ marginBottom: props.withBottomSpacing ? "calc(var(--gutter))" : "0px" }}>
        {children}
      </Zone>
    );
  else
    return (
      <Zone {...props} inverted className={className} style={{ marginBottom: props.withBottomSpacing ? "calc(var(--gutter))" : "0px" }}>
        {children}
      </Zone>
    );
};

export default Liner;
