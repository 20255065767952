import React, { useEffect } from "react";

export const useHandleClickOutside = <T extends HTMLElement>(ref: React.RefObject<T>, callback: () => void) => {
  const portal = document.getElementById("portal");
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node) && !portal?.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [callback]);
};
