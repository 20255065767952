import { useState, useRef } from "react";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Input from "../styled/input";
import TextArea from "../styled/textArea";
import { SelectTemplate } from "../common/select";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_TEMPLATE, POST_TEMPLATE_CREATE, POST_TEMPLATE_UDPATE, POST_TEMPLATE_DELETE } from "../../graphql/queries/template";
import clone from "clone";
import { GlobalStore } from "../../stores/global";
import Modal from "../../components/modal";
import { Template } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";

interface TemplateEditable {
  _id?: string | null;
  title: string;
  type: string;
  data: {
    content?: string;
  };
}

const TemplateEditor = ({ type, text }: { type: string; text?: string }) => {
  const addNotification = GlobalStore.useState(c => c.addNotification);
  const modalRef = useRef<any>();
  const [dirty, setDirty] = useState(false);
  const [updateTemplate] = useMutation(POST_TEMPLATE_UDPATE);
  const [deleteTemplate] = useMutation(POST_TEMPLATE_DELETE);
  const [createTemplate] = useMutation(POST_TEMPLATE_CREATE);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEditable>({ _id: null, type, title: "", data: { content: "" } });
  const [getTemplate] = useLazyQuery(GET_TEMPLATE, { fetchPolicy: "cache-and-network" });

  const { t } = useTranslation();

  const isNewTemplate = !selectedTemplate?._id;

  const handleTemplateSelect = (template: Template) => {
    getTemplate({ variables: { templateRef: template._id } })
      .then(({ data }) => {
        if (data?.template) {
          setSelectedTemplate(clone(data?.template));
          setDirty(false);
        }
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleTemplateChange = (e: any) => {
    if (!selectedTemplate) return;
    setDirty(true);
    selectedTemplate.data.content = e.target.value;
    setSelectedTemplate({ ...selectedTemplate });
  };

  const handleNewTemplateTitleChange = (e: any) => {
    if (!selectedTemplate) return;
    setDirty(true);
    selectedTemplate.title = e.target.value;
    setSelectedTemplate({ ...selectedTemplate });
  };

  const handleDeleteTemplate = (ref: string) => {
    deleteTemplate({ variables: { templateRef: ref } }).then(() => {
      addNotification({ ok: 1, message: "Template deleted" });
      setSelectedTemplate({ _id: null, type, title: "", data: { content: "" } });
    });
  };

  const handleSubmitTemplate = async () => {
    if (!selectedTemplate) return;
    if (!selectedTemplate.title || !selectedTemplate.data?.content)
      return addNotification({ ok: 0, message: "Title and content is required" });
    if (!selectedTemplate._id) {
      createTemplate({
        variables: { title: selectedTemplate?.title, type, data: { content: selectedTemplate?.data?.content } }
      })
        .then(({ data }) => {
          if (data?.templateCreate) {
            setSelectedTemplate(clone(data.templateCreate));
            setDirty(false);
            addNotification({ ok: 1, message: "Template created" });
          }
        })
        .catch(e => addNotification({ ok: 0, message: e.message }));
    } else
      updateTemplate({
        variables: {
          templateRef: selectedTemplate._id,
          templateUpdateInput: {
            title: selectedTemplate.title,
            data: { content: selectedTemplate.data.content },
            type: selectedTemplate.type
          }
        }
      })
        .then(() => {
          setDirty(false);
          addNotification({ ok: 1, message: "Template updated" });
        })
        .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleResetAndClose = () => {
    modalRef.current.close();
    setSelectedTemplate({ _id: null, type, title: "", data: { content: "" } });
  };

  return (
    <>
      <Modal style={{}} ref={modalRef} onClose={handleResetAndClose}>
        <div id="editMessageTemplates">
          <div id="templateEditorForm">
            <ModalHeaderContainer>
              <Typography variant="pageTitle" tag="h2">
                {t("Template editor")}
              </Typography>
              <div style={{ width: "200px" }}>
                <SelectTemplate placeholder={t("Select a template")} type={type} value={null} onChange={handleTemplateSelect} />
              </div>
              <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <hr />
            <div className="content">
              <Input
                name="title"
                required
                label={t("Template title")}
                placeholder={t("Template title") + "..."}
                value={selectedTemplate?.title || ""}
                onChange={handleNewTemplateTitleChange}
              />
              <TextArea
                disabled={false}
                autoFocus={true}
                rows="14"
                onChange={handleTemplateChange}
                value={selectedTemplate?.data?.content || ""}
                label={t("Template content")}
                type="text"
                name="content"
                placeholder={t("Enter content")}
                autoComplete="off"
                required
              />
              <div className="flexSpaceBetween">
                {!isNewTemplate && !dirty ? (
                  <Button
                    disabled={false}
                    variant="danger"
                    type="button"
                    onClick={() => {
                      if (selectedTemplate?._id && window.confirm(t("Are you sure?"))) handleDeleteTemplate(selectedTemplate._id);
                    }}>
                    {t("Delete")}
                  </Button>
                ) : (
                  <span />
                )}
                <span>
                  <ButtonV2 disabled={!dirty} type="button" variant="primary" onClick={handleSubmitTemplate}>
                    {isNewTemplate ? t("Create template") : t("Update template")}
                  </ButtonV2>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Button type="button" variant="noStyle" onClick={() => modalRef.current.open()}>
        {text || t("Edit templates")}
      </Button>
    </>
  );
};

export default TemplateEditor;
