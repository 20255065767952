import { useEffect, useState, useRef, SyntheticEvent } from "react";
import Zone from "../../components/styled/zone";
import Input from "../../components/styled/input";
import Checkbox from "../../components/styled/checkbox";
import Radio from "../../components/styled/radio";
import { Select } from "../../components/styled/select";
import { GlobalStore } from "../../stores/global";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { Route, Switch, useHistory, Link, useLocation } from "react-router-dom";
import { POST_USER_CHECK } from "../../graphql/queries/session";
import { POST_USER_ADMIN_PASSWORD_RESET, POST_USER_ADMIN_PASSWORD_TOKEN } from "../../graphql/queries/user";
import { POST_CONFIG_CREATE } from "../../graphql/queries/config";
import { useMutation } from "@apollo/client";
import Loader from "../../components/common/loader";
import URI from "urijs";
import Countries from "@common-ground-io/common-assets/assets/countries.json";
import moment from "moment";
import { isElectron, removeSessionJWTForDomain } from "../..//utils";
import AppleSignin from "react-apple-login";
import { decodeToken } from "react-jwt";
import { NoText } from "../../components/logo";
import * as amplitude from "@amplitude/analytics-browser";
import { AddNotification } from "../../types/globals";
import { Session } from "../../__generated__/graphql";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { useTranslation } from "react-i18next";

const LoginOrRegister = ({ isRegister, isLogin }: { isRegister: boolean; isLogin: boolean }) => {
  const { addNotification, adminVerify, session, login, logout, loginWithSSO, adminCreate } = GlobalStore.useState(c => c);
  const history = useHistory();
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const search = currentUri.search(true);
  const isDesktopRedirect = search && search.desktop;
  const [registerData, setRegisterData] = useState();

  useEffect(() => {
    if (session && !session.user.verification?.status) history.push("/register/verify");
    else if (session && (session.adminDomains.length === 0 || isRegister)) history.push("/register/finalize");
    else if (session && isLogin && !isDesktopRedirect) history.push("/dashboard");
  }, [session]);

  return (
    <div id="register">
      <div className="header">
        <a href="https://common-ground.io" target={"_blank"} rel="noreferrer">
          <NoText />
        </a>
      </div>
      <GoogleOAuthProvider clientId={process.env.VITE_GOOGLE_OAUTH_ID as string}>
        <Switch>
          <Route path="/register/finalize" exact>
            <Finalize addNotification={addNotification} history={history} logout={logout} session={session as Session} />
          </Route>
          <Route path="/register/details" exact>
            <Details addNotification={addNotification} adminCreate={adminCreate} register={registerData} />
          </Route>
          <Route path="/register/verify" exact>
            <Verify addNotification={addNotification} adminVerify={adminVerify} session={session as Session} />
          </Route>
          <Route path="/login/password/reset" exact>
            <ResetPassword addNotification={addNotification} history={history} />
          </Route>
          <Route path={["*", "/login", "/register"]} exact>
            <Login
              addNotification={addNotification}
              login={login}
              history={history}
              isDesktopRedirect={isDesktopRedirect}
              loginWithSSO={loginWithSSO}
              logout={logout}
              setRegisterData={setRegisterData}
              session={session as Session}
            />
          </Route>
        </Switch>
      </GoogleOAuthProvider>
      <LegalFooter />
    </div>
  );
};

const Login = ({
  session,
  addNotification,
  history,
  setRegisterData,
  isDesktopRedirect,
  login,
  loginWithSSO,
  logout
}: {
  session: Session;
  addNotification: AddNotification;
  history: any;
  setRegisterData: any;
  isDesktopRedirect: boolean;
  login: any;
  loginWithSSO: any;
  logout: any;
}) => {
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const [requiresPassword, setRequiresPassword] = useState<boolean>(false);
  const [userCheck] = useMutation(POST_USER_CHECK);
  const { t } = useTranslation();

  useEffect(() => {
    const { desktopApi } = window as any;
    if (desktopApi?.loginCallback)
      desktopApi.loginCallback((event: any, ssoInput: any) => {
        if (!ssoInput?.jwt && !ssoInput.code) throw new Error(t("Authentication error"));
        setIsLoginIn(true);
        loginWithSSO({ ssoInput })
          .then(() => {
            history.push("/dashboard");
          })
          .catch((e: any) => {
            addNotification({ ok: 0, message: e.toString() });
            setIsLoginIn(false);
          });
      });
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoginIn(true);
    const password = e.target.password?.value;
    const email = e.target.email.value.toLowerCase();
    if (!requiresPassword && !password) {
      try {
        const { data } = await userCheck({ variables: { email: e.target.email.value } });
        if (!data) throw new Error("Admin Error");
        const userExist = data.userAdminCheck;
        setRegisterData({ userData: { email: e.target.email.value } });
        if (userExist) setRequiresPassword(true);
        else history.push("/register/details");
      } catch (error: any) {
        removeSessionJWTForDomain();
        addNotification({ ok: 0, message: error.toString() });
      } finally {
        setIsLoginIn(false);
      }
    } else if (requiresPassword) {
      try {
        await login({ email, password });
      } catch (err: any) {
        setMessage(err.message);
      } finally {
        setIsLoginIn(false);
      }
    }
  };

  const handleCancel = async () => {
    setRequiresPassword(false);
  };

  const handleSSOLogin = async (options: { apple?: any; google?: any }) => {
    if (isLoginIn) return;
    const { apple, google } = options;
    setMessage("");
    setIsLoginIn(true);
    try {
      if (session) await logout();
      let userData;
      let jwt;
      let loginData;

      if (google) {
        jwt = google.credential;
        const decodedJWT = decodeToken(jwt) as any;
        userData = {
          firstName: decodedJWT.given_name,
          lastName: decodedJWT.family_name,
          email: decodedJWT.email
        };
        loginData = { ssoInput: { jwt, audience: process.env.VITE_GOOGLE_OAUTH_ID, type: "google" } };
      } else if (apple && apple.authorization) {
        jwt = apple.authorization.id_token;
        const decodedJWT = decodeToken(jwt) as any;
        userData = { email: decodedJWT.email };
        loginData = { ssoInput: { code: apple.authorization.code, type: "apple" } };
      } else return;

      setRegisterData({ userData, ssoInput: loginData.ssoInput });
      const { data } = await userCheck({ variables: { email: userData.email } });
      if (!data?.userAdminCheck) throw new Error("Admin Error");
      const userExist = data.userAdminCheck;
      if (userExist) {
        if (!isDesktopRedirect) {
          await loginWithSSO(loginData);
          history.push("/dashboard");
        } else if (isDesktopRedirect) {
          const protocol = `cg-admin-desktop${process.env.NODE_ENV !== "production" ? "-dev" : ""}`;
          const externalUri = new URI(protocol + "://app/login-callback").search({ ...loginData.ssoInput });
          window.location.href = externalUri.toString();
        }
      } else history.push("/register/details");
    } catch (err: any) {
      removeSessionJWTForDomain();
      setMessage(err.toString());
    } finally {
      setIsLoginIn(false);
    }
  };

  const isLocal = process.env.NODE_ENV !== "production";
  const isStaging = process.env.VITE_BUILD_ENV === "staging";
  const rootUrl = isLocal
    ? "http://localhost:3000"
    : isStaging
    ? "https://staging.admin.common-ground.io"
    : "https://admin.common-ground.io";
  const loginUrl = rootUrl + "/login?desktop=true";

  return (
    <div id="introduction">
      <Zone className="content">
        <div className="title">
          <h1>{t("Sign in")}</h1>
        </div>
        <div className="logins">
          {!isDesktopRedirect ? (
            <form className="top" onSubmit={handleSubmit}>
              <div className="elements">
                <Input
                  required
                  variant="overZone"
                  type="email"
                  disabled={requiresPassword}
                  name="email"
                  placeholder={t("Email address") + "..."}
                  label={t("Email address")}
                />
                {requiresPassword ? (
                  <>
                    <Input
                      required
                      variant="overZone"
                      name="password"
                      placeholder={t("Enter your password") + "..."}
                      type="password"
                      label={t("Enter your password")}
                    />
                    <Link to="/login/password/reset">{t("Forgot your password? Reset it")}</Link>
                  </>
                ) : null}
                <ButtonV2 type="submit" disabled={isLoginIn} variant="primary">
                  {isLoginIn ? <Loader /> : t("Submit")}
                </ButtonV2>
              </div>
              <hr />
            </form>
          ) : null}
          {requiresPassword ? (
            <ButtonV2 variant="secondary" onClick={handleCancel}>
              {t("Cancel")}
            </ButtonV2>
          ) : null}
          <div className="sso">
            {!requiresPassword ? (
              <>
                {!isDesktopRedirect ? <p>{t("Or sign in with")}</p> : null}
                <div className="buttons">
                  <div className="google">
                    {isElectron() ? (
                      <a href={loginUrl} target="_blank" rel="noreferrer">
                        <img
                          style={{ height: "55px" }}
                          src="https://static.common-ground.io/common/media/btn_google_signin_light_normal_web@2x.png"
                        />
                      </a>
                    ) : (
                      <GoogleLogin onSuccess={credentials => handleSSOLogin({ google: credentials })} onError={() => handleSSOLogin({})} />
                    )}
                  </div>
                  <div className="apple">
                    {isElectron() ? (
                      <a href={loginUrl} target="_blank" rel="noreferrer">
                        <button className="reset appleButton">
                          <img src="https://static.common-ground.io/common/media/apple/Logo-SIWA-Logo-only-White@3x.png" />
                          {t("Sign in with Apple")}
                        </button>
                      </a>
                    ) : (
                      <AppleSignin
                        clientId="cg.backoffice.desktop.sso"
                        redirectURI={rootUrl + "/login"}
                        usePopup={true}
                        callback={credentials => handleSSOLogin({ apple: credentials })}
                        scope="email name"
                        responseMode="form_post"
                      />
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="footer">{message ? <p className="message">{message}</p> : null}</div>
        </div>
      </Zone>
    </div>
  );
};

interface FinalizeCountry {
  value: string;
  label: string;
}
const Finalize = ({
  session,
  addNotification,
  logout,
  history
}: {
  session: Session;
  addNotification: AddNotification;
  history: any;
  logout: any;
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(false);
  const [form, setForm] = useState({
    shopName: "",
    country: null as FinalizeCountry | null,
    type: "b2c",
    referral: "",
    agreed: false,
    collectSalesTax: true
  });
  const [configCreate] = useMutation(POST_CONFIG_CREATE);
  const [isCreating, setIsCreating] = useState(false);

  const handleFinalize = async (e: any) => {
    e.preventDefault();
    setIsCreating(true);
    try {
      if (!form.country || !form.shopName) throw new Error(t("Country and shop name is required"));
      amplitude.track("Account created", { name: form.shopName, country: form.country.value });
      await configCreate({
        variables: {
          configData: {
            shopName: form.shopName,
            type: form.type,
            collectSalesTax: form.collectSalesTax,
            userRef: session.user._id,
            country: form.country.value,
            referral: form.referral
          }
        }
      });
      window.location = "/" as any;
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
      setMessage(error.message);
    } finally {
      setIsCreating(false);
    }
  };

  const handleFormChange = (e: any) => {
    if (e.target.type === "checkbox") setForm({ ...form, agreed: e.target.checked });
    else setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCancel = async () => {
    if (session && session.adminDomains.length) history.push("/");
    else await logout();
  };

  const handleDropdownChange = async (value: any) => {
    form.country = value;
    setForm({ ...form });
  };

  const handUpdateTypeChange = (e: any) => {
    form.type = e.target.value;
    if (form.type === "b2b") form.collectSalesTax = true;
    setForm({ ...form });
  };

  const handUpdateSalesTaxChange = (e: any) => {
    form.collectSalesTax = e.target.checked;
    setForm({ ...form });
  };

  if (!session) {
    history.push("/");
  }

  return (
    <div id="finalize">
      <Zone className="content">
        <div className="title">
          <h1>{t("Account information")}</h1>
        </div>
        <div className="logins">
          <form className="top" onSubmit={handleFinalize}>
            <div className="elements">
              <Input
                required
                autoComplete="off"
                variant="overZone"
                name="shopName"
                onChange={handleFormChange}
                value={form.shopName}
                placeholder={t("Business name") + "..."}
                label={t("Business name")}
              />
              <Select
                variant="overZone"
                required
                placeholder={t("Select a country") + "..."}
                isMulti={false}
                options={Countries.map(c => ({ label: c.name, value: c.code }))}
                value={form.country}
                onChange={(a: any) => handleDropdownChange(a)}
              />
              <Radio
                onChange={handUpdateTypeChange}
                value={form.type}
                entries={[
                  {
                    label: t("I am a B2C business (shops, individual sellers, retailers)"),
                    value: "b2c"
                  },
                  { label: t("I am a B2B business (distributors, wholesalers, manufacturers)"), value: "b2b" }
                ]}
              />
              {form.type === "b2c" && form.country?.label ? (
                <Checkbox
                  checked={form.collectSalesTax}
                  onChange={handUpdateSalesTaxChange}
                  label={<span>{t("I collect sales tax in {{countryTax}}", { countryTax: form.country?.label })}</span>}
                />
              ) : null}
              <p>
                {t(
                  "By creating an account with Common Ground, a 14 day trial will start. You are not required to add a payment method until the trial period is over."
                )}
              </p>
              <Input
                variant="overZone"
                name="referral"
                onChange={handleFormChange}
                value={form.referral || ""}
                placeholder={t("Referral code") + "..."}
                label={t("Referral code")}
              />
              <Checkbox
                checked={form.agreed}
                required
                onChange={handleFormChange}
                label={
                  <span>
                    <a href="https://www.common-ground.io/legal/terms" target="_tab">
                      {t("I have read and agree to our terms and conditions")}
                    </a>
                  </span>
                }
              />
              <ButtonV2 type="submit" disabled={isCreating} variant="primary">
                {isCreating ? <Loader /> : t("Submit")}
              </ButtonV2>
              {message ? <p>{message}</p> : null}
            </div>
          </form>
          <ButtonV2 variant="secondary" onClick={handleCancel}>
            {t("Cancel")}
          </ButtonV2>
        </div>
      </Zone>
    </div>
  );
};

const Details = ({ register, adminCreate, addNotification }: { register: any; addNotification: AddNotification; adminCreate: any }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    firstName: register?.userData?.firstName || "",
    lastName: register?.userData?.lastName || "",
    password: "",
    email: register?.userData?.email || "",
    agreed: false
  });

  const handleFormChange = (e: any) => {
    if (e.target.type === "checkbox") setForm({ ...form, agreed: e.target.checked });
    else setForm({ ...form, [e.target.name]: e.target.value });
  };

  const fromSSO = register?.ssoInput;

  const handleCreateAccount = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const payload: any = { ...form };
      if (!form.agreed) throw new Error(t("You must agree to our privacy policy"));
      if (fromSSO) {
        payload.ssoInput = register?.ssoInput;
      }
      amplitude.track("User created");
      await adminCreate(payload);
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
      setMessage(error.toString());
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="details">
      <Zone className="content">
        <div className="title">
          <h1>{t("Details")}</h1>
        </div>
        <div className="logins">
          <form className="top" onSubmit={handleCreateAccount}>
            <div className="elements">
              <div className="twoColumns">
                <Input
                  required
                  variant="overZone"
                  autoComplete="off"
                  name="firstName"
                  onChange={handleFormChange}
                  value={form.firstName}
                  placeholder={t("First name") + "..."}
                  label={t("First name")}
                />
                <Input
                  required
                  variant="overZone"
                  autoComplete="off"
                  name="lastName"
                  onChange={handleFormChange}
                  value={form.lastName}
                  placeholder={t("Last name") + "..."}
                  label={t("Last name") + "..."}
                />
              </div>
              <Input
                required
                name="email"
                variant="overZone"
                onChange={handleFormChange}
                disabled={true}
                value={form.email}
                placeholder={t("Email address") + "..."}
                label={t("Email address")}
              />
              {!fromSSO ? (
                <Input
                  required
                  variant="overZone"
                  autoComplete="off"
                  onChange={handleFormChange}
                  name="password"
                  placeholder={t("Enter a new password") + "..."}
                  type="password"
                  label={t("Enter a new password")}
                />
              ) : null}

              <Checkbox
                checked={form.agreed}
                onChange={handleFormChange}
                required
                label={
                  <span>
                    <a href="https://www.common-ground.io/legal/privacy" target="_tab">
                      {t("I have read and agree to our privacy policy")}
                    </a>
                  </span>
                }
              />
              <ButtonV2 disabled={isSubmitting} type="submit" variant="primary">
                {isSubmitting ? <Loader /> : t("Create my account")}
              </ButtonV2>
            </div>
          </form>
          <div className="footer">
            {message ? <p className="message">{message}</p> : null}
            <p>
              <Link to="/login">{t("Back to login")}</Link>
            </p>
          </div>
        </div>
      </Zone>
    </div>
  );
};

const Verify = ({ session, adminVerify, addNotification }: { session: Session; addNotification: AddNotification; adminVerify: any }) => {
  const [message, setMessage] = useState(false);
  const [codes, setCodes] = useState<string[]>(["", "", "", ""]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [refs] = useState(Array.from({ length: 4 }, () => useRef<any>()));
  const history = useHistory();
  const { t } = useTranslation();

  if (!session) history.push("/register");

  const handleVerifyAccount = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    try {
      const token = codes.join("");
      if (codes.find(c => !c) !== undefined) {
        return refs[codes.findIndex(c => !c)].current?.focus();
      }
      if (!token || token.length !== 4) throw new Error(t("Invalid code"));
      await adminVerify({ userRef: session.user._id, token });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
      setMessage(error.message);
      setCodes(["", "", "", ""]);
      refs[0].current.focus();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResend = async () => {
    setIsSendingCode(true);
    try {
      await adminVerify({ userRef: session.user._id, resend: true });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
      setMessage(error.message);
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleKeyDown = async (e: any, i: number) => {
    const value = e.key;
    if (e.key === "Backspace") {
      codes[i] = "";
      setCodes([...codes]);
    } else if (e.key === "ArrowLeft" && i > 0) {
      refs[i - 1].current.focus();
    } else if (e.key === "ArrowRight" && i < 3) {
      refs[i + 1].current.focus();
    } else if (!isNaN(parseInt(value))) {
      codes[i] = value;
      setCodes([...codes]);
      if (i < 3) refs[i + 1].current.focus();
      else if (i === 3) await handleVerifyAccount();
    }
  };

  if (!session) return null;

  return (
    <div id="verify">
      <Zone className="content">
        <div className="title">
          <h1>{t("Email confirmation")}</h1>
        </div>
        <div className="logins">
          <form className="top" onSubmit={handleVerifyAccount}>
            <p>{t("Enter the 4-digit code that was sent to {{emailVerification}}", { emailVerification: session.user.email })}</p>
            <div className="elements">
              <Zone className="codes">
                {codes.map((c, i) => (
                  <input
                    key={i}
                    ref={refs[i]}
                    value={codes[i]}
                    onKeyDown={(e: any) => handleKeyDown(e, i)}
                    onChange={(e: any) => e}
                    autoFocus={i === 0}
                    className="code"
                    min="0"
                    max="9"
                    required
                    name={String(i)}
                    type="number"
                    onWheel={(e: any) => (e.target as HTMLElement).blur()}
                  />
                ))}
              </Zone>
              <ButtonV2 disabled={isSubmitting} type="submit" variant="primary">
                {isSubmitting ? <Loader /> : t("Submit")}
              </ButtonV2>
            </div>
          </form>
          <ButtonV2 type="submit" disabled={isSendingCode || isSubmitting} variant="secondary" onClick={handleResend}>
            {isSendingCode ? <Loader /> : t("Resend verification code")}
          </ButtonV2>
        </div>
        <div className="footer">
          {message ? <p className="message">{message}</p> : null}
          <p>
            <Link to="/login">{t("Back to login")}</Link>
          </p>
        </div>
      </Zone>
    </div>
  );
};

const ResetPassword = ({ history, addNotification }: { history: any; addNotification: AddNotification }) => {
  const [isGettingSession, setIsGettingSession] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const location = useLocation();
  const uri = new URI(location.pathname + location.search);
  const search = uri.search(true);
  const [submitPasswordReset] = useMutation(POST_USER_ADMIN_PASSWORD_RESET);
  const [submitPasswordToken] = useMutation(POST_USER_ADMIN_PASSWORD_TOKEN);
  const [tokenWasCreated, setTokenWasCreated] = useState(false);
  const { t } = useTranslation();

  const token = search.token;

  const handlePasswordReset = async (e: any) => {
    e.preventDefault();
    setMessage(null);
    setIsGettingSession(true);
    const email = e.target.email.value.toLowerCase();
    if (!email) return setMessage("Please enter a valid email address");
    try {
      await submitPasswordToken({ variables: { email } });
      setMessage(t("Reset instructions were sent to your email address"));
      setTokenWasCreated(true);
      amplitude.track("Password reset");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
      setMessage(e.data);
    } finally {
      setIsGettingSession(false);
    }
  };

  const handlePasswordSubmit = async (e: any) => {
    e.preventDefault();
    setMessage(null);
    const password = e.target.password.value;
    setIsGettingSession(true);
    try {
      if (!password) throw new Error(t("Please enter a valid password"));
      else if (password.length < 6) throw new Error(t("Password must be 6 characters long") + "...");
      await submitPasswordReset({ variables: { password, token } });
      addNotification({ ok: 1, message: t("Your password was reset") });
      history.push("/login");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
      setMessage(e.toString());
    } finally {
      setIsGettingSession(false);
    }
  };

  return (
    <div id="passwordReset">
      <Zone className="content">
        <div className="header">
          <h1>{t("Password reset")}</h1>
        </div>
        <div className="logins">
          {token ? (
            <form className="top" onSubmit={handlePasswordSubmit}>
              <div className="elements">
                <Input
                  variant={"overZone"}
                  fullWidth
                  label={t("Enter a new password")}
                  type="password"
                  name="password"
                  placeholder={t("Enter a new password") + "..."}
                  required
                />
                <ButtonV2 disabled={isGettingSession} variant="primary" type="submit">
                  {isGettingSession ? <Loader /> : "Submit new password"}
                </ButtonV2>
              </div>
            </form>
          ) : !tokenWasCreated ? (
            <form className="top" onSubmit={handlePasswordReset}>
              <div className="elements">
                <Input
                  variant={"overZone"}
                  fullWidth
                  label={t("Enter your email address")}
                  type="email"
                  name="email"
                  placeholder={t("Enter your email address") + "..."}
                  required
                />
                <ButtonV2 disabled={isGettingSession} type="submit" variant="primary">
                  {isGettingSession ? <Loader /> : "Reset password"}
                </ButtonV2>
              </div>
            </form>
          ) : null}
        </div>
        <div className="footer">
          {message ? <p className="message">{message}</p> : null}
          <p>
            <Link to="/login">{t("Back to login")}</Link>
          </p>
        </div>
      </Zone>
    </div>
  );
};

const LegalFooter = () => {
  const { t } = useTranslation();
  return (
    <div id="legalFooter">
      <div className="left">
        <a href="https://docs.common-ground.io" target={"_blank"} rel="noreferrer">
          {t("Help?")}
        </a>
        <a href="https://www.common-ground.io/legal/privacy" target={"_blank"} rel="noreferrer">
          {t("Privacy policy")}
        </a>
        <a href="https://www.common-ground.io/legal/terms" target={"_blank"} rel="noreferrer">
          {t("Terms")}
        </a>
      </div>
      <div className="right">
        <p>
          <a href="https://common-ground.io" target={"_blank"} rel="noreferrer">
            {"© "}
            {moment().year()} {"Common Ground SAS"}
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginOrRegister;
