import styled from "styled-components";

import { colorsAsRgbString } from "@common-ground-io/colors";

const layout = {
  light: {
    fontColorLabel: colorsAsRgbString.greyOvercast,
    bcgColorInput: colorsAsRgbString.greyLighter,
    borderColor: colorsAsRgbString.greyLight,
    fontColorInput: colorsAsRgbString.greyDarker,
    fontColorInputReadonly: colorsAsRgbString.greyLight,
    fontColorPlaceholder: colorsAsRgbString.greyOvercast
  },
  dark: {
    fontColorLabel: colorsAsRgbString.grey,
    borderColor: "transparent",
    bcgColorInput: colorsAsRgbString.greyDark,
    fontColorInput: colorsAsRgbString.greyLighter,
    fontColorInputReadonly: colorsAsRgbString.grey,
    fontColorPlaceholder: colorsAsRgbString.grey
  }
};

const Styledlabel = styled.label<{ fullWidth?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(layout, props).fontColorLabel};
`;

const StyledInput = styled.input<{ fullWidth?: boolean; label?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  margin: 0;
  margin-top: ${props => (props.label ? "10px" : "0px")};
  border: 1px solid ${props => path(layout, props).borderColor};
  border-radius: 10px;
  outline: none;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(layout, props).bcgColorInput};
  color: ${props => path(layout, props).fontColorInput};
  box-shadow: none;
  outline: none;

  &:read-only {
    color: ${props => path(layout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(layout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  ::-webkit-calendar-picker-indicator {
    filter: ${({ theme }) =>
      theme.name === "light"
        ? "invert(23%) sepia(0%) saturate(1432%) hue-rotate(140deg) brightness(111%) contrast(87%);"
        : "invert(50%) sepia(0%) saturate(3%) hue-rotate(168deg) brightness(95%) contrast(84%);"};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
    box-shadow: none;
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const path = (layout: any, props: any) => layout[props.theme.name || "light"];

const InputComponent = (props: any) => {
  return (
    <Container className="cg-common cg-input">
      {props.label && (
        <Styledlabel htmlFor={props.id || props.name || ""} className={`cg-common ${props.className || ""}-label`}>
          {props.label}
        </Styledlabel>
      )}
      <StyledInput
        {...props}
        onWheel={(e: any) => (e.target as HTMLElement).blur()}
        className={`cg-common cg-input ${props.className || ""}`}
      />
    </Container>
  );
};

export default InputComponent;
