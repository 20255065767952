import { useState } from "react";
import Input from "../../styled/input";
import { Select } from "../../styled/select";
import { Address, CheckoutOrOrderBuyer, Config } from "../../../__generated__/graphql";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Button } from "../../../componentsV2/Button";
import { getData, getName } from "country-list";
import AddressComponent from "../../../componentsV2/Address";

export const addressMap = (t: TFunction) => {
  return {
    billingAndShipping: t("Billing and shipping"),
    shipping: t("Shipping"),
    billing: t("Billing")
  };
};

export default function AddressEdit({
  editable,
  address,
  type,
  buyer,
  handleSubmit,
  handleChange,
  handleCancel,
  config
}: {
  editable?: boolean;
  address: Address;
  type: string;
  buyer?: CheckoutOrOrderBuyer | null;
  handleSubmit: any;
  handleChange: any;
  handleCancel: any;
  config: Config;
}) {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const handleToggle = () => {
    setEdit(!edit);
    if (edit) handleCancel();
  };

  const handleSelectShippingAddress = (option: any) => {
    handleSubmit(null, null, option.value);
    setEdit(false);
  };

  const addresses = (buyer && buyer?.addresses) || [];
  const selectedAddress: any = addresses?.find(a => a._id === address._id);

  return (
    <div className="addressEdit">
      <div className="sectionHeader">
        <h4>
          {type} {t("Address")}{" "}
        </h4>
        {editable ? (
          <button className="reset" type="button" onClick={handleToggle}>
            <p>{edit ? t("Cancel") : t("Edit")}</p>
          </button>
        ) : null}
      </div>
      {!edit ? (
        <>
          {address.addressLine1 ? (
            <>
              {buyer ? <p>{buyer.name}</p> : null}
              <AddressComponent config={config} address={address} />
              {buyer ? <p>{buyer.telephone}</p> : null}
              {buyer ? <p>{buyer.email}</p> : null}
            </>
          ) : (
            <p>{t("No address set")}</p>
          )}
        </>
      ) : (
        <form
          className="section"
          onSubmit={(e: any) => {
            handleSubmit(e, address);
            setEdit(false);
          }}>
          {addresses.length ? (
            <Select
              label={t("Select a shipping address")}
              variant="overZone"
              onChange={handleSelectShippingAddress}
              value={
                selectedAddress
                  ? // @ts-ignore
                    { label: `${addressMap(t)[selectedAddress.type]}: ${selectedAddress.city} - ${selectedAddress.addressLine1}` }
                  : { label: t("Shop collection"), value: null }
              }
              options={[
                editable ? { label: t("Shop collection"), value: null } : null,
                // @ts-ignore
                ...addresses.map(a => ({ value: a._id, label: `${addressMap(t)[a.type as string]}: ${a.city} - ${a.addressLine1}` }))
              ].filter(e => !!e)}
            />
          ) : null}
          <Input
            variant="overZone"
            label={t("Address line") + " 1"}
            type="text"
            name="addressLine1"
            required
            value={address.addressLine1 || ""}
            onChange={(e: any) => handleChange(type, e.target.name, e.target.value)}
          />
          <Input
            variant="overZone"
            label={t("Address line") + " 2"}
            type="text"
            name="addressLine2"
            value={address.addressLine2 || ""}
            onChange={(e: any) => handleChange(type, e.target.name, e.target.value)}
          />
          <Input
            variant="overZone"
            label={t("City")}
            type="text"
            name="city"
            required
            value={address.city || ""}
            onChange={(e: any) => handleChange(type, e.target.name, e.target.value)}
          />
          <Input
            variant="overZone"
            label={t("Post code / Zip")}
            type="text"
            name="postCode"
            value={address.postCode || ""}
            onChange={(e: any) => handleChange(type, e.target.name, e.target.value)}
          />
          <Input
            variant="overZone"
            label={t("State")}
            type="text"
            name="state"
            value={address.state || ""}
            onChange={(e: any) => handleChange(type, e.target.name, e.target.value)}
          />
          <Select
            variant="overZone"
            label={t("Country")}
            value={{
              value: address.alpha2,
              label: getName(address.alpha2 || "")
            }}
            onChange={(option: any) => handleChange(type, "alpha2", option.value)}
            options={getData().map((c: any) => ({ label: c.name, value: c.code }))}
          />
          <Button variant="primary" disabled={false} type="submit">
            {t("Submit")}
          </Button>
        </form>
      )}
    </div>
  );
}
