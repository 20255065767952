import { useEffect, useState, useContext } from "react";
import { ConfigContextData } from "../contexts/ConfigContext";
import URI from "urijs";
import * as amplitude from "@amplitude/analytics-browser";
import { Location } from "../types/globals";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { POST_CONFIG_DISCOGS_UPDATE } from "../graphql/queries/config";

export default function DiscogsConnect({ location }: { location: Location }) {
  const { configReload } = useContext(ConfigContextData);
  const currentPath = location.pathname + location.search;
  const currentUri = new URI(currentPath);
  const history = useHistory();
  const searchQuery = currentUri.search(true);
  const [grantResponse, setGrantResponse] = useState(null);
  const [error, setError] = useState(searchQuery.denied ? "Operation cancelled..." : null);
  const { t } = useTranslation();
  const [updateDiscogsSettings] = useMutation(POST_CONFIG_DISCOGS_UPDATE);

  useEffect(() => {
    const submitConnect = async (verifier: string) => {
      try {
        updateDiscogsSettings({ variables: { verifier } }).then(() => {
          amplitude.track("Discogs connected");
          history.push("/preferences/external-services");
        });
      } catch (e: any) {
        setGrantResponse(e.toString());
        setError(e.data);
      }
    };
    if (!searchQuery.denied) submitConnect(searchQuery.oauth_verifier);
  }, [currentPath, searchQuery.denied, searchQuery.oauth_verifier, configReload]);

  return (
    <div id="stripeConnect" style={{ textAlign: "center", marginTop: "var(--gutter)" }}>
      {grantResponse || error ? (
        <>
          <h2>{grantResponse || error}</h2>
          {error ? <p>{t("Please navigate to your Discogs settings and try again")}</p> : <p>{t("You may close this window")}</p>}
        </>
      ) : (
        <h2>{t("Retrieving your Discogs tokens, please wait")}... </h2>
      )}
    </div>
  );
}
