import { useState, useEffect } from "react";
import { Button } from "../../componentsV2/Button";
import Zone from "../styled/zone";
import { Link, useLocation } from "react-router-dom";
import { Select } from "../styled/select";
import Loader from "../common/loader";
import Price from "../common/price";
import { SearchListings } from "../global/search";
import URI from "urijs";
import { GlobalStore } from "../../stores/global";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { GET_ITEM, GET_INVENTORY_EXPORT_STICKERS, POST_ITEM_STICKERS_GENERATE } from "../../graphql/queries/item";
import { useHelpPanel } from "../../components/helpPanel";
import { GET_TEMPLATES } from "../../graphql/queries/template";
import { Config, Item, Listing, Template } from "../../__generated__/graphql";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export interface StickerEntry {
  listing: Listing;
  item: Item;
}

export default function Stickers() {
  const { addNotification, config } = GlobalStore.useState(c => c);
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  const currentUri = new URI(currentPath);
  const { load } = useHelpPanel({ show: false, id: 7234051 });
  const searchQuery = currentUri.search(true);
  const cacheString = `stickers-template-${config?.id}`;
  const [getItem] = useLazyQuery(GET_ITEM);
  const [generateSticker] = useMutation(POST_ITEM_STICKERS_GENERATE);
  const preTaxes = config?.taxes?.rules?.editPricesBeforeTaxes;
  const isLegacyPrinting = localStorage.getItem(`${config?.id}-legacyPrinting`) === "true";
  const { data } = useQuery(GET_TEMPLATES, { variables: { types: ["sticker-html"] } });
  const templates = data?.templatesConnection?.templates || [];

  const [entries, setEntries] = useState<{ item: Item; listing: Listing }[]>([]);
  const [cachedTemplate] = useState(localStorage.getItem(cacheString));
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null | undefined>();
  const [id] = useState(searchQuery.id ? searchQuery.id : null);
  const [isGenerating, setIsGenerating] = useState(false);
  document.title = "Stickers";

  const { t } = useTranslation();

  useEffect(() => {
    if (cachedTemplate) {
      const templateFromCache = templates.find(t => t._id === cachedTemplate);
      if (!templateFromCache) {
        if (templates.length) setSelectedTemplate(templates[0]);
        else setSelectedTemplate(null);
      } else if (templateFromCache) setSelectedTemplate(templateFromCache);
    } else if (templates.length && !selectedTemplate) setSelectedTemplate(templates[0]);
  }, [templates, cachedTemplate]);

  useEffect(() => {
    const loadRelease = async () => {
      if (id) {
        try {
          const { data } = await getItem({ variables: { listingRef: id } });
          if (data?.item) {
            const listing = data.item.listings.find(l => l._id === id);
            if (listing) setEntries([{ listing, item: data.item }]);
          }
        } catch (e: any) {
          console.error(e);
        }
      }
    };
    if (id) loadRelease();
  }, [id]);

  const handleSelectTemplate = (option: any) => {
    const template = templates.find(t => t._id === option.value);
    if (template) {
      localStorage.setItem(cacheString, template._id);
      setSelectedTemplate({ ...template });
    }
  };

  const handleAddEntry = (item: Item, listing: Listing) => {
    if (!selectedTemplate) return;
    if (entries.length >= parseInt(selectedTemplate.data?.model?.listingLimit)) {
      return addNotification({ ok: 0, message: "You have reach the max number of entries" });
    }
    entries.unshift({ listing, item });
    setEntries([...entries]);
  };

  const handleRemoveEntry = (index: number) => {
    entries.splice(index, 1);
    setEntries([...entries]);
  };

  const handleSubmitPrint = async () => {
    if (!selectedTemplate) return addNotification({ ok: 0, message: "A template must be selected" });
    const printData = {
      listingRefs: entries.map(e => e.listing._id),
      templateRef: selectedTemplate._id,
      fields: selectedTemplate.data.fields
    };
    setIsGenerating(true);
    try {
      const { data } = await generateSticker({ variables: printData });
      if (data?.itemStickersGenerate) {
        const location = new URI(data.itemStickersGenerate);
        if (data?.itemStickersGenerate) if (location) window.open(location.toString(), "_blank");
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
    setIsGenerating(false);
  };

  const templateOptions = templates?.map(t => ({ value: t._id, label: t.title }));

  const isPrintable = entries.length > 0 && selectedTemplate;
  return (
    <div className="stickers">
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {t("Stickers")}
          </Typography>
        </div>
        <div className="right"></div>
      </section>
      <div className="content">
        <div className="left">
          <StickersV2 preTaxes={!!preTaxes} load={load} />
        </div>
        <div className="right">
          {isLegacyPrinting ? (
            <>
              <div className="header">
                <h2>
                  {t("Legacy printing")} ({entries.length})
                </h2>
                <Button disabled={!isPrintable || isGenerating} variant={isPrintable ? "primary" : "secondary"} onClick={handleSubmitPrint}>
                  {isGenerating ? <Loader /> : t("Print")}
                </Button>
              </div>
              <SearchListings config={config as Config} label={t("Search and add listings")} onSelect={handleAddEntry} />
              <Select
                label={t("Template")}
                onChange={handleSelectTemplate}
                value={
                  selectedTemplate
                    ? {
                        value: selectedTemplate._id,
                        label: selectedTemplate.title
                      }
                    : null
                }
                options={templateOptions}
              />
              <div className="entries">
                {entries.map((e, i) => (
                  <Entry preTaxes={!!preTaxes} key={i} entry={e} i={i} handleRemoveEntry={handleRemoveEntry} t={t} />
                ))}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const StickersV2 = ({ preTaxes, load }: { preTaxes: boolean; load: any }) => {
  const { config, addNotification, stickers } = GlobalStore.useState(c => c);
  const [loading, setLoading] = useState(false);
  const [getInventoryExport] = useLazyQuery(GET_INVENTORY_EXPORT_STICKERS);
  const { t } = useTranslation();
  const handleGenerate = async () => {
    if (!stickers.entries.length) return addNotification({ ok: 0, message: "At least one listing is required" });
    try {
      setLoading(true);
      const { data } = await getInventoryExport({ variables: { listingRefs: stickers.entries.map(e => e.listing._id) } });
      // @ts-ignore
      if (data?.inventoryExportStickers?.uri) window.location = data.inventoryExportStickers.uri;
    } catch (error: any) {
      return addNotification({ ok: 0, message: error.toString() });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveEntry = (index: number) => {
    GlobalStore.update(s => {
      s.stickers.entries.splice(index, 1);
    });
  };

  const handleAddEntry = (item: Item, listing: Listing) => {
    GlobalStore.update(s => {
      s.stickers.entries.unshift({ item, listing });
    });
  };

  const handleClear = () => {
    GlobalStore.update(s => {
      s.stickers.entries = [];
    });
  };

  return (
    <div className="stickersv2">
      <div className="header">
        <div className="left">
          <h2>
            {t("Print queue")} ({stickers.entries.length})
          </h2>
          <Button variant="secondary" type="button" onClick={() => load("7234051")}>
            <span>{t("Help?")}</span>
          </Button>
        </div>
        <Button
          disabled={loading || !stickers.entries.length}
          variant={stickers.entries.length ? "primary" : "secondary"}
          onClick={handleGenerate}>
          {loading ? <Loader /> : "Generate CSV"}
        </Button>
      </div>
      <div className="content">
        <SearchListings config={config as Config} label={t("Search and add listings")} onSelect={handleAddEntry} />
        <div className="entries">
          {stickers.entries.map((e, i) => (
            <Entry key={i} entry={e} i={i} preTaxes={preTaxes} handleRemoveEntry={handleRemoveEntry} t={t} />
          ))}
        </div>
        {stickers.entries.length ? (
          <Button variant="warning" styleProps={{ marginTop: "var(--gutter)" }} onClick={handleClear}>
            {t("Clear")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const Entry = ({
  entry: e,
  handleRemoveEntry,
  i,
  preTaxes,
  t
}: {
  entry: StickerEntry;
  handleRemoveEntry: any;
  i: number;
  preTaxes: boolean;
  t: TFunction;
}) => {
  return (
    <div key={e.listing._id} className="stickerEntry">
      <Zone className="top">
        <p>
          <Link to={e.item.path}>
            <span className="main">{e.item.descriptions.main}</span>{" "}
          </Link>
          <br />
          <Price value={(preTaxes ? e.listing.prices.beforeTaxes : e.listing.prices.sale) as number} />
          {e.listing.options.map((o, i) => (
            <span key={o.name} className="option">
              {o.value} {i < e.listing.options.length - 1 ? " / " : ""}
            </span>
          ))}
        </p>
        <button className="reset" onClick={() => handleRemoveEntry(i)}>
          <i className="cg-icon-burger-close" />
        </button>
      </Zone>
      <div className="bottom">
        <p className="line">
          {e.listing.location ? (
            <span>
              {t("Location")}: {e.listing.location}
            </span>
          ) : null}
          {e.listing.supplierCode ? (
            <span>
              {t("Supplier")}: {e.listing.supplierCode}
            </span>
          ) : null}
        </p>
        <span className="barcode">
          {t("Barcode identifier")}: {e.listing.barcode || e.listing.id}
        </span>
      </div>
    </div>
  );
};
